// Split a serialized LIQE string into an array of tokens.
const splitExpression = (input) => {
  try {
    // Regular expression to split the input string into tokens.
    const regex = /(\([^)]+\)|\S+)/g;
    const result = input.match(regex);
    if (!result) {
      throw new Error('Failed to split the expression.');
    }
    return result;
  } catch (error) {
    throw new Error(`Error in splitExpression: ${error.message}`);
  }
};

// Transform an array of tokens into a structured object representing LIQE expressions.
const transformArrayToObject = (arr) => {
  const stack = []; // Stack to manage nested LIQE expressions.

  let operator = ''; // To track logical operators like 'OR' and 'AND'.
  for (const item of arr) {
    let currentGroup = { list: [] };

    // Check for logical operators 'OR' or 'AND'.
    if (item === 'OR' || item === 'AND') {
      operator = item;
    } else if (item.startsWith('(')) {
      // If the item starts with '(', it represents a group of LIQE expressions.
      // Normalize the item by removing parentheses and split it by 'OR' or 'AND'.
      const normalizedItem = item.replace('(', '').replace(')', '');
      const splitExpr = normalizedItem.split(/OR|AND/).map(str => str.trim());

      // Find logical operators within the normalized item.
      const operatorsMatch = normalizedItem.match(/\b(OR|AND)\b/g);
      const operators = Array.from(new Set(operatorsMatch));

      // Deserialize each part of the expression within the group.
      const deserialized = splitExpr.map((expr, index) => {
        // Detect whether 'NOT' is present in the expression.
        let containsNot = expr.includes('NOT');
        const match = expr.match(/:null|:>=|:<=|:>|:<|:/);
        const [variable, value] = expr.split(match[0]);

        return {
          variable: containsNot ? variable.replace('NOT ', '') : variable,
          condition: containsNot ? ':!' : match[0],
          value,
          ...(operators[index - 1] && { operator: operators[index - 1] })
        };
      });

      currentGroup.list = deserialized;

      // If there is a logical operator in the parent expression, set it.
      if (operator) {
        currentGroup.operator = operator;
        operator = '';
      }
      stack.push(currentGroup);
    } else {
      throw Error('Custom expression');
    }
  }

  return stack;
};

// Deserialize a serialized LIQE string into a structured object.
export const deserializeLIQE = (serializedString) => {
  try {
    // Split the serialized string into an array of tokens.
    const innerQueries = splitExpression(serializedString);
    // Transform the array of tokens into a structured object.
    const result = transformArrayToObject(innerQueries);
    return result;
  } catch (e) {
    return null;
  }
};

// This function serializes data into a LIQE (List Item Query Expression) string.
// LIQE is a specialized query language used for querying data.
export const serializeLIQE = (data) => {
  // If the data is an array, it represents a complex LIQE expression with multiple items.
  if (Array.isArray(data)) {
    // Map each item in the array to its serialized form.
    const results = data.map(item => serializeLIQE(item));

    // Reduce the serialized items into a single LIQE string.
    return data.reduce((accumulator, item, index) => {
      // If the item has an 'operator', include it in the serialized result.
      if (item?.operator) {
        return accumulator ? `${accumulator} ${item.operator} ${results[index]}` : `${item.operator} ${results[index]}`;
      } else {
        // If there's no 'operator', simply concatenate the items.
        return accumulator ? `${accumulator} ${results[index]}` : `${results[index]}`;
      }
    }, '');
  } else if (data && typeof data === 'object') {
    // If the data is an object, it represents a single LIQE item or a group of items.
    // If the object has a 'list' property and it's an array, it represents a group of LIQE items.
    if (data.list && Array.isArray(data.list)) {
      // Map each item in the list to its serialized form, considering optional properties.
      const results = data.list.map(item => {
        const variable = item?.variable || '';
        const condition = item?.condition || '';
        const value = item?.value || '';
        const operator = item?.operator ? ` ${item.operator} ` : '';
        // Construct the serialized item.
        return `${operator}${condition === ':!' ? 'NOT ' : ''}${variable}${condition === ':!' ? ':' : condition}${value}`;
      });
      // Enclose the group of items in parentheses and join them into a single string.
      return `(${results.join('')})`;
    } else {
      // If there's no 'list' property, it's a single LIQE item.
      const variable = data.variable || '';
      const condition = data.condition || '';
      const value = data.value || '';
      // Construct the serialized item.
      return `${variable}${condition}${value}`;
    }
  }
};

