import { API_TAGS } from '../../constants/api';
import { DEFAULT_WEBCHAT_DEPLOYMENT } from '../../constants/main';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './botDeploymentsMessages';

export const botDeploymentsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchBotDeployments: build.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments`,
        method: 'GET',
        params: {
          ...urlParams,
          includeTenant: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_DEPLOYMENTS.ERROR
        }
      }),
      providesTags: () => {
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' }
        ];
      },

      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data: deployments } = await Promise.resolve(queryFulfilled);

        if (deployments?.total > 0) {
          const { items } = deployments;
          const previewDeployment = items.find((item) => item.isPreview === true) || {};
          if (previewDeployment.isPreview) {
            await dispatch(botDeploymentsSlice.endpoints.fetchBotDeploymentPreview.initiate({ orgId: args.orgId, tenantId: args.tenantId }));
          } else {
            await dispatch(botDeploymentsSlice.endpoints.createBotDeployment.initiate({ orgId: args.orgId, tenantId: args.tenantId, data: DEFAULT_WEBCHAT_DEPLOYMENT, successMessage: { showMessage: false } }));
          }
        }

        if (deployments?.total === 0) {
          dispatch(botDeploymentsSlice.endpoints.createBotDeployment.initiate({ orgId: args.orgId, tenantId: args.tenantId, data: DEFAULT_WEBCHAT_DEPLOYMENT, successMessage: { showMessage: false } }));
        }
        return deployments;
      }
    }),

    fetchBotDeployment: build.query({
      query: ({ orgId, tenantId, deploymentId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/${deploymentId}`,
        method: 'GET',
        params: {
          ...urlParams,
          includeTenant: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_DEPLOYMENT.ERROR
        }
      }),
      providesTags: (_result, _error, args) => {
        return [{ type: API_TAGS.BOT_DEPLOYMENTS, id: args.deploymentId }];
      }
    }),

    fetchPublicBotDeployment: build.query({
      query: ({ deploymentId, urlParams = {} }) => ({
        url: `public/bot-deployments/${deploymentId}`,
        method: 'GET',
        params: {
          ...urlParams,
          includeTenant: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_DEPLOYMENT.ERROR
        }
      })
    }),

    fetchBotDeploymentPreview: build.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/preview`,
        method: 'GET',
        params: {
          ...urlParams,
          includeTenant: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_DEPLOYMENT_PREVIEW.ERROR
        }
      }),
      providesTags: () => {
        return [{ type: API_TAGS.BOT_DEPLOYMENTS, id: 'PREVIEW' }];
      }
    }),

    createBotDeployment: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_BOT_DEPLOYMENT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_BOT_DEPLOYMENT.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        if (args.isPreview) {
          return [
            { type: API_TAGS.BOT_DEPLOYMENTS, id: 'PREVIEW' },
            { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
            { type: API_TAGS.BOTS, id: args.tenantId }
          ];
        }
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    updateBotDeployment: build.mutation({
      query: ({ orgId, tenantId, deploymentId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/${deploymentId}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_BOT_DEPLOYMENT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_BOT_DEPLOYMENT.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: args.deploymentId },
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteBotDeployment: build.mutation({
      query: ({ orgId, tenantId, deploymentId, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/${deploymentId}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_BOT_DEPLOYMENT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_BOT_DEPLOYMENT.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    cloneBotDeployment: build.mutation({
      query: ({ orgId, tenantId, deploymentId, data = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/${deploymentId}/clone`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CLONE_BOT_DEPLOYMENT.ERROR
        },
        includeResponseHeaders: true,
        responseType: 'text'
      }),
      transformResponse: (response) => {
        return response.headers['hbf-created-resource-id'];
      },
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteBotDeployments: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/bot-deployments/batch`,
        method: 'DELETE',
        body: { ids: data },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_BOT_DEPLOYMENTS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_BOT_DEPLOYMENTS.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOT_DEPLOYMENTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    })

  })
});

export const {
  useFetchBotDeploymentsQuery,
  useLazyFetchBotDeploymentsQuery,
  useFetchBotDeploymentQuery,
  useLazyFetchBotDeploymentQuery,
  useFetchBotDeploymentPreviewQuery,
  useFetchPublicBotDeploymentQuery,
  useCreateBotDeploymentMutation,
  useUpdateBotDeploymentMutation,
  useDeleteBotDeploymentMutation,
  useCloneBotDeploymentMutation,
  useDeleteBotDeploymentsMutation
} = botDeploymentsSlice;
