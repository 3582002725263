import i18n from '../../i18n';
export const MESSAGES = {
  FETCH_PREDEFINED_RESPONSES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch predefined responses.'),
      403: i18n.t('You are not allowed to fetch predefined responses.'),
      404: i18n.t('The predefined responses you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your predefined responses.'),
      default: i18n.t('An error occurred fetching your predefined responses.')
    }
  },
  FETCH_AUTOMATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch automations.'),
      403: i18n.t('You are not allowed to fetch automations.'),
      404: i18n.t('The automations you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your automations.'),
      default: i18n.t('An error occurred fetching your automations.')
    }
  },
  UPDATE_PREDEFINED_RESPONSES: {
    ERROR: {
      401: i18n.t('You are not authorized to update predefined responses.'),
      403: i18n.t('You are not allowed to update predefined responses.'),
      404: i18n.t('The predefined responses you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating your predefined responses.'),
      default: i18n.t('An error occurred updating your predefined responses.')
    },
    SUCCESS: {
      200: i18n.t('Predefined responses updated successfully!')
    }
  },
  UPDATE_AUTOMATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to update automations.'),
      403: i18n.t('You are not allowed to update automations.'),
      404: i18n.t('The automations you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating your automations.'),
      default: i18n.t('An error occurred updating your automations.')
    },
    SUCCESS: {
      200: i18n.t('Automations updated successfully!')
    }
  },
  CREATE_PREDEFINED_RESPONSES: {
    ERROR: {
      401: i18n.t('You are not authorized to create predefined responses.'),
      403: i18n.t('You are not allowed to create predefined responses.'),
      404: i18n.t('The predefined responses you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating your predefined responses.'),
      default: i18n.t('An error occurred creating your predefined responses.')
    },
    SUCCESS: {
      200: i18n.t('Predefined responses created successfully!')
    }
  },
  CREATE_AUTOMATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to create automations.'),
      403: i18n.t('You are not allowed to create automations.'),
      404: i18n.t('The automations you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating your automations.'),
      default: i18n.t('An error occurred creating your automations.')
    },
    SUCCESS: {
      200: i18n.t('Automations created successfully!')
    }
  },
  DELETE_PREDEFINED_RESPONSES: {
    ERROR: {
      401: i18n.t('You are not authorized to delete predefined responses.'),
      403: i18n.t('You are not allowed to delete predefined responses.'),
      404: i18n.t('The predefined responses you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting your predefined responses.'),
      default: i18n.t('An error occurred deleting your predefined responses.')
    },
    SUCCESS: {
      200: i18n.t('Predefined responses deleted successfully!')
    }
  },
  DELETE_AUTOMATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to delete automations.'),
      403: i18n.t('You are not allowed to delete automations.'),
      404: i18n.t('The automations you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting your automations.'),
      default: i18n.t('An error occurred deleting your automations.')
    },
    SUCCESS: {
      200: i18n.t('Automations deleted successfully!')
    }
  }
};
