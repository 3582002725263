// Core imports
import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

// Component imports
import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Steps, Typography } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';

// Misc imports
import { DATA_RETENTION_OPTIONS } from '../../constants/main';
import { useCreateOrganizationMutation } from '../../store/organization/organizationApi';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { NAV_DASHBOARD } from '../../constants/navigation';
import { setOrganization } from '../../store/auth/authActions';
import { updateRecentBots } from '../../store/bots/botsActions';

// Constant declarations
const timezoneOptions = moment.tz.names();
const { Text } = Typography;

const OrgCreateModal = ({
  isModalOpen,
  closeModal
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createOrganization, { isLoading: isCreatingOrganization }] = useCreateOrganizationMutation({ fixedCacheKey: 'createOrganization-shared' });

  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = useMemo(() => [
    {
      title: t('NAME'),
      description: t('Step 1: Set your organization name.'),
      content: (
        <Row>
          <Col span={24}>
            <Form.Item
              name="displayName"
              label={t('Organization Name')}
              requiredMark
              rules={[
                {
                  required: true,
                  message: t('Please enter your organization name.')
                },
                {
                  max: 20,
                  message: t('Please enter a name less than 20 characters.')
                }
              ]}
            >
              <Input size="large" placeholder={t('Max 20 characters')} />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      title: t('SETTINGS'),
      description: t('Step 2: Configuration Settings.'),
      content: (
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('Timezone')}
              name="timezone"
            >
              <Select
                size="large"
                showSearch
                allowClear
                className="w-100"
                placeholder={t('Choose a timezone')}
                showAction={['focus', 'click']}
                onChange={(value) => {
                  form.setFieldsValue({ timezone: value });
                }}
              >
                {
                  timezoneOptions.map(timezone => (
                    <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              label={t('Data Retention')}
              name="dataRetentionPeriod"
            >
              <Select
                allowClear
                size="large"
                className="w-100"
                placeholder={t('Choose data retention period')}
              >
                {
                  DATA_RETENTION_OPTIONS.map((month) => {
                    return (
                      <Select.Option key={month.value} value={month.value}>
                        {month.label}
                      </Select.Option>
                    );
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      title: t('CONFIRM'),
      description: t('Step 3: Confirm your organization details.'),
      content: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert type="info" className="mb-3" message={t('Please review the following details before creating your organization.')} />
          </Col>
          <Col span={24}>
            <Space>
              <Text strong>{t('Organization Name')}:</Text>
              {form.getFieldsValue(true).displayName ?? <Text type="secondary">{t('(not defined)')}</Text>}
            </Space>
          </Col>
          <Col span={24}>
            <Space>
              <Text strong>{t('Timezone')}:</Text>
              {form.getFieldsValue(true).timezone ?? <Text type="secondary">{t('(not defined)')}</Text>}
            </Space>
          </Col>
          <Col span={24}>
            <Space>
              <Text strong>{t('Data Retention')}:</Text>
              {form.getFieldsValue(true).dataRetentionPeriod ?? <Text type="secondary">{t('(not defined)')}</Text>}
            </Space>
          </Col>
        </Row>
      )
    }
  ], [form.getFieldsValue(true)]);

  const goToStep = (stepNumber) => {
    form.validateFields()
      .then(() => {
        setCurrentStep(stepNumber);
      }).catch();
  };

  const goToNextStep = () => {
    goToStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    goToStep(currentStep - 1);
  };

  const onSubmit = async () => {
    const valuesToSubmit = form.getFieldsValue(true);
    const newOrganization = await createOrganization({ data: valuesToSubmit }).unwrap();

    if (newOrganization) {
      // Hide the modal after form submission
      closeModal();
      setCurrentStep(0);
      form.resetFields();

      // Navigate to the newly created organization
      dispatch(setOrganization(newOrganization.id, navigate));
      dispatch(updateRecentBots([]));
      navigate(replaceUrlParams(NAV_DASHBOARD, { orgId: newOrganization.id }));
    }
  };

  const renderCustomDot = (dot, { status, index }) => (
    <span className="cursor-pointer" onClick={() => goToStep(index)}>
      {dot}
    </span>
  );

  return (
    <Modal
      open={isModalOpen}
      onCancel={closeModal}
      footer={null}
      width={600}
      title={
        <Fragment>
          <Typography.Paragraph className="mb-1">
            <Space size={8} className="align-items-center">
              <ApartmentOutlined />
              {t('Create new Organization')}
            </Space>
          </Typography.Paragraph>
          <Typography.Text className="text-muted">
            {steps[currentStep].description}
          </Typography.Text>
        </Fragment>
      }
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row className="p-8" gutter={[16, 40]}>
          <Col span={24}>
            <Steps
              current={currentStep}
              items={steps.map(item => ({ key: item.title, title: item.title }))}
              size="small"
              progressDot={renderCustomDot}
            />
          </Col>

          <Col span={24}>
            {steps[currentStep].content}
          </Col>
        </Row>

        <Divider />

        <Row justify="end" gutter={16}>

          <Col flex="auto">
            {
              currentStep !== 0 && (
                <Button type="text" size="large" className="text-primary" onClick={() => goToPreviousStep()}>
                  {t('BACK')}
                </Button>
              )
            }
          </Col>

          {
            currentStep === 1 && (
              <Col>
                <Button type="text" size="large" className="text-primary" onClick={() => goToNextStep()}>
                  {t('LATER')}
                </Button>
              </Col>
            )
          }

          <Col>
            {
              currentStep === steps.length - 1 ? (
                <Button type="primary" size="large" onClick={onSubmit} loading={isCreatingOrganization}>
                  {t('Create Organization')}
                </Button>
              ) : (
                <Button type="primary" size="large" onClick={() => goToNextStep()}>
                  {t('Next')}
                </Button>
              )
            }
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

OrgCreateModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default OrgCreateModal;
