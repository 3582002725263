import { API_TAGS } from '../../constants/api';
import { API_LIVECHAT_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './liveChatSettingsMessages';

export const livechatSettingsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchLiveChatApplicationSettings: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/livechat-settings`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        }
      }),
      errorMessage: {
        showMessage: true,
        messages: MESSAGES.FETCH_LIVE_CHAT_APPLICATION_SETTINGS.ERROR
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          if (error?.error?.error?.code === 404) {
            dispatch(livechatSettingsSlice.endpoints.updateLiveChatSettings.initiate({ orgId: args.orgId, data: {} }));
          }
        }
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'SETTINGS' }
        ];
      }
    }),

    updateLiveChatSettings: builder.mutation({
      query: ({ orgId, data, urlParams={} }) => ({
        url: `organizations/${orgId}/livechat-settings`,
        method: 'PUT',
        API_URL: API_LIVECHAT_URL,
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_LIVE_CHAT_APPLICATION_SETTINGS.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_LIVE_CHAT_APPLICATION_SETTINGS.SUCCESS
        }
      }),
      transformErrorResponse: (error) => false,
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [ { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'SETTINGS' }];
      }
    }),

    fetchOrganizationPredefinedResponses: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/predefined-responses`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams,
          tenantScope: '*'
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_PREDEFINED_RESPONSES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'PredefinedResponses' }
        ];
      }
    }),

    fetchOrganizationAutomations: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/predefined-actions`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams,
          tenantScope: '*'
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_AUTOMATIONS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'Automations' }
        ];
      }
    })
  })
});

export const {
  useFetchLiveChatApplicationSettingsQuery,
  useUpdateLiveChatSettingsMutation,
  useFetchOrganizationPredefinedResponsesQuery,
  useFetchOrganizationAutomationsQuery
} = livechatSettingsSlice;
