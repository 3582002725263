import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_INTEGRATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the integrations.'),
      403: i18n.t('You are not allowed to fetch the integrations.'),
      404: i18n.t('The integrations you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the integrations.'),
      default: i18n.t('Αn error occurred fetching the integrations.')
    }
  },

  CREATE_INTEGRATION: {
    ERROR: {
      401: i18n.t('You are not authorized to create the integration.'),
      403: i18n.t('You are not allowed to create the integration.'),
      404: i18n.t('The integration you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the integration.'),
      default: i18n.t('An error occurred creating the integration.')
    },
    SUCCESS: {
      200: i18n.t('Integration created successfully!')
    }
  },

  UPDATE_INTEGRATION: {
    ERROR: {
      401: i18n.t('You are not authorized to update the integration.'),
      403: i18n.t('You are not allowed to update the integration.'),
      404: i18n.t('The integration you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the integration.'),
      default: i18n.t('An error occurred updating the integration.')
    },
    SUCCESS: {
      200: i18n.t('Integration updated successfully!')
    }
  },

  DELETE_INTEGRATION: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the integration.'),
      403: i18n.t('You are not allowed to delete the integration.'),
      404: i18n.t('The integration you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the integration.'),
      default: i18n.t('An error occurred deleting the integration.')
    },
    SUCCESS: {
      200: i18n.t('Integration deleted successfully!')
    }
  }
};
