// Core imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Component imports
import { Button, Col, Modal, Row, Typography } from 'antd';

// Misc Imports
import { useUpdateBotMutation } from '../../store/bots/botsApi';

const BotDisabledModalWarning = ({
  isModalOpen,
  closeModal,
  handleSave
}) => {

  const { t } = useTranslation();

  const [{ isLoading: isUpdatingBot }] = useUpdateBotMutation({ fixedCacheKey: `updateTenantSettings` });

  return (
    <Modal
      open={isModalOpen}
      centered
      footer={false}
      closeIcon={<Fragment></Fragment>}
      forceRender
    >
      <Row gutter={24} className="text-center">
        <Col span={24}>
          <Typography.Text className="fw-bold fs-lg">
            {t('Change Bot Availability') + '?'}
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={24} className="text-center mt-3">
        <Col span={24}>
          <Typography.Text className="fs-lg">
            {t('You have changed bot availability!')}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text className="fs-lg">
            {t('Are you sure?')}
          </Typography.Text>
        </Col>
      </Row>
      <Row className="text-center mt-3 justify-content-center">
        <Col span={6} className="mr-2">
          <Button
            className="text-black"
            onClick={closeModal}
            disabled={isUpdatingBot}
            size="middle"
            block
          >
            {t('Back')}
          </Button>
        </Col>
        <Col span={6}>
          <Button
            size="middle"
            loading={isUpdatingBot}
            onClick={handleSave}
            type="primary"
            block
          >
            {t('Save')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

BotDisabledModalWarning.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteBot: PropTypes.func,
  handleSave: PropTypes.func
};
export default BotDisabledModalWarning;
