import { apiSlice } from '../apiSlice';
import { MESSAGES } from './automatedAnswersMessages';
import { ACTIVITY_TYPES, CHAPTERSOURCE_TYPES } from '../../constants/main';
import { API_TAGS } from '../../constants/api';

export const automatedAnswersSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchAutomatedAnswers: build.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'GET',
        params: {
          ...urlParams,
          type: CHAPTERSOURCE_TYPES.FLOWGRAPH,
          activityTypes: `${ACTIVITY_TYPES.AUTOMATED_ANSWERS},${ACTIVITY_TYPES.KNOWLEDGE_BASE_ARTICLE}`
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_AUTOMATED_ANSWERS.ERROR
        }
      }),
      providesTags: (result, error, _args) => {
        if (error?.error) {
          return [{ type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ name }) => ({ type: API_TAGS.AUTOMATED_ANSWERS, name })),
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' }
        ];
      }
    }),

    fetchAutomatedAnswer: build.query({
      query: ({ orgId, tenantId, chapterId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${chapterId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_AUTOMATED_ANSWER.ERROR
        }
      }),
      providesTags: (_result, _error, args) => {
        return [{ type: API_TAGS.AUTOMATED_ANSWERS, id: args.chapterId }];
      }
    }),

    createAutomatedAnswer: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_AUTOMATED_ANSWER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_AUTOMATED_ANSWER.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    createAutomatedAnswers: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: false } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'PUT',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_AUTOMATED_ANSWERS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_AUTOMATED_ANSWERS.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    updateAutomatedAnswer: build.mutation({
      query: ({ orgId, tenantId, automatedAnswerName, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${automatedAnswerName}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_AUTOMATED_ANSWER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_AUTOMATED_ANSWER.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    deleteAutomatedAnswer: build.mutation({
      query: ({ orgId, tenantId, automatedAnswerName, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${automatedAnswerName}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_AUTOMATED_ANSWER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_AUTOMATED_ANSWER.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    /**
     * Updates a list of automated answers
     * @param {String} orgId The current organization ID
     * @param {String} tenantId The current bot ID
     * @param {Array} data The array of chaptersources to update
     * @returns True or false for success or failure
    */
    updateAutomatedAnswers: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'PUT',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_AUTOMATED_ANSWERS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_AUTOMATED_ANSWERS.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    /**
     * Deletes a list of automated answers
     * @param {String} orgId The current organization ID
     * @param {String} tenantId The current bot ID
     * @param {Array} data The array of chaptersource names to delete (array of strings)
     * @returns True or false for success or failure
    */
    deleteAutomatedAnswers: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'DELETE',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_AUTOMATED_ANSWERS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: data.names.length > 1 ? MESSAGES.DELETE_AUTOMATED_ANSWERS.SUCCESS : MESSAGES.DELETE_AUTOMATED_ANSWER.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchAutomatedAnswersQuery,
  useFetchAutomatedAnswerQuery,
  useCreateAutomatedAnswerMutation,
  useUpdateAutomatedAnswerMutation,
  useDeleteAutomatedAnswerMutation,
  useCreateAutomatedAnswersMutation,
  useUpdateAutomatedAnswersMutation,
  useDeleteAutomatedAnswersMutation
} = automatedAnswersSlice;
