import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_ORGANIZATION_SUBSCRIBERS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the subscribers.'),
      403: i18n.t('You are not allowed to fetch the subscribers.'),
      404: i18n.t('The subscribers you are trying to fetch do not exist.'),
      500: i18n.t('There was an error fetching the subscribers.'),
      default: i18n.t('An error occurred fetching the subscribers.')
    }
  }
};
