import Icon from '@ant-design/icons';

const ImportantFlagSvg = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H9L6 5L9 10H0V0Z" fill="#F35902"/>
  </svg>
);

export const ImportantFlag = (props) => (
  <Icon component={ImportantFlagSvg} {...props} />
);
