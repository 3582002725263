// Core imports
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

// Component imports
import { Button, Card, Checkbox, Col, Divider, Form, Input, Popconfirm, Radio, Row, Select, Space, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined, QuestionCircleTwoTone, TeamOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import useUserPermissions from '../../utils/hooks/useUserPermissions';
import { ADDITIONAL_ROLES_ORG, PERMISSIONS_ORG } from '../../constants/main';
import { useParams, useSearchParams } from 'react-router-dom';
import { getCurrentQueryState } from '../../store/utils/getCurrentQueryState';
import { useCreateAutomationMutation, useDeleteAutomationMutation, useUpdateAutomationMutation } from '../../store/predefinedEntities/predefinedEntitiesApi';
import is from '../../utils/is';
import { useFetchOrganizationAutomationsQuery } from '../../store/liveChatSettings/liveChatSettingsApi';
import { ALPHANUMERIC_HYPHEN_UNDERSCORE_REGEX } from '../../constants/regex';

const ADMIN_PERMISSIONS = [PERMISSIONS_ORG.ADMIN, ADDITIONAL_ROLES_ORG.LIVECHAT_ADMIN];

const AutomationsSidebarForm = ({
  selectedAutomation,
  setSelectedAutomation,
  onTriggerAutomation = () => {}
}) => {
  const [form] = Form.useForm();
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();

  const { currentData: activeConversationInfo } = getCurrentQueryState('fetchActiveConversation', { orgId, conversationId: searchParams.get('conversation') });
  const { data: automations } = useFetchOrganizationAutomationsQuery({ orgId, urlParams: { tenantScope: activeConversationInfo?.tenantId } });

  const [deleteAutomation] = useDeleteAutomationMutation();
  const [createAutomation, { isLoading: isCreatingAutomation }] = useCreateAutomationMutation();
  const [updateAutomation, { isLoading: isUpdatingAutomation }] = useUpdateAutomationMutation();

  const userPermissions = useUserPermissions(orgId, activeConversationInfo?.tenantId);
  const hasAdminRights = ADMIN_PERMISSIONS.some(permission => userPermissions.includes(permission));

  useEffect(() => {
    form.setFieldsValue(
      selectedAutomation?.id ? {
        automationName: selectedAutomation.name,
        url: selectedAutomation.url,
        categories: selectedAutomation?.categories?.length > 0 ? selectedAutomation.categories : [],
        agentInput: selectedAutomation.agentInput,
        organizationWide: selectedAutomation.organizationWide,
        tenantId: selectedAutomation.tenantId,
        headers: selectedAutomation.headers,
        runOn: checkConditions(selectedAutomation)
      } : {
        automationName: '',
        url: '',
        categories: [],
        agentInput: [],
        organizationWide: false,
        tenantId: activeConversationInfo?.tenantId,
        headers: [],
        runOn: []
      }
    );
  }, [selectedAutomation]);

  const checkConditions = (item) => {
    let conditions = [];

    if (item) {
      if (item.runOnMissedRequest) {
        conditions.push('runOnMissedRequest');
      }
      if (item.runOnEndConversation) {
        conditions.push('runOnEndConversation');
      }
    }
    return conditions;
  };

  const onSubmitResponse = async (formValues) => {
    let valuesToSubmit = {
      name: formValues?.automationName,
      runOnMissedRequest: is.truthy(formValues.runOn.find(item => item === 'runOnMissedRequest')),
      runOnEndConversation: is.truthy(formValues.runOn.find(item => item === 'runOnEndConversation')),
      ...formValues
    };

    delete valuesToSubmit.automationName;
    delete valuesToSubmit.runOn;

    valuesToSubmit?.agentInput?.forEach((input, index) => {
      if (input?.type !== 'choice') {
        delete input.entries;
      }
    });

    let isSaveSuccessful;

    if (selectedAutomation?.id) {
      isSaveSuccessful = await updateAutomation({ orgId, automationId: selectedAutomation?.id, data: valuesToSubmit }).unwrap();
    } else {
      isSaveSuccessful = await createAutomation({ orgId, data: valuesToSubmit }).unwrap();
    }

    if (isSaveSuccessful) {
      setSelectedAutomation(null);
    }

    return;
  };

  const onDeleteAutomation = async () => {
    if (selectedAutomation) {
      deleteAutomation({ orgId, automationId: selectedAutomation.id });
    }
    return;
  };

  const renderFormFields = () => {
    return (
      <Fragment>
        <Form.Item
          name="automationName"
          label={t('Button Title')}
          tooltip={{
            icon: <QuestionCircleTwoTone />,
            title: t('Enter a short title for the automation button.')
          }}
          rules={[
            {
              required: true,
              message: t('Enter a name for this automation')
            }
          ]}
        >
          <Input
            autoFocus
            placeholder={t('Automation Name')}
            disabled={selectedAutomation.organizationWide && !hasAdminRights}
          />
        </Form.Item>

        <Form.Item
          name="url"
          label={t('Automation URL')}
          tooltip={{
            icon: <QuestionCircleTwoTone />,
            title: t('Type here the URL that will be used for the Automation')
          }}
          rules={[
            {
              validator: async (_rule, value) => {
                try {
                  const callbackUrl = new URL(value);
                  if (callbackUrl) {
                    return Promise.resolve();
                  }
                } catch (ex) {
                  return Promise.reject(new Error(t('Enter a valid URL')));
                }
              }
            }
          ]}
        >
          <Input
            placeholder={t('Enter the automation URL')}
            disabled={selectedAutomation.organizationWide && !hasAdminRights}
          />
        </Form.Item>

        <Form.Item
          name="categories"
          label={t('Categories')}
          labelCol={{ span: 6 }}
          labelAlign="left"
        >
          <Select
            mode="tags"
            tokenSeparators={[',']}
            placeholder={t('Add a category or a list of categories')}
            // Options are all categories from all automations
            options={
              (automations || [])
                .map(automation => automation.categories) // Get all categories
                .flat() // Flatten the array to a single array
                .filter((category, index, self) => category && self.indexOf(category) === index) // Remove duplicates (and empty strings)
                .map(category => ({ label: category, value: category }))
            }
            disabled={!hasAdminRights}
            onInputKeyDown={(e) => {
              if (!ALPHANUMERIC_HYPHEN_UNDERSCORE_REGEX.test(e.key)) {
                e.preventDefault();
                return false;
              }
            }}
          />
        </Form.Item>

        <Divider className="fs-sm text-muted" orientation="left">{t('Agent Input')}</Divider>

        <Form.Item
          shouldUpdate
          className="mb-2"
        >
          {() => (
            <Form.List
              name="agentInput"
            >
              {(fields, { add, remove }, { errors }) => (
                <div>
                  {
                    !fields.length ? (
                      <Typography.Paragraph
                        className="fs-sm text-muted fw-bold text-center p-1"
                        style={{ margin: 'auto' }}
                      >
                        {t('Add inputs here')}
                      </Typography.Paragraph>
                    ) : null
                  }

                  {
                    fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row gutter={[4, 4]} align="middle" wrap={false}>
                        <Col flex="auto">
                          <Card
                            className="shadow-none"
                            size="small"
                          >
                            <Form.Item
                              colon={false}
                              {...restField}
                              name={[name, 'name']}
                              className="mb-1"
                              label={t('Field Name')}
                              labelAlign="left"
                              labelCol={{ xs: 24 }}
                              fieldKey={[fieldKey, 'name']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Enter a name')
                                }
                              ]}
                            >
                              <Input
                                placeholder={t('Field name')}
                                disabled={form.getFieldValue('runOn')?.length}
                                autoFocus
                              />
                            </Form.Item>

                            <Form.Item
                              className="mb-1"
                              colon={false}
                              {...restField}
                              name={[name, 'prompt']}
                              label={t('Field Prompt')}
                              labelAlign="left"
                              labelCol={{ xs: 24 }}
                              fieldKey={[fieldKey, 'prompt']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Enter a prompt')
                                }
                              ]}
                            >
                              <Input
                                placeholder={t('Prompt message')}
                                disabled={form.getFieldValue('runOn')?.length}
                              />
                            </Form.Item>

                            <Form.Item
                              className="mb-1"
                              colon={false}
                              {...restField}
                              name={[name, 'isRequired']}
                              label={t('Required')}
                              labelAlign="left"
                              labelCol={{ xs: 24 }}
                              fieldKey={[fieldKey, 'isRequired']}
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox
                                disabled={form.getFieldValue('runOn')?.length}
                              />
                            </Form.Item>

                            <Form.Item
                              className="mb-1"
                              colon={false}
                              {...restField}
                              name={[name, 'type']}
                              label={t('Field Type')}
                              labelAlign="left"
                              labelCol={{ xs: 24 }}
                              initialValue={'plain_text'}
                            >
                              <Select
                                options={[
                                  {
                                    label: t('Plain text'),
                                    value: 'plain_text'
                                  },
                                  {
                                    label: t('Email'),
                                    value: 'email'
                                  },
                                  {
                                    label: t('Number'),
                                    value: 'number'
                                  },
                                  {
                                    label: t('Phone number'),
                                    value: 'phone_number'
                                  },
                                  {
                                    label: t('Date'),
                                    value: 'date'
                                  },
                                  {
                                    label: t('Dropdown'),
                                    value: 'choice'
                                  }
                                ]}
                                disabled={form.getFieldValue('runOn')?.length}
                              />
                            </Form.Item>

                            {
                              form.getFieldValue(['agentInput', name, 'type']) === 'choice' && (
                                <Fragment>
                                  <Form.List name={[name, 'entries']}>
                                    {(dropdownFields, { add: addDropdownOption, remove: removeDropdownOptions }) => (
                                      <Fragment>
                                        {dropdownFields.map(({ key: dropdownKey, name: dropdownName, ...restDropdownField }) => (
                                          <Form.Item key={dropdownKey} className="m-0">
                                            <Row gutter={12} wrap={false}>
                                              <Col span={10} flex="1 1 100%">
                                                <Form.Item
                                                  className="mb-1"
                                                  colon={false}
                                                  labelAlign="left"
                                                  labelCol={{ xs: 24 }}
                                                  label="Key"
                                                  {...restDropdownField}
                                                  name={[dropdownName, 'key']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: t('Enter a key')
                                                    }
                                                  ]}
                                                >
                                                  <Input placeholder={t('Key')} disabled={form.getFieldValue('runOn')?.length} />
                                                </Form.Item>
                                              </Col>

                                              <Col span={10} flex="1 1 100%">
                                                <Form.Item
                                                  className="mb-1"
                                                  colon={false}
                                                  labelAlign="left"
                                                  labelCol={{ xs: 24 }}
                                                  label="Value"
                                                  {...restDropdownField}
                                                  name={[dropdownName, 'value']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: t('Enter a value')
                                                    }
                                                  ]}
                                                >
                                                  <Input placeholder={t('Value')} disabled={form.getFieldValue('runOn')?.length} />
                                                </Form.Item>
                                              </Col>

                                              <Col span={4} flex="none">
                                                <Form.Item
                                                  className="mb-1"
                                                  label={<Typography.Text className="d-none">{t('Delete')}</Typography.Text>}
                                                  labelAlign="left"
                                                  colon={false}
                                                  labelCol={{ xs: 24 }}
                                                >
                                                  <Button
                                                    shape="circle"
                                                    type="text"
                                                    className="text-secondary"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => removeDropdownOptions(dropdownName)}
                                                    disabled={!hasAdminRights}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          </Form.Item>
                                        ))}

                                        <Form.Item
                                          label={<Typography.Text className="d-none">{t('Add')}</Typography.Text>}
                                          labelAlign="left"
                                          colon={false}
                                          labelCol={{ xs: 24 }}
                                        >
                                          <Button
                                            type="dashed"
                                            block
                                            onClick={() => addDropdownOption()}
                                            icon={<PlusOutlined />}
                                            disabled={form.getFieldValue('runOn')?.length}
                                          >
                                            {t('Add Pair')}
                                          </Button>
                                        </Form.Item>
                                      </Fragment>
                                    )}
                                  </Form.List>
                                </Fragment>
                              )
                            }
                          </Card>
                        </Col>

                        <Col flex="none">
                          <Button
                            shape="circle"
                            type="text"
                            className="text-secondary"
                            icon={<DeleteOutlined />}
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    ))
                  }

                  <Form.Item
                    className="mb-1"
                    label={<Typography.Text className="d-none">{t('Add')}</Typography.Text>}
                    labelAlign="left"
                    colon={false}
                    labelCol={{ xs: 24 }}
                  >
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      disabled={form.getFieldValue('runOn')?.length}
                    >
                      {t('Add field')}
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          )}
        </Form.Item>

        <Form.Item
          label={<Typography.Text strong>{t('Settings')}</Typography.Text>}
          className="mb-2"
        >
          <Form.Item
            name="organizationWide"
            label={<Typography.Text>{t('Who can view & use this response')}</Typography.Text>}
          >
            <Radio.Group
              disabled={!hasAdminRights}
            >
              <Radio value={false}>{t('Only owner')}</Radio>
              <Radio value={true}>{t('All users')} <TeamOutlined/></Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tenantId"
            label={<Typography.Text>{t('This response will be available for')}</Typography.Text>}
          >
            <Radio.Group
              disabled={selectedAutomation.organizationWide && !hasAdminRights}
            >
              <Radio value={activeConversationInfo?.tenantId}>{t('Current bot')}</Radio>
              <Radio value={null}>{t('All bots')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="runOn"
            label={<Typography.Text>{t('This automation will run automatically')}</Typography.Text>}
            labelCol={{ span: 24 }}
            labelAlign="left"
          >
            <Checkbox.Group
              disabled={![...ADMIN_PERMISSIONS].some(permission => userPermissions.includes(permission))}
              options={[
                {
                  label: <Typography.Text>{t('On missed chat')}</Typography.Text>,
                  value: 'runOnMissedRequest'
                },
                {
                  label: <Typography.Text>{t('On terminated chat')}</Typography.Text>,
                  value: 'runOnEndConversation'
                }
              ]}
            />
          </Form.Item>

          <Form.List
            name="headers"
          >
            {(fields, { add, remove }, { errors }) => (
              <Fragment>
                {
                  fields.length ? (
                    <Row wrap={false} gutter={12}>
                      <Col xs={11}>
                        <Typography.Paragraph ellipsis>
                          {t('Header name')}
                        </Typography.Paragraph>
                      </Col>
                      <Col xs={11}>
                        <Typography.Paragraph ellipsis>
                          {t('Header value')}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  ) : (
                    <Form.Item
                      label={<Typography.Text>{t('Add custom headers here')}</Typography.Text>}
                      labelAlign="left"
                      labelCol={{ span: 24 }}
                      tooltip={{ icon: <QuestionCircleTwoTone />, title: t('Add custom headers to your request before triggering the Automation') }}
                      className="mb-0"
                    />
                  )
                }

                <div>
                  {
                    fields.map(({ key, name, ...restField }) => (
                      <Form.Item
                        key={key}
                        className="m-0"
                      >
                        <Row gutter={12} wrap={false}>
                          <Col flex="auto">
                            <Form.Item
                              {...restField}
                              name={[name, 'key']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Enter a header key')
                                }
                              ]}
                            >
                              <Input
                                placeholder={t('Header key')}
                                disabled={!hasAdminRights}
                              />
                            </Form.Item>
                          </Col>

                          <Col flex="auto">
                            <Form.Item
                              {...restField}
                              name={[name, 'value']}
                              rules={[
                                {
                                  required: true,
                                  message: t('Enter a header value')
                                }
                              ]}
                            >
                              <Input
                                placeholder={t('Header value')}
                                disabled={!hasAdminRights}
                              />
                            </Form.Item>
                          </Col>
                          <Col flex="none">
                            <Button
                              shape="circle"
                              type="text"
                              className="text-secondary"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                              disabled={!hasAdminRights}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    ))
                  }
                </div>

                <Button
                  type="dashed"
                  onClick={() => add({ key: '', value: '' })}
                  icon={<PlusOutlined />}
                  disabled={!hasAdminRights}
                >
                  {t('Add header')}
                </Button>
              </Fragment>
            )}
          </Form.List>
        </Form.Item>
      </Fragment>
    );
  };

  const renderFormFooterButtons = () => {
    return (
      <Fragment>
        <Space
          className="mb-2 mt-2"
        >
          <Button
            size="large"
            style={{ width: 140 }}
            onClick={() => setSelectedAutomation(null)}
          >
            {t('Discard Changes')}
          </Button>

          <Button
            size="large"
            htmlType="submit"
            type="primary"
            disabled={selectedAutomation.organizationWide && !hasAdminRights}
            loading={isUpdatingAutomation|| isCreatingAutomation}
            style={{ width: 140 }}
          >
            {t('Save Changes')}
          </Button>
        </Space>

        {
          (!selectedAutomation.organizationWide && selectedAutomation.id) || (selectedAutomation.organizationWide && hasAdminRights) ? (
            <Row
              justify="center"
            >
              <Popconfirm
                title="Delete this automation?"
                okText="Confirm"
                onConfirm={onDeleteAutomation}
              >
                <Button
                  size="small"
                  className="fs-sm"
                  type="link"
                >
                  {t('Delete Automation')}
                </Button>
              </Popconfirm>
            </Row>
          ) : null
        }
      </Fragment>
    );
  };

  const renderFormBody = () => {
    return (
      <Form
        form={form}
        name="automationsSidebarForm"
        className="bg-layout h-100 px-4 pb-4  mt-4"
        style={{ width: 'calc(100% + 32px)', marginLeft: -16 }}
        layout="vertical"
        onFinish={onSubmitResponse}
      >
        <Row
          wrap={false}
          className="h-100 flex-column mb-2"
        >
          <Col flex="auto" >
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <Typography.Paragraph
                  className="fs-lg my-1 d-flex align-items-center"
                >
                  {selectedAutomation.id ? t('Edit Automation') : t('Create Automation')}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Button
                  hidden={!selectedAutomation?.id}
                  type="link"
                  className="fs-lg"
                  onClick={() => onTriggerAutomation(selectedAutomation)}
                >
                  Trigger
                </Button>
              </Col>
            </Row>
            <Divider className="mt-0 mb-2"/>

            {renderFormFields()}

          </Col>
          <Col className="mx-auto">
            {renderFormFooterButtons()}
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          {renderFormBody()}
        </Col>
      </Row>
    </Fragment>
  );
};

AutomationsSidebarForm.propTypes = {
  selectedAutomation: PropTypes.object,
  setSelectedAutomation: PropTypes.func,
  onTriggerAutomation: PropTypes.func
};

export default AutomationsSidebarForm;
