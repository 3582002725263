import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './chapterSourcesMessages';

export const chapterSourcesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchChapterSources: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: false
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }];
      }
    }),

    fetchChapterSource: builder.query({
      query: ({ orgId, tenantId, chaptersourceName, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${chaptersourceName}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: false
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.CHAPTER_SOURCES, id: args.chaptersourceName }];
      }
    }),

    /**
     * Updates all of the bot's chaptersources
     * @param {String} orgId The current organization ID
     * @param {String} tenantId The current bot ID
     * @param {Array} replacementTenantId The bot ID of the bot we are copying from
     * @returns True or false for success or failure
    */
    replaceBotContent: builder.mutation({
      query: ({ orgId, tenantId, replacementTenantId, successMessage = { showMessage: false } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/content-replacement?replacementTenantId=${replacementTenantId}`,
        method: 'POST',
        body: null,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_CONTENT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_CONTENT.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        // As we are replacing the entire bot's content, we need to invalidate all of the bot's content
        return [
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' },
          { type: API_TAGS.AUTOMATED_ANSWERS, id: 'LIST' },
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.LIVE_CHAT_SYSTEM_MESSAGES, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchChapterSourcesQuery,
  useLazyFetchChapterSourcesQuery,
  useFetchChapterSourceQuery,
  useReplaceBotContentMutation
} = chapterSourcesSlice;
