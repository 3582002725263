import { useCallback, useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../../constants/main';

const useContainerResize = (ref, storageKey, initialValue, dragLeft = false) => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(
    JSON.parse(window.localStorage.getItem(STORAGE_KEYS.LIVECHAT_LAYOUT_PREFERENCES))?.[storageKey] ?? initialValue
  );

  const startResizing = useCallback((e) => {
    e.preventDefault();
    document.body.style.cursor = 'col-resize';
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    document.body.style.cursor = 'initial';
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setWidth(
          dragLeft
            ? ref.current.getBoundingClientRect().right - mouseMoveEvent.clientX
            : mouseMoveEvent.clientX - ref.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (storageKey) {
        try {
          const layoutConfig = JSON.parse(window.localStorage.getItem(STORAGE_KEYS.LIVECHAT_LAYOUT_PREFERENCES));
          layoutConfig[storageKey] = width;
          window.localStorage.setItem(STORAGE_KEYS.LIVECHAT_LAYOUT_PREFERENCES, JSON.stringify(layoutConfig));
        } catch {
          return;
        }
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [width]);

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return { width, setWidth, isResizing, startResizing };
};

export default useContainerResize;
