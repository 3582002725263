//Core imports
import { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

// Component imports
import { Row, Skeleton, Typography } from 'antd';

const UserInfoPanel = ({ item }) => {

  return (
    <Fragment>

      <div
        className="pb-4 px-6 overflow-y-auto overflow-x-hidden"
        style={{ minHeight: 132 }}
      >
        <Row
          wrap={false}
          align="middle"
          className="mb-2"
        >
          <Typography.Text style={{ minWidth: 88 }} className="fw-bold fs-sm text-muted">{t('Name')}:</Typography.Text>
          <Typography.Text className="fs-sm" ellipsis>
            {
              item
                ? item.platformUserName ?? '-'
                : <Skeleton.Button size="small" className="p-0 m-0"/>

            }
          </Typography.Text>
        </Row>
        <Row
          wrap={false}
          align="middle"
          className="mb-2"
        >
          <Typography.Text style={{ minWidth: 88 }} className="fw-bold fs-sm text-muted">{t('Email')}:</Typography.Text>
          <Typography.Text className="fs-sm" ellipsis>
            {
              item
                ? item.platformUserEmail ?? '-'
                : <Skeleton.Button size="small" className="p-0 m-0"/>
            }
          </Typography.Text>
        </Row>
        <Row
          wrap={false}
          align="middle"
          className="mb-2"
        >
          <Typography.Text style={{ minWidth: 88 }} className="fw-bold fs-sm text-muted">{t('Phone')}:</Typography.Text>
          <Typography.Text className="fs-sm" ellipsis>
            {
              item
                ? item.platformUserPhone ?? '-'
                : <Skeleton.Button size="small" className="p-0 m-0"/>
            }
          </Typography.Text>
        </Row>
        <Row
          wrap={false}
          align="top"
          className="mb-2"
        >
          <Typography.Text style={{ minWidth: 88 }} className="fw-bold fs-sm text-muted">{t('Address')}:</Typography.Text>
          <Typography.Text className="fs-sm">
            {
              item
                ? item.platformUserAddress ?? '-'
                : <Skeleton.Button size="small" className="p-0 m-0"/>
            }
          </Typography.Text>
        </Row>
      </div>
    </Fragment>
  );
};

UserInfoPanel.propTypes = {
  item: PropTypes.object
};

export default UserInfoPanel;
