import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_CHAT_SESSIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch chat sessions.'),
      403: i18n.t('You are not allowed to fetch chat sessions.'),
      404: i18n.t('The chat sessions you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the chat sessions.'),
      default: i18n.t('An error occurred fetching the chat sessions')
    }
  },
  FETCH_CHAT_SESSION_INFO: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch chat session info.'),
      403: i18n.t('You are not allowed to fetch chat session info.'),
      404: i18n.t('The chat session info you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the chat session info.'),
      default: i18n.t('An error occurred fetching the chat session info')
    }
  },
  UPDATE_CHAT_SESSION: {
    ERROR: {
      401: i18n.t('You are not authorized to update chat session.'),
      403: i18n.t('You are not allowed to update chat session.'),
      404: i18n.t('The chat session you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the chat session.'),
      default: i18n.t('An error occurred updating the chat session')
    },
    SUCCESS: {
      200: i18n.t('Chat session updated successfully!')
    }
  },
  DOWNLOAD_CHAT_SESSIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to download chat sessions.'),
      403: i18n.t('You are not allowed to download chat sessions.'),
      404: i18n.t('The chat sessions you are trying to download do not exist.'),
      500: i18n.t('An error occurred downloading the chat sessions file.'),
      default: i18n.t('An error occurred downloading the chat sessions file.')
    },
    SUCCESS: {
      200: i18n.t('Chat sessions downloaded successfully!')
    }
  },
  DOWNLOAD_CHAT_SESSION: {
    ERROR: {
      401: i18n.t('You are not authorized to download chat session.'),
      403: i18n.t('You are not allowed to download chat session.'),
      404: i18n.t('The chat session you are trying to download does not exist.'),
      500: i18n.t('An error occurred downloading the chat session file.'),
      default: i18n.t('An error occurred downloading the chat session file')
    },
    SUCCESS: {
      200: i18n.t('Chat session downloaded successfully!')
    }
  }
};
