// Core imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Component imports
import { Button, Col, Input, Modal, Row, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const DeleteConfirmationModal = ({
  title,
  content,
  onDelete,
  isDeleting,
  isModalOpen,
  closeModal,
  showDeleteKeyword,
  deleteKeyword='DELETE'
}) => {
  const { t } = useTranslation();
  const [isKeywordCorrect, setIsKeywordCorrect] = useState(false);

  const renderConfirmDeletionInput = () => {
    return (
      <Fragment>
        <Row gutter={24}>
          <Col span={24} className="d-flex justify-content-center">
            <Typography.Text className="mb-3">
              {`Type in '${deleteKeyword}' to confirm deletion`}
            </Typography.Text>
          </Col>
          <Col span={24} className="d-flex justify-content-center">
            <Input
              onChange={(event) => {
                const content = event.currentTarget.value;
                if(content === deleteKeyword) {
                  setIsKeywordCorrect(true);
                } else {
                  if(isKeywordCorrect){
                    setIsKeywordCorrect(false);
                  }
                }
              }}
              className="mb-3 w-60"
              placeholder="Enter Keyword"
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      centered
      onCancel={() => closeModal()}
      footer={false}
      closeIcon={<Fragment></Fragment>}
      forceRender
      style={{ maxWidth: 400 }}
    >
      <Row gutter={24} className="text-center">
        <Col span={24}>
          <Typography.Text className="fw-bold fs-lg">
            {title}
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={24} className="text-center mt-3">
        <Col span={24}>
          <Typography.Text className="fs-sm text-error">
            <WarningOutlined className="mr-1 fs-lg"/>  {t('Non Reversible Action!')}
          </Typography.Text>
        </Col>
      </Row>
      {content}
      {showDeleteKeyword ? renderConfirmDeletionInput() : null}
      <Row className="text-center mt-3 justify-content-center">
        <Col span={6} className="mr-2">
          <Button
            type="secondary"
            onClick={closeModal}
            disabled={isDeleting}
            size="middle"
            block
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            size="middle"
            loading={isDeleting}
            disabled={showDeleteKeyword && !isKeywordCorrect}
            onClick={() => onDelete()}
            type="primary"
            danger
            block
          >
            {deleteKeyword}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteBot: PropTypes.func,
  showDeleteKeyword: PropTypes.bool,
  deleteKeyword: PropTypes.string
};
export default DeleteConfirmationModal;
