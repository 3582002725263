
import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_AUDIT_LOGS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the audit logs.'),
      403: i18n.t('You are not allowed to fetch the audit logs.'),
      404: i18n.t('The audit logs you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the audit logs.'),
      default: i18n.t('An error occurred fetching the audit logs.')
    }
  }
};
