import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_MONITORS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch monitoring data.'),
      403: i18n.t('You are not allowed to fetch monitoring data.'),
      404: i18n.t('The monitoring data you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching monitoring data.'),
      default: i18n.t('An error occurred fetching monitoring data.')
    },
    SUCCESS: {
      200: i18n.t('Monitors fetched successfully.')
    }
  },
  FETCH_MONITORING: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the bot monitoring data.'),
      403: i18n.t('You are not allowed to fetch the bot monitoring data.'),
      404: i18n.t('The bot monitoring data you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the bot monitoring data.'),
      default: i18n.t('An error occurred fetching the bot monitoring data.')
    },
    SUCCESS: {
      200: i18n.t('Monitoring fetched successfully.')
    }
  },
  DELETE_MONITORING: {
    ERROR: {
      401: i18n.t('You are not authorized to disable bot monitoring.'),
      403: i18n.t('You are not allowed to disable bot monitoring.'),
      404: i18n.t('The monitoring you are trying to disable does not exist.'),
      500: i18n.t('There was an error disabling bot monitoring.'),
      default: i18n.t('An error occurred disabling bot monitoring.')
    },
    SUCCESS: {
      200: i18n.t('Bot monitoring disabled!')
    }
  },
  CREATE_MONITORING: {
    ERROR: {
      401: i18n.t('You are not authorized to enable bot monitoring.'),
      403: i18n.t('You are not allowed to enable bot monitoring.'),
      404: i18n.t('The bot monitoring you are trying to enable does not exist.'),
      500: i18n.t('There was an error enabling bot monitoring.'),
      default: i18n.t('An error occurred creating bot monitoring.')
    },
    SUCCESS: {
      200: i18n.t('Bot monitoring enabled!')
    }
  }
};
