// Core imports
import React, { Fragment, useState } from 'react';
import ReactLoginMS from 'react-ms-login';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

// Component imports
import { Button, Checkbox, Col, Divider, Form, Input, Row, Typography } from 'antd';
import GoogleLoginButton from '../UI/GoogleLoginButton';
import { MicrosoftIcon } from '../Icons/MicrosoftIcon';
import { ArrowLeftOutlined, TeamOutlined } from '@ant-design/icons';

// Misc imports
import { AUTHENTICATION_METHODS } from '../../constants/main';
import { MICROSOFT_CLIENT_ID } from '../../constants/env';
import { NAV_LOGIN, NAV_ROOT } from '../../constants/navigation';
import { useRegisterUserMutation } from '../../store/register/registerApi';
import { useAuthenticateUserMutation } from '../../store/auth/authApi';
import { setOrganization } from '../../store/auth/authActions';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isFetchingCurrentUser } = useSelector(state => state.auth);

  const [registerUser, { isLoading: isRegisteringUser }] = useRegisterUserMutation();
  const [loginUser, { isLoading: isLoggingInUser }] = useAuthenticateUserMutation();

  const [isOrgField, setIsOrgField] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [loginMethod, setLoginMethod] = useState(null);

  const onSubmit = async (authenticationMethod, values) => {
    let loginValues;
    const { organizationName } = values;

    if (organizationName) {
      const submitValues = { ...formValues, organizationName };

      try {
        const response = await registerUser({ data: submitValues }).unwrap();

        if (response.error) {
          setIsOrgField(false);
          form.resetFields();
        } else {
          let loginValues;

          switch (loginMethod) {
            case AUTHENTICATION_METHODS.GOOGLE:
              loginValues = { idToken: formValues.loginToken };
              break;
            case AUTHENTICATION_METHODS.MICROSOFT:
              loginValues = { accessToken: formValues.loginToken };
              break;
            case AUTHENTICATION_METHODS.EMAIL:
              loginValues = { email: formValues.email, password: formValues.password };
              break;
            default:
              loginValues = { email: formValues.email, password: formValues.password };
              break;
          }

          const loginResponse = await loginUser({ authenticationMethod: loginMethod, data: {
            ...loginValues
          } }).unwrap();

          if (loginResponse?.user) {
            const { organizations } = loginResponse.user;
            if (organizations.length === 1) {
              dispatch(setOrganization(organizations[0].id, navigate));
              navigate(NAV_ROOT);
            }
          }
        }
      } catch (errorInfo) {
        setIsOrgField(false);
        form.resetFields();
      }
    } else {
      switch (authenticationMethod) {
        case AUTHENTICATION_METHODS.GOOGLE:
          loginValues = { loginType: AUTHENTICATION_METHODS.GOOGLE, loginToken: values.credential };
          setLoginMethod(AUTHENTICATION_METHODS.GOOGLE);
          break;
        case AUTHENTICATION_METHODS.MICROSOFT:
          loginValues = { loginType: AUTHENTICATION_METHODS.MICROSOFT, loginToken: values.access_token };
          setLoginMethod(AUTHENTICATION_METHODS.MICROSOFT);
          break;
        case AUTHENTICATION_METHODS.EMAIL:
        default:
          setLoginMethod(AUTHENTICATION_METHODS.EMAIL);
          loginValues = { fullName: `${values.firstName} ${values.lastName}`, email: values.email, password: values.password };
          break;
      }

      setFormValues({ ...loginValues });
      setIsOrgField(true);
      form.resetFields();
    }
  };

  return (
    <Form
      name="RegistrationForm"
      form={form}
      layout="vertical"
      onFinish={(values) => onSubmit(AUTHENTICATION_METHODS.EMAIL, values)}
      labelCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 12 }}
      wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
      className="pt-10 pr-10 pl-10 pb-5 "
    >
      <Row className="text-center">
        <Col span={24}>
          <Typography.Title className="mb-2" level={2} strong>
            {t('Sign Up')}
          </Typography.Title>
        </Col>
      </Row>

      <Row className="text-center mb-7">
        <Col span={24}>
          <Typography.Text className="fs-lg text-muted">
            {t('Create your account')}
          </Typography.Text>
        </Col>
      </Row>
      {
        !isOrgField ? (
          <Row>
            <Col span={24}>
              <Row justify={'space-between'}>
                <Col span={11}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    required={false}
                    rules={[{
                      required: true,
                      type: 'string',
                      message: t('Please enter your first name')
                    }]}
                  >
                    <Input placeholder="Enter your first name" size="large" />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    required={false}
                    rules={[{
                      required: true,
                      type: 'string',
                      message: t('Please enter your last name')
                    }]}
                  >
                    <Input placeholder="Enter your last name" size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Email"
                name="email"
                required={false}
                rules={[{
                  required: true,
                  type: 'email',
                  message: t('Please enter your email.')
                }]}
              >
                <Input name={['email']} placeholder="Enter your email" size="large" />
              </Form.Item>

              <Form.Item
                label="Password"
                required={false}
                name="password"
                rules={[{
                  required: true,
                  message: t('Please enter your password.')
                }]}
              >
                <Input.Password placeholder="Set your password (min 8 characters)" size="large" />
              </Form.Item>

              <Form.Item
                name="terms"
                valuePropName="checked"
                required={false}
                validateTrigger={['onSubmit']}
                rules={[{
                  validator: async (_rule, value) => {
                    if (!value) {
                      throw new Error('Please agree to our Terms & Conditions');
                    }
                  }
                }]}
              >
                <Checkbox>
                  {t('I agree to the Terms & Conditions')}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="mb-0">
                <Button
                  className="mt-1"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {t('Sign Up')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Divider>or</Divider>
            </Col>

            <Col span={24}>
              <GoogleLoginButton
                onSuccess={(response) => onSubmit(AUTHENTICATION_METHODS.GOOGLE, response)}
                buttonContent={t('Sign Up with Google')}
              />
            </Col>

            <Col span={24}>
              <ReactLoginMS
                handleLogin={(response) => onSubmit(AUTHENTICATION_METHODS.MICROSOFT, response)}
                clientId={MICROSOFT_CLIENT_ID}
                redirectUri={`${window.location.origin.toString()}/ms-auth.html`}
                responseType="token"
                cssClass="ant-btn ant-btn-default ant-btn-lg ant-btn-block"
                btnContent={
                  <Fragment>
                    <MicrosoftIcon className="mr-3" />
                    {t('Sign Up with Microsoft')}
                  </Fragment>
                }
              />
            </Col>

            <Col xs={24}>
              <Divider />
            </Col>

            <Col xs={24}>
              <Typography.Text>{t('Already have an account?')}</Typography.Text>
              <Button className="pl-1" type="link" onClick={() => navigate(NAV_LOGIN)}>
                <strong>{t('Sign In')}</strong>
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={[16, 0]} className="w-100" justify="center">
            <Col xs={24}>
              <Row justify="center" className="mt-5 mb-5">
                <Typography.Title level={4}>{t('Enter your organization name')}</Typography.Title>
              </Row>

              <Row gutter={[16, 8]} className="align-items-baseline mb-1">
                <Col xs={24}>
                  <Form.Item
                    name="organizationName"
                    rules={[{
                      required: true,
                      message: t('Please enter your organization\'s name')
                    }]}
                  >
                    <Input size="large" prefix={<TeamOutlined className="text-secondary mr-2" />} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={6}>
              <Form.Item>
                <Button
                  type="link"
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  block
                  onClick={() => setIsOrgField(false)}
                >
                  {t('Back')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={{ span: 12, offset: 6 }}>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isRegisteringUser || isLoggingInUser || isFetchingCurrentUser}
                  block
                >
                  {t('Continue')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )
      }
    </Form>
  );
};

export default RegistrationForm;
