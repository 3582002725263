// Core imports
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component imports
import { Button, Col, Input, List, Modal, Row, Skeleton, Space, Typography, message } from 'antd';
import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';

// Misc imports
import { useCreateApiTokenMutation } from '../../store/apiTokens/apiTokensApi';
import { getCurrentQueryState } from '../../store/utils/getCurrentQueryState';
import { NAV_ANALYTICS_PUBLIC } from '../../constants/navigation';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { ANALYTICS_SECTIONS, PERMISSIONS_TENANT } from '../../constants/main';
import { copyTextToClipboard } from '../../utils/function/copyToClipboard';
import { useTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;

const ShareAnalyticsModal = (props) => {
  const { open, setOpen, sectionsToShare, includeDatePicker } = props;
  const location = useLocation();
  const { orgId } = useParams();
  const { t } = useTranslation();

  const [createApiToken, { isLoading: isCreatingApiToken }] = useCreateApiTokenMutation({ fixedCacheKey: 'createApiToken-shared' });
  const { data: bots } = getCurrentQueryState('fetchBots', { orgId });

  const [urlToShare, setUrlToShare] = useState(null);

  useEffect(() => {
    if (open) {
      openShareModal();
    }
  }, [open]);

  /**
   * Opens the embedding share modal
   */
  const openShareModal = async () => {
    const currentUrlSearchParams = new URLSearchParams(location.search);
    const botIdsList = currentUrlSearchParams.get('tenants') || (bots?.items || []).map(bot => bot.id).join(',');

    // Create an API token with the required permissions
    const analyticsEmbedToken = await createApiToken({ orgId, data: {
      description: 'Analytics iFrame',
      tenantRoles: Object.fromEntries(botIdsList.split(',').map(botId => [botId, [PERMISSIONS_TENANT.VIEWER]]))
    } }).unwrap();

    if (analyticsEmbedToken) {
      // Create the URL to be shared
      let url = new URL(
        window.location.origin
        + replaceUrlParams(NAV_ANALYTICS_PUBLIC, { orgId })
        + '?'
        + currentUrlSearchParams.toString()
      );

      url.searchParams.delete('tab');
      url.searchParams.set('token', analyticsEmbedToken.token);

      if (includeDatePicker !== undefined) {
        url.searchParams.set('includeDatePicker', includeDatePicker);
      }

      if (sectionsToShare.length) {
        url.searchParams.set('sections', sectionsToShare.join(','));
      }

      // If the tenants list is empty, we need to add all the tenants manually
      if (!currentUrlSearchParams.get('tenants')) {
        url.searchParams.set('tenants', botIdsList);
      }

      setUrlToShare(url);
    } else {
      message.error(t('Something went wrong. Please try again later.'));
    }
  };

  const generateSectionList = (sections = []) => {
    const uniqueParents = [...new Set(sections.map(section => section.split('_')[0]))];

    return uniqueParents.map(parent => {
      const parentSection = Object.values(ANALYTICS_SECTIONS).find(({ key }) => key === parent);

      // Check if only the parent section exists in sectionsToShare array
      const onlyParentExists = sections.includes(parent) && !sections.some(section => section.startsWith(`${parent}_`) && section !== parent);

      const subSections = sections.filter(section => section.startsWith(`${parent}_`) && section !== parent);

      // If only the parent section exists, include all subSections related to that parent
      const allSubSections = onlyParentExists
        ? Object.values(parentSection.subSections).map(subSection => `${parent}_${subSection.key}`)
        : subSections;

      return {
        key: parent,
        title: t(parent, { context: 'analytics section' }),
        subSections: allSubSections.map(subSection => {
          const subSectionKey = subSection.split('_')[1];
          return {
            key: subSection,
            title: t(subSectionKey, { context: 'analytics section' })
          };
        })
      };
    });
  };

  return (
    <Modal
      title={t('Share Analytics')}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          type="primary"
          size="large"
          icon={<CopyOutlined className="fs-lg" />}
          onClick={() => {
            copyTextToClipboard(urlToShare);
          }}
        >
          {t('Copy URL')}
        </Button>
      ]}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {t('Copy the URL below to share the analytics section with others.')}
        </Col>

        <Col span={24} className="mb-3">
          {
            isCreatingApiToken ? (
              <Skeleton.Input
                active
                size="large"
                className="w-100 border-radius-base"
              >
                {t('Creating API token...')}
              </Skeleton.Input>
            ) : <Input size="large" value={urlToShare} />
          }
        </Col>

        <Col span={24}>
          <Paragraph>
            <Space size={6}>
              {includeDatePicker ? <CheckOutlined className="text-success fs-sm" /> : <CloseOutlined className="text-error fs-sm" />}
              {includeDatePicker ? t('Datepicker included') : t('Datepicker not included')}
            </Space>
          </Paragraph>

          <Paragraph className="mb-0">
            <Space size={6}>
              <CheckOutlined className="text-success fs-sm" />
              {t('Visualizations Included:')}
            </Space>
          </Paragraph>

          <List
            size="small"
            style={{ maxHeight: 300, overflowY: 'auto' }}
            bordered={false}
            className="ml-4 scrollbar-custom scrollbar-custom-nohide"
            dataSource={generateSectionList(sectionsToShare)}
            itemLayout="vertical"
            renderItem={item => (
              <List.Item className="border-0 pb-0">
                <Text strong className="d-inline">{item.title}</Text>

                {
                  item.subSections.length > 0 && (
                    <List
                      size="small"
                      dataSource={item.subSections}
                      renderItem={subItem => (
                        <List.Item className="border-0 pb-0 pt-1">
                          {subItem.title}
                        </List.Item>
                      )}
                    />
                  )
                }
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};

ShareAnalyticsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  sectionsToShare: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    additionalUrlSearchParams: PropTypes.string
  })).isRequired,
  includeDatePicker: PropTypes.bool
};

export default ShareAnalyticsModal;
