import { unwrapResult } from '@reduxjs/toolkit';
import { storySlice } from './storyApi';
import { NODE_TYPES } from '../../constants/flowGraph';
import { VARIABLE_TYPES } from '../../constants/main';

/**
 * Update the variables list by appending any local variables to the fetched ones
 * @param {string} orgId The organization ID present in the URL
 * @param {string} tenantId The tenant ID present in the URL
 * @param {Array} nodes The list of nodes in the flow graph
 */
export const updateStoryVariablesWithLocalNodes = (orgId, tenantId, nodes) => async (dispatch) => {
  const extractLocalVariables = (nodes) => {
    const locallyCreatedQuestionNodes = nodes.filter(element => element.type === NODE_TYPES.SELECT && element.data.questionId);
    const locallyCreatedCallbackNodes = nodes.filter(element => element.type === NODE_TYPES.SEND_STORED_DATA && element.data.sendStoredData?.callbackId);
    const locallyCreatedVariableNodes = nodes.filter(element => element.type === NODE_TYPES.VARIABLE && element.data.variable?.key);

    const localVariables = []
      .concat(locallyCreatedVariableNodes.map(node => ({ name: node.data.variable.key, type: node.data.variable.type }))) // Add the locally created variables to the list of variables
      .concat(locallyCreatedQuestionNodes.map(node => ({ name: node.data.questionId, type: VARIABLE_TYPES.QUESTION }))) // Add any yet unsaved question nodes to the list of variables
      .concat(locallyCreatedCallbackNodes.map(node => ({ name: node.data.sendStoredData.callbackId, type: VARIABLE_TYPES.CALLBACK }))); // Add any yet unsaved callback nodes to the list of variables

    return localVariables;
  };

  const mergeAndSortVariables = (variables, localVariables) => {
    const newVariablesMap = [...variables, ...localVariables].reduce((acc, variable) => {
      if (acc[variable.name]) {
        acc[variable.name] = { ...acc[variable.name], ...variable };
      } else {
        acc[variable.name] = variable;
      }
      return acc;
    }, {});

    const updatedVariables = Object.values(newVariablesMap)
      .filter((item, index, self) => self.findIndex(t => t.name === item.name) === index) // Keep only unique values
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically

    return updatedVariables;
  };

  // Fetch the latest data
  dispatch(storySlice.endpoints.fetchStoryVariables.initiate({ orgId, tenantId }))
    .then(unwrapResult) // Unwrap the result of the first dispatch
    .then(() => {
      // Update the store with the updated data
      dispatch(storySlice.util.updateQueryData('fetchStoryVariables', { orgId, tenantId }, (variables) => {
        const localVariables = extractLocalVariables(nodes);

        // Combine fetched and local variables, remove duplicates, and sort alphabetically
        const updatedVariables = mergeAndSortVariables(variables, localVariables);

        return updatedVariables;
      }));
    });
};

