
import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_BACKUPS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch backup data.'),
      403: i18n.t('You are not allowed to fetch backup data.'),
      404: i18n.t('The backup data you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the backup data.'),
      default: i18n.t('An error occurred fetching the backup data.')
    }
  },
  CREATE_BACKUP: {
    ERROR: {
      401: i18n.t('You are not authorized to create backup.'),
      403: i18n.t('You are not allowed to create backup.'),
      404: i18n.t('The backup you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the backup.'),
      default: i18n.t('An error occurred creating the backup.')
    },
    SUCCESS: {
      200: i18n.t('Backup created successfully!')
    }
  },

  DELETE_BACKUP: {
    ERROR: {
      401: i18n.t('You are not authorized to delete backup.'),
      403: i18n.t('You are not allowed to delete backup.'),
      404: i18n.t('The backup you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the backup.'),
      default: i18n.t('An error occurred deleting the backup.')
    },
    SUCCESS: {
      200: i18n.t('Backup deleted successfully!')
    }
  },

  TRIGGER_BACKUP_RESTORE: {
    ERROR: {
      401: i18n.t('You are not authorized to trigger backup restore.'),
      403: i18n.t('You are not allowed to trigger backup restore.'),
      404: i18n.t('The backup you are trying to restore does not exist.'),
      500: i18n.t('An error occurred triggering the backup restore.'),
      default: i18n.t('An error occurred triggering the backup restore.')
    },
    SUCCESS: {
      200: i18n.t('Backup restore triggered successfully!')
    }
  }
};
