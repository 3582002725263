import Icon from '@ant-design/icons';

const HrwizSvg = () => (
  <svg
    width="1.1em"
    height="1.1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    color="currentColor"
  >
    <polygon className="st0" points="1,9.8 12.1,1.6 23,10 18.8,22.7 5.2,22.7 "/>
    <polygon className="st0" points="8.6,12.3 12,9.7 15.4,12.3 14.1,16.2 9.9,16.2 "/>
  </svg>
);

export const HrwizIcon = (props) => (
  <Icon component={HrwizSvg} {...props} />
);
