// Core imports
import { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component imports
import { Form, Input, Typography, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import MediaWidgetModal from '../Modals/MediaWidgetModal';

// Misc imports
import { IS_MEDIA_MANAGER_ENABLED } from '../../constants/env';
import { t } from 'i18next';
import useUserPermissions from '../../utils/hooks/useUserPermissions';
import { PERMISSIONS_GROUP_ADMIN } from '../../constants/main';
import { truncate } from '../../utils/string/truncate';

const AvatarFormFieldSelection = ({
  initialValue,
  showInput=true,
  showUpload=true,
  loading=false,
  shouldValidate=false,
  checkPermissions=true,
  isPublicToOrg=true,
  fields={
    inputField: { name: '', label: '', placeholder: '', required: true, validationMessage: '' },
    uploadField: { name: '', label: '', required: true, validationMessage: '' }
  },
  formInstance
}) => {
  const { orgId, tenantId } = useParams();
  const [fileList, setFileList] = useState([]);
  const [isMediaWidgetOpen, setIsMediaWidgetOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userPermissions = useUserPermissions(orgId, tenantId);
  const canEdit = checkPermissions ? userPermissions.some(permission => PERMISSIONS_GROUP_ADMIN.includes(permission)) : true;

  useEffect(() => {
    if (initialValue) {
      setInputNewImage(initialValue);
      setUploadNewImage(initialValue);
    }
  }, []);

  const openMediaWidgetModal = () => {
    setIsMediaWidgetOpen(true);
  };

  const setUploadNewImage = (url) => {
    formInstance?.setFieldValue(fields?.uploadField?.name, url);
    setFileList([{
      uid: '-1',
      name: 'avatar.png',
      status: 'done',
      url: url
    }]);
  };

  const setInputNewImage = (url) => {
    formInstance?.setFieldValue(fields?.inputField?.name, url);
  };

  const closeMediaWidgetModal = (file) => {
    if (file?.url) {
      setUploadNewImage(file.url);
    }

    const currentTab = searchParams.get('mediaTab');
    if (currentTab === 'library' || currentTab === 'upload') {
      searchParams.delete('mediaTab');
      setSearchParams(searchParams);
    }

    setIsMediaWidgetOpen(false);
  };

  const renderUploadButton = () => {
    return (
      <div
        onClick={() => {
          if (canEdit) {
            openMediaWidgetModal();
          }
        }}
      >
        {loading ? <LoadingOutlined /> : <PlusOutlined />}

        <div className="mt-2">
          {t('Upload Image')}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {
        showUpload && IS_MEDIA_MANAGER_ENABLED && (
          <Fragment>
            <Form.Item
              required={false}
              name={fields?.uploadField?.name || 'image'}
              label={fields?.uploadField?.label || ''}
              rules={shouldValidate && [{
                required: fields?.uploadField?.required,
                message: fields?.uploadField?.validationMessage
              }]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                fileList={fileList[0]?.url ? fileList : []}
                openFileDialogOnClick={false}
                onRemove={(file) => {
                  formInstance?.setFieldValue(fields?.uploadField?.name, '');
                  setFileList([]);
                }}
                showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                disabled={!canEdit || loading}
              >
                {renderUploadButton()}
              </Upload>

              {
                canEdit ? (
                  <MediaWidgetModal
                    onUploadComplete={(url) => setUploadNewImage(url)}
                    onClose={closeMediaWidgetModal}
                    isModalOpen={isMediaWidgetOpen}
                    isPublicToOrg={isPublicToOrg}
                  />
                ) : null
              }
            </Form.Item>
          </Fragment>
        )
      }

      {
        showInput && (
          <Fragment>
            <Form.Item
              required={false}
              label={`${IS_MEDIA_MANAGER_ENABLED ? t('or') : ''} ${fields?.inputField?.label}`}
              name={fields?.inputField?.name}
              rules={shouldValidate && [{
                required: fields?.inputField?.required,
                message: fields?.inputField?.validationMessage
              }]}
            >
              {
                canEdit ? (
                  <Input
                    value={formInstance?.getFieldValue(fields?.inputField?.name)}
                    size="large"
                    placeholder={fields?.inputField?.placeholder}
                    onChange={() => {
                      setInputNewImage(formInstance?.getFieldValue(fields?.inputField?.name));
                      setFileList([
                        {
                          uid: '-1',
                          name: 'avatar.png',
                          status: 'done',
                          url: formInstance?.getFieldValue(fields?.inputField?.name)
                        }
                      ]);
                    }}/>
                ) : (
                  <Fragment>
                    <Typography.Text className="mb-0" copyable={initialValue ? { text: initialValue } : false}>
                      {initialValue ? truncate(initialValue, 80, '...') : t('(No image available)')}
                    </Typography.Text>
                  </Fragment>
                )
              }
            </Form.Item>
          </Fragment>
        )
      }
    </Fragment>
  );
};

AvatarFormFieldSelection.propTypes = {
  loading: PropTypes.bool,
  showInput: PropTypes.bool,
  showUpload: PropTypes.bool,
  fields: PropTypes.object,
  formInstance: PropTypes.any,
  initialValue: PropTypes.string,
  shouldValidate: PropTypes.bool,
  checkPermissions: PropTypes.bool,
  isPublicToOrg: PropTypes.bool
};

export default AvatarFormFieldSelection;
