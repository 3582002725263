import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_BOT_TEMPLATES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot templates.'),
      403: i18n.t('You are not allowed to fetch bot templates.'),
      404: i18n.t('The bot templates you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the bot templates.'),
      default: i18n.t('An error occurred fetching the bot templates.')
    }
  },
  FETCH_BOT_TEMPLATE: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot template.'),
      403: i18n.t('You are not allowed to fetch bot template.'),
      404: i18n.t('The bot template you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the bot template.'),
      default: i18n.t('An error occurred fetching the bot template.')
    }
  }
};
