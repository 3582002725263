// Core imports
import { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Component imports
import { Button } from 'antd';
import { GoogleIcon } from '../Icons/GoogleIcon';

// Misc imports
import useInterval from '../../utils/hooks/useInterval';
import { GOOGLE_CLIENT_ID } from '../../constants/env';

const GoogleLoginButton = ({
  onSuccess,
  buttonContent
}) => {
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const [googleIsLoading, setGoogleIsLoading] = useState(true);
  const [google, setGoogle] = useState();
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState();
  const googleButtonRef = useRef(null);

  /* Serves to parent component the google sign in credentials */
  const handleCallbackResponse = (response) => {
    onSuccess(response);
  };

  /* This interval serves the purpose of being sure that when the script is loaded so is
     the google object. This is needed because the google object might not be available
     immediately after the script is loaded.
  */
  useInterval(
    () => {
      if (typeof window !== 'undefined' && window.google) {
        setGoogle(window.google);
        setGoogleIsLoading(false);
      }
    },
    googleIsLoading ? 100 : null
  );

  useEffect(() => {
    /* if we already have initialized the script then do nothing */
    if (isGoogleScriptLoaded) {
      return undefined;
    }

    /* Function to initialize google sign in when the script loads */
    const initializeGoogle = () => {
      /* If the window.google is not loaded or the script is already loaded there is
         no need to initialize the script. */
      if (!window.google || isGoogleScriptLoaded) {
        return;
      }

      /* If the window is loaded and it's running for first time then set Loaded to true */
      setIsGoogleScriptLoaded(true);

      /* Initialize the google sign in script */
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        ux_mode: 'popup',
        callback: handleCallbackResponse
      });

      const createFakeGoogleWrapper = () => {
        const googleLoginWrapper = document.createElement('div');
        // Or you can hide it in CSS rule for custom-google-button
        googleLoginWrapper.style.display = 'none';
        googleLoginWrapper.classList.add('custom-google-button');

        // Add the wrapper to body
        document.body.appendChild(googleLoginWrapper);

        // Use GSI javascript api to render the button inside our wrapper
        // You can ignore the properties because this button will not appear
        window.google.accounts.id.renderButton(googleLoginWrapper, {
          type: 'icon',
          width: 200
        });

        const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');

        return {
          click: () => {
            googleLoginWrapperButton.click();
          }
        };
      };

      // Now we have a wrapper to click
      const initiateGoogleButtonWrapper = createFakeGoogleWrapper();

      setGoogleButtonWrapper(initiateGoogleButtonWrapper);
    };

    /* Create the script element and append it to the body */
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.id = 'gsi-client';

    /* Add the onload event listener to the script */
    script.onload = initializeGoogle;

    /* Append the script to the body */
    document.body.appendChild(script);

    return () => {
      /* Remove the script from the body on unmount*/
      window.google?.accounts?.id?.cancel();
      document.body.removeChild(script);
    };

  }, [isGoogleScriptLoaded, google]);

  return (
    <Fragment>
      <Button
        ref={googleButtonRef}
        size="large"
        onClick={() => googleButtonWrapper.click()}
        block
        className={`mb-3`}
        loading={googleIsLoading}
        icon={<GoogleIcon className="mr-3" />}
      >
        {buttonContent}
      </Button>
    </Fragment>
  );
};

GoogleLoginButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  buttonContent: PropTypes.string.isRequired
};

export default GoogleLoginButton;
