import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './nlpPipelinesMessages';

export const nlpPipelinesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchNlpPipelines: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines`,
        method: 'GET',
        params: {
          pageSize: 100,
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_NLP_PIPELINES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.NLP_PIPELINES, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ id }) => ({ type: API_TAGS.NLP_PIPELINES, id })),
          { type: API_TAGS.NLP_PIPELINES, id: 'LIST' }
        ];
      }
    }),

    fetchNlpPipeline: builder.query({
      query: ({ orgId, tenantId, nlpPipelineId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines/${nlpPipelineId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_NLP_PIPELINE.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.NLP_PIPELINES, id: args.nlpPipelineId }
        ];
      }
    }),

    fetchNlpPipelineStatus: builder.query({
      query: ({ orgId, tenantId, nlpPipelineId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines/${nlpPipelineId}/status`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_NLP_PIPELINE_STATUS.ERROR
        }
      })
    }),

    createNlpPipeline: builder.mutation({
      query: ({ orgId, tenantId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_NLP_PIPELINE.ERROR
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.NLP_PIPELINES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    updateNlpPipeline: builder.mutation({
      query: ({ orgId, tenantId, nlpPipelineId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines/${nlpPipelineId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_NLP_PIPELINE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_NLP_PIPELINE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.NLP_PIPELINES, id: args.nlpPipelineId },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteNlpPipeline: builder.mutation({
      query: ({ orgId, tenantId, nlpPipelineId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines/${nlpPipelineId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_NLP_PIPELINE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_NLP_PIPELINE.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.NLP_PIPELINES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    trainNlpPipeline: builder.mutation({
      query: ({ orgId, tenantId, nlpPipelineId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/nlp-pipelines/${nlpPipelineId}/train`,
        method: 'POST',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.TRAIN_NLP_PIPELINE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.TRAIN_NLP_PIPELINE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    fetchRagPipelineSettings: builder.query({
      query: ({ serviceUrl, ragPipelineId, token, urlParams = {} }) => ({
        url: `/pipelines/${ragPipelineId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: serviceUrl,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_RAQ_PIPELINE_SETTINGS.ERROR
        },
        extraToken: token
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.RAG_PIPELINE_SETTINGS, id: args.ragPipelineId }
        ];
      }
    }),

    createRagPipelineSettings: builder.mutation({
      query: ({ serviceUrl, data, token, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `/pipelines/`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        API_URL: serviceUrl,
        extraToken: token,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_RAQ_PIPELINE_SETTINGS.ERROR
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.NLP_PIPELINES, id: 'LIST' }
        ];
      }
    }),

    updateRagPipelineSettings: builder.mutation({
      query: ({ serviceUrl, ragPipelineId, token, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `/pipelines/${ragPipelineId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        API_URL: serviceUrl,
        extraToken: token,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_RAQ_PIPELINE_SETTINGS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_RAQ_PIPELINE_SETTINGS.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.RAG_PIPELINE_SETTINGS, id: args.ragPipelineId }
        ];
      }
    })
  })
});

export const {
  useFetchNlpPipelinesQuery,
  useFetchNlpPipelineQuery,
  useFetchNlpPipelineStatusQuery,
  useCreateNlpPipelineMutation,
  useUpdateNlpPipelineMutation,
  useDeleteNlpPipelineMutation,
  useTrainNlpPipelineMutation,
  useFetchRagPipelineSettingsQuery,
  useLazyFetchRagPipelineSettingsQuery,
  useCreateRagPipelineSettingsMutation,
  useUpdateRagPipelineSettingsMutation
} = nlpPipelinesSlice;
