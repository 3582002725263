import i18n from '../../i18n';

export const MESSAGES = {
  UPDATE_CONTENT: {
    ERROR: {
      401: i18n.t('You are not authorized to update the bot\'s content.'),
      403: i18n.t('You are not allowed to update the bot\'s content.'),
      404: i18n.t('The content you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the bot\'s content.'),
      default: i18n.t('An error occurred updating the bot\'s content.')
    },
    SUCCESS: {
      200: i18n.t('Bot content updated successfully!'),
      201: i18n.t('Bot content updated successfully!')
    }
  }
};
