import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './testSetsMessages';

export const testSetsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTestSets: builder.query({
      query: ({ orgId, tenantId, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_TEST_SETS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.TEST_SETS, id: 'LIST' }];
      }
    }),

    fetchTestSet: builder.query({
      query: ({ orgId, tenantId, testId, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets/${testId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_TEST_SET.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.TEST_SETS, id: args.testId }];
      }
    }),

    createTestSet: builder.mutation({
      query: ({ orgId, tenantId, data }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_TEST_SET.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_TEST_SET.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.TEST_SETS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteTestSet: builder.mutation({
      query: ({ orgId, tenantId, nluTestId }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets/${nluTestId}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_TEST_SET.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_TEST_SET.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.TEST_SETS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    updateTestSet: builder.mutation({
      query: ({ orgId, tenantId, nluTestId, data, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets/${nluTestId}`,
        method: 'PATCH',
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_TEST_SET.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_TEST_SET.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.TEST_SETS, id: 'LIST' },
          { type: API_TAGS.TEST_SETS, id: args.nluTestId },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    runTestSet: builder.mutation({
      query: ({ orgId, tenantId, nlpTestId, nlpPipelines, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets/${nlpTestId}`,
        method: 'POST',
        body: { nlpPipelines },
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.RUN_TEST_SET.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.RUN_TEST_SET.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.TEST_SETS, id: 'LIST' },
          { type: API_TAGS.TEST_SETS, id: args.nluTestId }
        ];
      }
    }),

    testSetResult: builder.query({
      query: ({ orgId, tenantId, nlpTestId, runId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/test-sets/${nlpTestId}/runs/${runId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_TEST_SETS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.TEST_SETS, id: 'LIST' }];
      }
    })
  })
});

export const {
  useFetchTestSetsQuery,
  useFetchTestSetQuery,
  useCreateTestSetMutation,
  useDeleteTestSetMutation,
  useUpdateTestSetMutation,
  useRunTestSetMutation,
  useTestSetResultQuery,
  useLazyTestSetResultQuery
} = testSetsSlice;
