import { apiSlice } from '../apiSlice';
import { MESSAGES } from './forgotPasswordMessages';

export const forgotPasswordSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: ({ data }) => ({
        url: 'public/forgot-password',
        method: 'POST',
        withAuth: false,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.RESET_PASSWORD.ERROR
        }
      })
    }),

    forgotPassword: builder.mutation({
      query: ({ data }) => ({
        url: `public/forgot-password-request`,
        method: 'POST',
        body: data,
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FORGOT_PASSWORD.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.FORGOT_PASSWORD.SUCCESS
        },
        transformResponse: (response) => true
      })
    })
  })
});

export const { useResetPasswordMutation, useForgotPasswordMutation } = forgotPasswordSlice;
