// Core imports
import { t } from 'i18next';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Component imports
import { Col, Row, Typography } from 'antd';
import LoginForm from '../components/Forms/LoginForm';
import GradientCard from '../components/UI/GradientCard';
import LightAppLogo from '../assets/logo-full-light.svg';
import { useNavigate } from 'react-router-dom';

// Misc imports
import { NAV_DASHBOARD } from '../constants/navigation';
import replaceUrlParams from '../utils/string/replaceUrlParams';
import is from '../utils/is';

const LoginScreen = () => {
  const { currentUser, organizationId } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const isAuthenticated = !!currentUser;

  useEffect(() => {
    /*
    * This check is used to prevent redirect conflicts
    * when running the console through an MS Teams tab
    */
    if (isAuthenticated && !is.inMicrosoftTeams) {
      navigate(replaceUrlParams(NAV_DASHBOARD, { orgId: organizationId }));
    }
  }, [isAuthenticated]);

  return (
    <GradientCard
      leftSizeContent={<LoginForm />}
      rightSizeContent={
        <Fragment>
          <Row justify={'center'} className="text-center mt-15 fs-xxl">
            <Col span={24}>
              <img style={{ marginTop: '150px' }} src={LightAppLogo} alt="logo" width={360} />
            </Col>
          </Row>
          <Row className="position-absolute" style={{ bottom: '7%' }}>
            <Col xs={24}>
              <Typography.Title className="text-white pl-15 pr-15 mb-1" level={4}>
                {
                  t(`Unlock the power of communication automation with Language AI.`)
                }
              </Typography.Title>
            </Col>
          </Row>
        </Fragment>
      }
    />
  );
};

export default LoginScreen;
