// Core imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// Component imports
import { Button, Col, Input, Modal, Row, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

// Misc imports
import { useDeleteOrganizationMutation } from '../../store/organization/organizationApi';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { NAV_DASHBOARD, NAV_LOGOUT } from '../../constants/navigation';

const DeleteOrganizationModal = ({
  isModalOpen,
  closeModal,
  deleteKeyword = 'DELETE',
  showDeleteKeyword
}) => {
  const { currentUser } = useSelector((state) => state.auth);
  const { orgId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteOrg, { isLoading: isDeletingOrg }] = useDeleteOrganizationMutation();

  const [isKeywordCorrect, setIsKeywordCorrect] = useState(false);

  const renderConfirmDeletionInput = () => {
    return (
      <Fragment>
        <Row gutter={24}>
          <Col span={24} className="d-flex justify-content-center">
            <Typography.Text className="mb-3">
              {`Type in '${deleteKeyword}' to confirm deletion`}
            </Typography.Text>
          </Col>
          <Col span={24} className="d-flex justify-content-center">
            <Input
              onChange={(event) => {
                const content = event.currentTarget.value;
                if(content === deleteKeyword) {
                  setIsKeywordCorrect(true);
                } else {
                  if(isKeywordCorrect){
                    setIsKeywordCorrect(false);
                  }
                }
              }}
              className="mb-3 w-60"
              placeholder="Enter Keyword"
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  const handleDeletion = async () => {
    const isDeleted = await deleteOrg({ orgId }).unwrap();
    if (isDeleted) {
      if (currentUser.organizations.length === 1) {
        navigate(NAV_LOGOUT);
      } else {
        let orgIdToRedirect;
        if ( currentUser.organizations[0].id === orgId) {
          orgIdToRedirect = currentUser.organizations[1].id;
        } else {
          orgIdToRedirect = currentUser.organizations[0].id;
        }
        navigate(replaceUrlParams(NAV_DASHBOARD, { orgId: orgIdToRedirect }));
      }

    }
  };

  return (
    <Modal
      open={isModalOpen}
      centered
      footer={false}
      forceRender
      closeIcon={<Fragment></Fragment>}
    >
      <Row gutter={24} className="text-center">
        <Col span={24}>
          <Typography.Text className="fw-bold fs-lg">
            {t('Delete Organization Forever?')}
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={24} className="text-center mt-3">
        <Col span={24}>
          <Typography.Text className="fs-sm text-error">
            <WarningOutlined className="mr-1 fs-lg"/>  {t('Non Reversible Action!')}
          </Typography.Text>
        </Col>
      </Row>
      <Col span={24} className ="d-flex justify-content-center">
        <Typography.Paragraph className="ml-4 mr-4">
          {t('Delete current organization, including all Bots, data, settings, and connections.')}
        </Typography.Paragraph>
      </Col>
      {showDeleteKeyword ? renderConfirmDeletionInput() : null}
      <Row className="text-center mt-3 justify-content-center">
        <Col span={6} className="mr-2">
          <Button
            type="secondary"
            onClick={closeModal}
            size="middle"
            disabled={isDeletingOrg}
            block
          >
            {t('No')}
          </Button>
        </Col>
        <Col span={6}>
          <Button
            size="middle"
            onClick={handleDeletion}
            disabled={showDeleteKeyword && !isKeywordCorrect}
            loading={isDeletingOrg}
            type="primary"
            danger
            block
          >
            {t('Delete')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

DeleteOrganizationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteBot: PropTypes.func,
  showDeleteKeyword: PropTypes.bool,
  deleteKeyword: PropTypes.string
};
export default DeleteOrganizationModal;
