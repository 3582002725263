// Core imports
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Component imports
import { Button, Space, Col, Modal, Row, Typography, Divider, Card, Grid, Checkbox } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';

// Misc imports
import { INTEGRATION_SCOPES } from '../../constants/main';
import i18n from '../../i18n';

// Constant declarations
const { useBreakpoint } = Grid;
const { Text, Paragraph } = Typography;
const { Meta } = Card;

const CreateIntegrationModal = ({
  isModalOpen,
  closeModal,
  setIntegration
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const isMobileSize = (screens.xs || screens.sm || screens.md) && !screens.lg;
  const [scopeFilters, setScopeFilters] = useState([]);
  const [filteredScopes, setFilteredScopes] = useState(Object.values(INTEGRATION_SCOPES));

  useEffect(() => {
    if ( scopeFilters.length > 0 ) {
      const filtered = Object.values(INTEGRATION_SCOPES).filter((scope) => scopeFilters.includes(scope.key));

      setFilteredScopes(filtered);
    } else {
      setFilteredScopes(Object.values(INTEGRATION_SCOPES));
    }
  }, [scopeFilters]);

  const handleCheckboxChange = (checkedValues) => {
    setScopeFilters(checkedValues);
  };

  return (
    <Modal
      open={isModalOpen}
      className={isMobileSize ? 'w-80' : 'w-50'}
      centered
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      bodyStyle={{ padding: 0 }}
      forceRender
      title={
        <Fragment>
          <Paragraph className="mb-1">
            <Space size={8} className="align-items-center">
              <DeploymentUnitOutlined />
              {t('Add new Integration')}
            </Space>
          </Paragraph>

          <Text className="text-muted">
            {t('Connect your established tools and streamline your workflows.')}
          </Text>
        </Fragment>
      }
    >
      <Row className={isMobileSize ? 'flex-column' : ''}>
        <Col span={isMobileSize ? 24 : 8} flex={1} className="overflow-y-auto overflow-x-hidden scrollbar-custom pb-2" style={{ height: isMobileSize ? 'auto' : '500px' }}>
          <Row className="px-6 py-3" justify={'space-between'} align={'middle'}>
            <Col>
              <Text className="fs-xs" type="primary">
                {t('SCOPE FILTERS')}
              </Text>
            </Col>

            <Col>
              <Button className="fs-xs fw-400" type="link" onClick={() => handleCheckboxChange([])}>
                <Text className="fs-xs" type="secondary">{t('CLEAR FILTERS')}</Text>
              </Button>
            </Col>
          </Row>

          <Checkbox.Group value={scopeFilters} className="mb-0 pl-4" onChange={handleCheckboxChange}>
            <Row className="px-2">
              {Object.entries(INTEGRATION_SCOPES).map(([key, value]) => {
                return (
                  <Col span={24}>
                    <Checkbox value={key}>{i18n.t(key, { context: 'integration category' })}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Col>

        <Divider type="vertical" className="h-auto m-0" />

        <Col span={isMobileSize ? 24 : 15} flex={4} className={`py-6 ${isMobileSize ? 'px-6' : 'pl-12'}`}>
          <Row className="mb-4" justify={'space-between'}>
            <Col>
              <Text className="fs-md" type="primary">
                {t('PLATFORMS')}
              </Text>

              {
                filteredScopes?.length === Object.values(INTEGRATION_SCOPES).length
                  ? <Text className="fs-md ml-3" type="secondary">{t('All Scopes')} ({filteredScopes.length})</Text>
                  : <Text ellipsis className="fs-md ml-3" type="secondary">{filteredScopes.map((scope) => scope.label).join(' / ')} ({filteredScopes.length})</Text>
              }
            </Col>
          </Row>

          <Row gutter={[12, 12]} className="overflow-y-auto overflow-x-hidden scrollbar-custom py-2" style={{ minHeight: '300px', maxHeight: '400px' }}>
            {
              filteredScopes.map((scope) => {
                return (
                  <Col className={isMobileSize ? 'w-100' : 'w-50'} >
                    <Card
                      bodyStyle={{ paddingTop: '8px' }}
                      cover={
                        <Row gutter={[12, 12]} align={'middle'} justify={'center'} className="mx-auto w-fc text-center mt-3">
                          <Col span={24}>
                            <Text className="fs-sm" strong>{scope.label.toUpperCase()}</Text>
                          </Col>
                          <Col span={24}>
                            {scope.icon}
                          </Col>
                          <Col span={24}>
                            <Text className="fs-lg" strong>{scope.type.label}</Text>
                          </Col>
                          <Col className="px-5 fs-sm" span={24}>
                            <Paragraph ellipsis={{ rows: 2, tooltip: scope.description }}>{scope.description}</Paragraph>
                          </Col>
                        </Row>
                      }
                    >
                      <Meta
                        className="w-fc mx-auto"
                        description={
                          <Row align={'middle'} justify={'center'}>
                            <Col span={24} className="mt-2">
                              <Button onClick={() => setIntegration({ type: scope.type.key, category: scope.key })}>{t('Integrate')}</Button>
                            </Col>
                          </Row>
                        }
                      />
                    </Card>
                  </Col>
                );
              })
            }
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

CreateIntegrationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  setIntegration: PropTypes.func
};
export default CreateIntegrationModal;
