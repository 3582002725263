// Core imports
import { t } from 'i18next';
import React, { Fragment } from 'react';

// Component imports
import { Col, Row, Typography } from 'antd';
import GradientCard from '../components/UI/GradientCard';
import LightAppLogo from '../assets/logo-full-light.svg';
import RegistrationForm from '../components/Forms/RegistrationForm';

const RegistrationScreen = () => {
  return (
    <GradientCard
      leftSizeContent={<RegistrationForm />}
      rightSizeContent={
        <Fragment>
          <Row justify={'center'} className="text-center mt-15 fs-xxl">
            <Col span={24}>
              <img style={{ marginTop: '150px' }} src={LightAppLogo} alt="logo" width={360} />
            </Col>
          </Row>
          <Row className="position-absolute" style={{ bottom: '7%' }}>
            <Col xs={24}>
              <Typography.Title className="text-white pl-15 pr-15 mb-1" level={4}>
                {
                  t(`Unlock the power of communication automation with Language AI.`)
                }
              </Typography.Title>
            </Col>
          </Row>
        </Fragment>
      }
    />
  );
};

export default RegistrationScreen;
