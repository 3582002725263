import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_LIVE_CHAT_APPLICATION_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat application settings.'),
      403: i18n.t('You are not allowed to fetch LiveChat application settings.'),
      404: i18n.t('The LiveChat application settings you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the LiveChat application settings.'),
      default: i18n.t('An error occurred fetching the LiveChat application settings.')
    }
  },
  UPDATE_LIVE_CHAT_APPLICATION_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to update LiveChat application settings.'),
      403: i18n.t('You are not allowed to update LiveChat application settings.'),
      404: i18n.t('The LiveChat application settings you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the LiveChat application settings.'),
      default: i18n.t('An error occurred updating the LiveChat application settings.')
    },
    SUCCESS: {
      200: i18n.t('LiveChat application settings updated successfully!')
    }
  },
  FETCH_PREDEFINED_RESPONSES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch predefined responses.'),
      403: i18n.t('You are not allowed to fetch predefined responses.'),
      404: i18n.t('The predefined responses you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your predefined responses.'),
      default: i18n.t('An error occurred fetching your predefined responses.')
    }
  },
  FETCH_AUTOMATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch automations.'),
      403: i18n.t('You are not allowed to fetch automations.'),
      404: i18n.t('The automations you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your automations.'),
      default: i18n.t('An error occurred fetching your automations.')
    }
  }
};
