import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class PrimaryTextBlot extends Inline {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('class', 'text-primary');
    return node;
  }

  static formats(node) {
    return node.tagName === 'SPAN' && node.className === 'text-primary';
  }
}

PrimaryTextBlot.blotName = 'primaryTextColor';
PrimaryTextBlot.tagName = 'span';

Quill.register(PrimaryTextBlot);

export default PrimaryTextBlot;
