import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './knowledgeBasesMessages';

export const knowledgeBasesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Knowledge Bases
    fetchKnowledgeBases: build.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/knowledge-bases`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    fetchKnowledgeBase: build.query({
      query: ({ orgId, knowledgeBaseId, urlParams = {} }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: args.knowledgeBaseId }];
      }
    }),

    createKnowledgeBase: build.mutation({
      query: ({ orgId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE.SUCCESS
        }
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    updateKnowledgeBase: build.mutation({
      query: ({ orgId, id, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${id}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' },
          { type: API_TAGS.KNOWLEDGE_BASES, id: args.id }
        ];
      }
    }),

    updateKnowledgeBases: build.mutation({
      query: ({ orgId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/bulk-update`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASES.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    syncKnowledgeBase: build.mutation({
      query: ({ orgId, id, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${id}/sync`,
        method: 'POST',
        body: null,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.SYNC_KNOWLEDGE_BASE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.SYNC_KNOWLEDGE_BASE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    syncKnowledgeBases: build.mutation({
      query: ({ orgId, urlParams, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/bulk-sync`,
        method: 'POST',
        body: null,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.SYNC_KNOWLEDGE_BASES.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.SYNC_KNOWLEDGE_BASES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    deleteKnowledgeBase: build.mutation({
      query: ({ orgId, id, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${id}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }
        ];
      }
    }),

    deleteKnowledgeBases: build.mutation({
      query: ({ orgId, urlParams, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASES.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASES.SUCCESS
        }
      }),

      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.KNOWLEDGE_BASES, id: 'LIST' }];
      }
    }),

    // Knowledge Base Articles
    fetchKnowledgeBaseArticles: build.query({
      query: ({ orgId, knowledgeBaseId, urlParams = {} }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/articles`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASE_ARTICLES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: 'LIST' }];
      }
    }),
    fetchKnowledgeBaseArticle: build.query({
      query: ({ orgId, knowledgeBaseId, articleId, urlParams }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/articles/${articleId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASE_ARTICLE.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: args.articleId }];
      }
    }),
    createKnowledgeBaseArticle: build.mutation({
      query: ({ orgId, knowledgeBaseId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/articles`,
        method: 'POST',
        body: data,
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_ARTICLE.SUCCESS
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_ARTICLE.ERROR
        }
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: 'LIST' }];
      }
    }),

    updateKnowledgeBaseArticle: build.mutation({
      query: ({ orgId, knowledgeBaseId, id, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/articles/${id}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE_ARTICLE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE_ARTICLE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: 'LIST' },
          { type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: args.id }];
      }
    }),

    createKnowledgeBaseArticlesFromPdf: build.mutation({
      query: ({ orgId, knowledgeBaseId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/pdf-articles`,
        method: 'POST',
        body: data,
        stringifyBody: false,
        extraHeaders: {
          'Accept': 'multipart/form-data'
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_ARTICLES_FROM_PDF.SUCCESS
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_ARTICLES_FROM_PDF.ERROR
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: 'LIST' }];
      }
    }),

    deleteKnowledgeBaseArticle: build.mutation({
      query: ({ orgId, knowledgeBaseId, id, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/articles/${id}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE_ARTICLE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE_ARTICLE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: 'LIST' }
        ];
      }
    }),

    // Knowledge Base Groups
    fetchKnowledgeBaseGroups: build.query({
      query: ({ orgId, knowledgeBaseId, urlParams = {} }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/kb-groups`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASE_GROUPS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_GROUPS, id: 'LIST' }];
      }
    }),
    fetchKnowledgeBaseGroup: build.query({
      query: ({ orgId, knowledgeBaseId, id, urlParams }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/kb-groups/${id}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_KNOWLEDGE_BASE_GROUP.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_ARTICLES, id: args.articleId }];
      }
    }),
    createKnowledgeBaseGroup: build.mutation({
      query: ({ orgId, knowledgeBaseId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/kb-groups`,
        method: 'POST',
        body: data,
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_GROUP.SUCCESS
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_KNOWLEDGE_BASE_GROUP.ERROR
        }
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.KNOWLEDGE_BASE_GROUPS, id: 'LIST' }];
      }
    }),

    updateKnowledgeBaseGroup: build.mutation({
      query: ({ orgId, knowledgeBaseId, id, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/kb-groups/${id}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_KNOWLEDGE_BASE_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.KNOWLEDGE_BASE_GROUPS, id: 'LIST' },
          { type: API_TAGS.KNOWLEDGE_BASE_GROUPS, id: args.id }];
      }
    }),

    deleteKnowledgeBaseGroup: build.mutation({
      query: ({ orgId, knowledgeBaseId, id, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/knowledge-bases/${knowledgeBaseId}/kb-groups/${id}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_KNOWLEDGE_BASE_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.KNOWLEDGE_BASE_GROUPS, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchKnowledgeBasesQuery,
  useFetchKnowledgeBaseQuery,
  useLazyFetchKnowledgeBasesQuery,
  useCreateKnowledgeBaseMutation,
  useUpdateKnowledgeBaseMutation,
  useUpdateKnowledgeBasesMutation,
  useSyncKnowledgeBaseMutation,
  useSyncKnowledgeBasesMutation,
  useDeleteKnowledgeBaseMutation,
  useDeleteKnowledgeBasesMutation,
  useFetchKnowledgeBaseArticlesQuery,
  useFetchKnowledgeBaseArticleQuery,
  useCreateKnowledgeBaseArticlesFromPdfMutation,
  useLazyFetchKnowledgeBaseArticleQuery,
  useCreateKnowledgeBaseArticleMutation,
  useUpdateKnowledgeBaseArticleMutation,
  useDeleteKnowledgeBaseArticleMutation,
  useFetchKnowledgeBaseGroupsQuery,
  useFetchKnowledgeBaseGroupQuery,
  useCreateKnowledgeBaseGroupMutation,
  useUpdateKnowledgeBaseGroupMutation,
  useDeleteKnowledgeBaseGroupMutation
} = knowledgeBasesSlice;
