import { apiSlice } from '../apiSlice';
import { API_LIVECHAT_URL } from '../../constants/env';
import { MESSAGES } from './liveChatMessages';
import handleBlobTypes from '../utils/handleBlobTypes';
import { livechatActions } from './liveChatSlice';
import { API_TAGS } from '../../constants/api';

export const livechatSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchRequests: builder.query({
      query: ({ orgId, urlParams }) => ({
        url: `agent/organizations/${orgId}/livechat-requests`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_LIVE_CHAT_REQUESTS.ERROR
        }
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs.orgId}`;
      },
      merge: (currentCache, newItems, args) => {
        if (args.arg.replace || args.arg?.urlParams?.skip === 0) {
          currentCache.data = [...newItems.data];
          currentCache.total = newItems.total;
        } else {
          currentCache.data.push(...newItems.data);
        }
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_REQUESTS, id: 'LIST' }
        ];
      }
    }),

    fetchChatSession: builder.query({
      query: ({ orgId, tenantId, sessionId, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/${sessionId}/messages`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_LIVE_CHAT_SESSION.ERROR
        }
      })
    }),

    fetchOrganizationTags: builder.query({
      query: ({ orgId, urlParams }) => ({
        url: `organizations/${orgId}/tags`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_LIVE_CHAT_SESSION.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.TAGS, id: 'LIST' }
        ];
      }
    }),

    updateOrganizationTags: builder.mutation({
      query: ({ orgId, data, urlParams }) => ({
        url: `organizations/${orgId}/tags`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_LIVE_CHAT_SESSION.ERROR
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.TAGS, id: 'LIST' }
        ];
      }
    }),

    fetchConversationTags: builder.query({
      query: ({ orgId, tenantId, sessionId, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/${sessionId}/tags`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_LIVE_CHAT_SESSION.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_CONVERSATION_TAGS, id: 'LIST' }
        ];
      }
    }),

    updateConversationTags: builder.mutation({
      query: ({ orgId, tenantId, sessionId, data, urlParams }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/${sessionId}/tags`,
        method: 'PATCH',
        body: data,
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: false,
          messages: MESSAGES.FETCH_LIVE_CHAT_SESSION.ERROR
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.LIVE_CHAT_CONVERSATION_TAGS, id: 'LIST' }
        ];
      }
    }),

    fetchConversationMessages: builder.query({
      query: ({ orgId, urlParams, replace }) => ({
        url: `organizations/${orgId}/livechat-messages`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_LIVE_CHAT_MESSAGES.ERROR
        }
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs?.orgId}${queryArgs?.urlParams?.conversation}`;
      },
      transformResponse: (response) => {
        const { data, total } = response;
        return {
          data: [...(new Set(data))],
          total
        };
      },
      merge: (currentCache, newItems, args) => {
        if (args.arg.replace || args.arg.urlParams.skip === 0) {
          currentCache.data = [...newItems.data];
          currentCache.total = newItems.total;
        } else {
          currentCache.data.push(...newItems.data);
        }
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_MESSAGES, id: args.urlParams.conversation }
        ];
      }
    }),

    fetchActiveConversation: builder.query({
      query: ({ orgId, conversationId, urlParams }) => ({
        url: `organizations/${orgId}/livechat-conversations/${conversationId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_LIVE_CHAT_CONVERSATION_INFO.ERROR,
          errorCodesToIgnore: [400, 404]
        }
      }),
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs?.orgId}${queryArgs?.conversationId}`;
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled.then(async response => {
          await dispatch(livechatActions.fetchActiveConversationComplete(response.data));
        }).catch(async error => {
          await dispatch(livechatActions.fetchActiveConversationFail(error));
        });
      }
    }),

    fetchConversations: builder.query({
      query: ({ orgId, urlParams }) => ({
        url: `agent/organizations/${orgId}/livechat-conversations`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_LIVE_CHAT_CONVERSATIONS.ERROR
        }
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs.orgId}`;
      },
      merge: (currentCache, newItems, otherArgs) => {
        (otherArgs?.arg?.replace || otherArgs?.arg?.urlParams?.skip === 0) && (currentCache.data = []);
        currentCache.data.push(...newItems.data);
        currentCache.total = newItems.total;
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_CONVERSATIONS, id: 'LIST' }
        ];
      }
    }),

    uploadAttachments: builder.mutation({
      query: ({ data, urlParams }) => ({
        url: 'livechat-attachments',
        method: 'POST',
        API_URL: API_LIVECHAT_URL,
        body: data,
        stringifyBody: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPLOAD_ATTACHMENT.ERROR
        },
        params: {
          ...urlParams
        },
        extraHeaders: {
          'Accept': 'multipart/form-data'
        }
      })
    }),

    fakeConversationMessage: builder.mutation({
      query: ({ orgId, data, urlParams }) => ({
        url: `organizations/${orgId}/livechat-messages`,
        method: 'POST',
        API_URL: API_LIVECHAT_URL,
        body: data,
        params: {
          ...urlParams
        },
        extraHeaders: {
          'Accept': 'application/json;charset=utf-8',
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJoYmYtYm90IiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Mbt7o74R5LxJ26wYZgPrupVUTg69Wc3V6D2GteZ1Glk'
        },
        withAuth: false
      })
    }),

    fakeLivechatRequest: builder.mutation({
      query: ({ data, urlParams }) => ({
        url: 'livechat-requests',
        method: 'POST',
        body: data,
        API_URL: API_LIVECHAT_URL,
        extraHeaders: {
          'Accept': 'application/json;charset=utf-8',
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJoYmYtYm90IiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Mbt7o74R5LxJ26wYZgPrupVUTg69Wc3V6D2GteZ1Glk'
        },
        withAuth: false
      })
    }),

    exportConversationTranscriptCsv: builder.query({
      query: ({ orgId, urlParams }) => ({
        url: `organizations/${orgId}/livechat-messages`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        responseType: 'blob',
        includeResponseHeaders: true,
        extraHeaders: {
          'Accept': 'text/csv'
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_TRANSCRIPT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_TRANSCRIPT.SUCCESS
        }
      }),
      transformResponse: async (response) => {
        return await handleBlobTypes(response);
      }
    }),

    exportConversationTranscriptPdf: builder.query({
      query: ({ orgId, urlParams }) => ({
        url: `organizations/${orgId}/livechat-messages`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        responseType: 'blob',
        includeResponseHeaders: true,
        extraHeaders: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/pdf'
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_TRANSCRIPT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_TRANSCRIPT.SUCCESS
        }
      }),
      transformResponse: async (response) => {
        return await handleBlobTypes(response, true);
      }
    })
  })
});

export const {
  useFetchRequestsQuery,
  useLazyFetchChatSessionQuery,
  useFetchConversationTagsQuery,
  useUpdateConversationTagsMutation,
  useUpdateOrganizationTagsMutation,
  useFetchOrganizationTagsQuery,
  useFetchConversationMessagesQuery,
  useFetchActiveConversationQuery,
  useFetchConversationsQuery,
  useUploadAttachmentsMutation,
  useFakeConversationMessageMutation,
  useFakeLivechatRequestMutation,
  useLazyExportConversationTranscriptPdfQuery,
  useLazyExportConversationTranscriptCsvQuery
} = livechatSlice;
