import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './storyMessages';

export const storySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchStoryVariables: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/story/variables`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.VARIABLES
        }
      })
    }),
    providesTags: (result, error, args) => {
      return [
        { type: API_TAGS.STORY, id: 'Variables' }
      ];
    }
  })
});

export const {
  useFetchStoryVariablesQuery
} = storySlice;
