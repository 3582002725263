import { message } from 'antd';
import { API_TAGS } from '../../constants/api';
import i18n from '../../i18n';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './monitoringMessages';

export const monitoringSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchMonitors: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/monitors`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_MONITORS.ERROR
        }
      }),
      transformErrorResponse: (error) => {
        if (error.error.status === 429) {
          const waitTime = error.error.metadata['retry-after'];
          if (waitTime) {
            message.error(i18n.t(`Too many requests. Please retry in ${waitTime} seconds.`));
          } else {
            message.error(i18n.t('Too many requests. Please wait a few seconds and try again.'));
          }
        }
        return error;
      },
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [
            { type: API_TAGS.MONITORING, id: 'LIST' }
          ];
        }
        return [
          { type: API_TAGS.MONITORING, id: 'LIST' }
        ];
      }
    }),

    deleteBotMonitoring: builder.mutation({
      query: ({ orgId, tenantId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/monitor`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_MONITORING.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_MONITORING.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.MONITORING, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    createBotMonitoring: builder.mutation({
      query: ({ orgId, tenantId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/monitor`,
        method: 'POST',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_MONITORING.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_MONITORING.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.MONITORING, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    })
  })
});

export const {
  useFetchMonitorsQuery,
  useFetchBotMonitoringQuery,
  useDeleteBotMonitoringMutation,
  useCreateBotMonitoringMutation
} = monitoringSlice;
