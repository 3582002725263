import { apiSlice } from '../apiSlice';
import { MESSAGES } from './chatSessionsMessages';
import { API_TAGS } from '../../constants/api';
import handleFileDownload from '../../utils/misc/handleFileDownload';

export const chatSessionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchChatSessions: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_CHAT_SESSIONS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.CHAT_SESSIONS, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ id }) => ({ type: API_TAGS.CHAT_SESSIONS, id })),
          { type: API_TAGS.CHAT_SESSIONS, id: 'LIST' }
        ];
      }
    }),

    fetchChatSessionInfo: builder.query({
      query: ({ orgId, tenantId, chatSessionId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/${chatSessionId}`,
        method: 'GET',
        params: {
          ...urlParams,
          includeMessages: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_CHAT_SESSION_INFO.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.CHAT_SESSIONS, id: args.chatSessionId }];
      }
    }),

    fetchContactChatSessions: builder.query({
      query: ({ orgId, tenantId, handle, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/subscribers/handle/${handle}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_CHAT_SESSIONS.ERROR
        }
      })
    }),

    updateChatSession: builder.mutation({
      query: ({ orgId, tenantId, chatSessionId, data, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/${chatSessionId}`,
        method: 'PATCH',
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_CHAT_SESSION.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_CHAT_SESSION.SUCCESS
        }
      }),
      transformErrorResponse: (response) => false,
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.CHAT_SESSIONS, id: args.chatSessionId }];
      }
    }),

    downloadChatSessions: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/export`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DOWNLOAD_CHAT_SESSIONS.ERROR
        },
        responseType: 'blob',
        includeResponseHeaders: true
      }),
      transformResponse: (response, meta, arg) => {
        let file = response;
        const fileName = file?.headers['content-disposition']?.split(new RegExp(['"'], 'g'))?.[1] ?? `chat_sessions.${arg?.urlParams?.fileType}`;
        handleFileDownload(file?.responseContents, fileName);
        return {};
      }
    }),

    downloadChatSession: builder.query({
      query: ({ orgId, tenantId, chatSessionId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chat-sessions/export/${chatSessionId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DOWNLOAD_CHAT_SESSION.ERROR
        },
        responseType: 'blob',
        includeResponseHeaders: true
      }),
      transformResponse: (response, meta, arg) => {
        let file = response;
        const fileName = file?.headers['content-disposition']?.split(new RegExp(['"'], 'g'))?.[1] ?? `${arg.chatSessionId}.${arg.urlParams.fileType}`;
        handleFileDownload(file?.responseContents, fileName);
        return {};
      }
    })
  })
});

export const {
  useFetchChatSessionsQuery,
  useFetchContactChatSessionsQuery,
  useLazyFetchChatSessionsQuery,
  useFetchChatSessionInfoQuery,
  useLazyFetchChatSessionInfoQuery,
  useLazyDownloadChatSessionsQuery,
  useLazyDownloadChatSessionQuery,
  useUpdateChatSessionMutation
} = chatSessionsSlice;
