import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './tagsMessages';

export const tagsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchTags: build.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tags`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_TAGS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.TAGS, id: 'LIST' }];
      }
    }),

    createTag: build.mutation({
      query: ({ orgId, data }) => ({
        url: `organizations/${orgId}/tags`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_TAG.ERROR
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        return [{ type: API_TAGS.TAGS, id: 'LIST' }];
      }
    })
  })
});

export const { useFetchTagsQuery, useCreateTagMutation } = tagsSlice;
