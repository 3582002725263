import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_USER_GROUPS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the user groups.'),
      403: i18n.t('You are not allowed to fetch the user groups.'),
      404: i18n.t('The user groups you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the user groups.'),
      default: i18n.t('Αn error occurred fetching the user groups.')
    }
  },
  CREATE_USER_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to create the user group.'),
      403: i18n.t('You are not allowed to create the user group.'),
      404: i18n.t('The user group you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the user group.'),
      default: i18n.t('Αn error occurred creating the user group.')
    },
    SUCCESS: {
      200: i18n.t('User group created successfully!')
    }
  },
  UPDATE_USER_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to update the user group.'),
      403: i18n.t('You are not allowed to update the user group.'),
      404: i18n.t('The user group you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the user group.'),
      default: i18n.t('Αn error occurred updating the user group.')
    },
    SUCCESS: {
      200: i18n.t('User group updated successfully!')
    }
  },
  DELETE_USER_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the user group.'),
      403: i18n.t('You are not allowed to delete the user group.'),
      404: i18n.t('The user group you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the user group.'),
      default: i18n.t('Αn error occurred deleting the user group.')
    },
    SUCCESS: {
      200: i18n.t('User group deleted successfully!')
    }
  },
  FETCH_USER_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the user group.'),
      403: i18n.t('You are not allowed to fetch the user group.'),
      404: i18n.t('The user group you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the user group.'),
      default: i18n.t('Αn error occurred fetching the user group.')
    }
  }
};
