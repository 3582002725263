import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './botsMessages';

export const botsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchBots: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants`,
        method: 'GET',
        params: {
          ...urlParams,
          includeSettings: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOTS.ERROR,
          errorCodesToIgnore: [401, 403]
        }
      }),
      transformResponse: (response) => {
        const { items } = response;
        const sortedBots = items?.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
        return {
          items: sortedBots,
          total: response.total
        };
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.BOTS, id: 'LIST' }
        ];
      }
    }),

    fetchBot: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}`,
        method: 'GET',
        params: {
          ...urlParams,
          includeSettings: true,
          includeIntegrations: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    fetchBotPublic: builder.query({
      query: ({ tenantId, urlParams = {} }) => ({
        url: `public/tenants/${tenantId}`,
        method: 'GET',
        params: {
          ...urlParams,
          includeSettings: true
        },
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_PUBLIC.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    createBot: builder.mutation({
      query: ({ orgId, data, urlParams, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants`,
        method: 'POST',
        body: data,
        params: {
          ...urlParams
        },
        includeResponseHeaders: true,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_BOT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_BOT.SUCCESS
        },
        responseType: 'text'
      }),
      transformResponse: (response) => {
        return response.headers['hbf-created-resource-id'];
      },
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    updateBot: builder.mutation({
      query: ({ orgId, tenantId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_BOT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_BOT.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.BOTS, id: 'LIST' }
        ];
      }
    }),

    deleteBot: builder.mutation({
      query: ({ orgId, tenantId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_BOT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_BOT.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: 'LIST' }];
      }
    }),

    cloneBot: builder.mutation({
      query: ({ orgId, tenantId, targetOrgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/clone`,
        method: 'POST',
        params: {
          ...urlParams,
          sourceTenantId: tenantId,
          targetOrganizationId: targetOrgId
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CLONE_BOT.ERROR
        },
        includeResponseHeaders: true,
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CLONE_BOT.SUCCESS
        },
        responseType: 'text'
      }),
      transformResponse: (response) => {
        return response.responseContents;
      },
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: 'LIST' }];
      }
    }),

    trainBot: builder.mutation({
      query: ({ orgId, tenantId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/train`,
        method: 'POST',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.TRAIN_BOT.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.TRAIN_BOT.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (response) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    updateLivechatSettings: builder.mutation({
      query: ({ orgId, tenantId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/settings/live-chat`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_LIVECHAT_SETTINGS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_LIVECHAT_SETTINGS.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    clearCache: builder.mutation({
      query: ({ orgId, tenantId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/cache`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CLEAR_CACHE.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CLEAR_CACHE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.BOTS, id: args.tenantId }];
      }
    }),

    processMessage: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/process`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.PROCESS_MESSAGE.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.BOTS, id: 'PROCESS' }
        ];
      }
    })
  })
});

export const {
  useFetchBotsQuery,
  useLazyFetchBotsQuery,
  useFetchBotQuery,
  useLazyFetchBotQuery,
  useFetchBotPublicQuery,
  useLazyFetchBotPublicQuery,
  useCreateBotMutation,
  useUpdateBotMutation,
  useDeleteBotMutation,
  useCloneBotMutation,
  useTrainBotMutation,
  useUpdateLivechatSettingsMutation,
  useClearCacheMutation,
  useLazyProcessMessageQuery
} = botsSlice;
