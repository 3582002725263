import { Fragment } from 'react';
import DocumentTitle from '../components/DocumentTitle';
import { KnowledgeBasesIcon } from '../components/Icons/KnowledgeBasesIcon';
import { t } from 'i18next';
import Permissioned from '../components/Permissioned';
import { PERMISSIONS_GROUP_VIEWER } from '../constants/main';
import { useParams } from 'react-router';
import useUserPermissions from '../utils/hooks/useUserPermissions';
import { Col, PageHeader, Row } from 'antd';
import KnowledgeBasesTable from '../components/Tables/KnowledgeBasesTable';

const OrganizationKnowledgeBasesScreen = () => {
  const { orgId } = useParams();
  const userPermissions = useUserPermissions(orgId, null);
  return (
    <Fragment>
      <DocumentTitle title={t('Knowledge Bases')} />

      <Permissioned
        allowedPermissions={PERMISSIONS_GROUP_VIEWER}
        currentPermissions={userPermissions}
      >
        <PageHeader
          title={t('Knowledge Bases')}
          avatar={{ icon: <KnowledgeBasesIcon /> }}
        />

        <Row gutter={24}>
          <Col span={24}>
            <KnowledgeBasesTable />
          </Col>
        </Row>
      </Permissioned>
    </Fragment>
  );
};

export default OrganizationKnowledgeBasesScreen;
