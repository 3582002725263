import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_ANALYTICS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch analytics.'),
      403: i18n.t('You are not allowed to fetch analytics.'),
      404: i18n.t('The analytics you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the analytics.'),
      default: i18n.t('An error occurred fetching the analytics.')
    }
  },
  EXPORT_ANALYTICS_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to export the analytics report.'),
      403: i18n.t('You are not allowed to export the analytics report.'),
      404: i18n.t('The analytics report you are trying to export does not exist.'),
      500: i18n.t('An error occurred exporting the analytics report.'),
      default: i18n.t('An error occurred exporting the analytics report.')
    },
    SUCCESS: {
      200: i18n.t('Analytics report exported successfully.')
    }
  }
};
