import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_TAGS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the tags.'),
      403: i18n.t('You are not allowed to fetch the tags.'),
      404: i18n.t('The tags you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the tags.'),
      default: i18n.t('Αn error occurred fetching the tags.')
    }
  },

  CREATE_TAG: {
    ERROR: {
      401: i18n.t('You are not authorized to create tag.'),
      403: i18n.t('You are not allowed to create tag.'),
      500: i18n.t('Αn error occurred creating the tag.'),
      default: i18n.t('Αn error occurred creating the tag.')
    }
  }
};
