
// Core imports
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Component imports
import { Button, Table, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import AnalyticsFunnelingGraphModal from '../Modals/AnalyticsFunnelingGraphModal';

const ActivityFunnelingTable = ({
  activityFunnelingData = [],
  isLoading = true
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [graphData, setGraphData] = useState({});
  const { t } = useTranslation();

  const handleModalCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const isModalOpen = useMemo(() => {
    return isModalVisible;
  }, [isModalVisible]);

  const modalGraphData = useMemo(() => {
    let questionIdsArray = [];
    let questionNodeDataContainer = {};

    graphData?.nodeUsage?.forEach((node) => {
      questionIdsArray.push(node.nodeId);
      questionNodeDataContainer[node.nodeId] = {
        ...node
      };
      graphData.injectedNodeData = {
        nodesIds: questionIdsArray,
        nodesData: questionNodeDataContainer
      };
    });
    return graphData;
  }, [graphData?.uniqueKey]);

  let columns = [
    {
      title: t('Bot'),
      dataIndex: 'tenantDisplayName',
      key: 'uniqueKey'
    },
    {
      title: t('Activity'),
      dataIndex: 'activityDisplayName',
      key: 'activityId'
    },
    {
      title: t('Actions'),
      key: 'actions',
      align: 'center',
      render: (_text, record) => {
        return (
          <Button
            type="text"
            onClick = {() => {
              setGraphData(record);
              setIsModalVisible(true);
            }}
          >
            <EyeOutlined />
          </Button>
        );
      }
    }
  ];

  return (
    <Fragment>
      <div className="mb-6 position-relative">
        <Typography.Title level={4} className="mb-6">
          <Typography.Text className="mr-2">{t('Activities')}</Typography.Text>
        </Typography.Title>

        <Table
          key={'activityFunnelingTable'}
          className={`mt-13 ${activityFunnelingData.length > 8 ? 'scroll-table' : ''}`}
          dataSource={activityFunnelingData}
          loading={isLoading}
          columns={columns}
          pagination={false}
          scroll={activityFunnelingData.length > 8 ? { y: 518 } : false}
        />

        <AnalyticsFunnelingGraphModal
          isModalVisible={isModalOpen}
          handleModalCancel={handleModalCancel}
          graphData={modalGraphData}
        />
      </div>
    </Fragment>
  );
};

ActivityFunnelingTable.propTypes = {
  activityFunnelingData: PropTypes.array,
  isLoading: PropTypes.bool
};

export default ActivityFunnelingTable;
