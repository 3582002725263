import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './backupsMessages';

export const backupsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchBackups: build.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/backups`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BACKUPS.ERROR
        }
      }),
      providesTags: () => {
        return [{ type: API_TAGS.BACKUPS, id: 'LIST' }];
      }
    }),

    createBackup: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/backups`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_BACKUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_BACKUP.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BACKUPS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteBackup: build.mutation({
      query: ({ orgId, tenantId, backupId, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/backups/${backupId}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_BACKUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_BACKUP.SUCCESS
        }
      }),
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BACKUPS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    triggerBackupRestore: build.mutation({
      query: ({ orgId, tenantId, backupDate, backupId, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/backups/${backupId}`,
        method: 'PUT',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.TRIGGER_BACKUP_RESTORE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: {
            200: `Chatbot restored to backup version of ${backupDate}`
          }
        }
      }),
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BACKUPS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    })
  })
});

export const {
  useFetchBackupsQuery,
  useCreateBackupMutation,
  useTriggerBackupRestoreMutation,
  useDeleteBackupMutation
} = backupsSlice;
