import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_KNOWLEDGE_BASES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the knowledge bases.'),
      403: i18n.t('You are not allowed to fetch the knowledge bases.'),
      404: i18n.t('The knowledge bases you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the knowledge bases.'),
      default: i18n.t('Αn error occurred fetching the knowledge bases.')
    }
  },

  CREATE_KNOWLEDGE_BASE: {
    ERROR: {
      401: i18n.t('You are not authorized to create a knowledge base.'),
      403: i18n.t('You are not allowed to create a knowledge base.'),
      500: i18n.t('Αn error occurred creating the knowledge base.'),
      default: i18n.t('Αn error occurred creating the knowledge base.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base created successfully!')
    }
  },

  UPDATE_KNOWLEDGE_BASE: {
    ERROR: {
      401: i18n.t('You are not authorized to update the knowledge base.'),
      403: i18n.t('You are not allowed to update the knowledge base.'),
      404: i18n.t('The knowledge base you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the knowledge base.'),
      default: i18n.t('An error occurred updating the knowledge base.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base updated successfully!')
    }
  },

  UPDATE_KNOWLEDGE_BASES: {
    ERROR: {
      401: i18n.t('You are not authorized to update the knowledge bases.'),
      403: i18n.t('You are not allowed to update the knowledge bases.'),
      404: i18n.t('The knowledge bases you are trying to update do not exist.'),
      500: i18n.t('There was an error updating the knowledge bases.'),
      default: i18n.t('An error occurred updating the knowledge bases.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge bases updated successfully!')
    }
  },

  SYNC_KNOWLEDGE_BASE: {
    ERROR: {
      401: i18n.t('You are not authorized to synchronize a knowledge base.'),
      403: i18n.t('You are not allowed to synchronize a knowledge base.'),
      500: i18n.t('Αn error occurred synchronizing the knowledge base.'),
      default: i18n.t('Αn error occurred synchronizing the knowledge base.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base synced successfully!')
    }
  },

  SYNC_KNOWLEDGE_BASES: {
    ERROR: {
      401: i18n.t('You are not authorized to synchronize the knowledge bases.'),
      403: i18n.t('You are not allowed to synchronize the knowledge bases.'),
      500: i18n.t('Αn error occurred synchronizing the knowledge bases.'),
      default: i18n.t('Αn error occurred synchronizing the knowledge bases.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge bases synced successfully!')
    }
  },

  DELETE_KNOWLEDGE_BASE: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the knowledge base.'),
      403: i18n.t('You are not allowed to delete the knowledge base.'),
      404: i18n.t('The knowledge base you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the knowledge base.'),
      default: i18n.t('An error occurred deleting the knowledge base.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base deleted successfully!')
    }
  },

  DELETE_KNOWLEDGE_BASES: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the knowledge bases.'),
      403: i18n.t('You are not allowed to delete the knowledge bases.'),
      404: i18n.t('The knowledge bases you are trying to delete do not exist.'),
      500: i18n.t('There was an error deleting the knowledge bases.'),
      default: i18n.t('An error occurred deleting the knowledge bases.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge bases deleted successfully!')
    }
  },

  FETCH_KNOWLEDGE_BASE_ARTICLES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the articles.'),
      403: i18n.t('You are not allowed to fetch the articles.'),
      404: i18n.t('The articles you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the articles.'),
      default: i18n.t('Αn error occurred fetching the articles.')
    }
  },

  FETCH_KNOWLEDGE_BASE_ARTICLE: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the article.'),
      403: i18n.t('You are not allowed to fetch the article.'),
      404: i18n.t('The article you are trying to fetch does not exist.'),
      500: i18n.t('Αn error occurred fetching the article.'),
      default: i18n.t('Αn error occurred fetching the article.')
    }
  },

  CREATE_KNOWLEDGE_BASE_ARTICLE: {
    ERROR: {
      401: i18n.t('You are not authorized to create a knowledge base article.'),
      403: i18n.t('You are not allowed to create a knowledge base article.'),
      500: i18n.t('Αn error occurred creating the knowledge base article.'),
      default: i18n.t('Αn error occurred creating the knowledge base article.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base article created successfully!')
    }
  },

  CREATE_KNOWLEDGE_BASE_ARTICLES_FROM_PDF: {
    ERROR: {
      401: i18n.t('You are not authorized to upload a PDF file with knowledge base articles or another PDF is already being processed.'),
      403: i18n.t('You are not allowed to upload a PDF file with knowledge base articles.'),
      500: i18n.t('Αn error occurred while uploading the PDF file with knowledge base articles.'),
      default: i18n.t('Αn error occurred while uploading the PDF file with knowledge base articles.')
    },
    SUCCESS: {
      200: i18n.t('PDF file is successfully uploaded!')
    }
  },

  UPDATE_KNOWLEDGE_BASE_ARTICLE: {
    ERROR: {
      401: i18n.t('You are not authorized to update the knowledge base article.'),
      403: i18n.t('You are not allowed to update the knowledge base article.'),
      404: i18n.t('The knowledge base article you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the knowledge base article.'),
      default: i18n.t('An error occurred updating the knowledge base article.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base article updated successfully!')
    }
  },

  DELETE_KNOWLEDGE_BASE_ARTICLE: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the knowledge base article.'),
      403: i18n.t('You are not allowed to delete the knowledge base article.'),
      404: i18n.t('The knowledge base article you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the knowledge base article.'),
      default: i18n.t('An error occurred deleting the knowledge base article.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base article deleted successfully!')
    }
  },

  FETCH_KNOWLEDGE_BASE_GROUPS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the knowledge base groups.'),
      403: i18n.t('You are not allowed to fetch the knowledge base groups.'),
      404: i18n.t('The knowledge base groups you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the knowledge base groups.'),
      default: i18n.t('Αn error occurred fetching the knowledge base groups.')
    }
  },

  FETCH_KNOWLEDGE_BASE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the knowledge base group.'),
      403: i18n.t('You are not allowed to fetch the knowledge base group.'),
      404: i18n.t('The knowledge base group you are trying to fetch does not exist.'),
      500: i18n.t('Αn error occurred fetching the knowledge base group.'),
      default: i18n.t('Αn error occurred fetching the knowledge base group.')
    }
  },

  CREATE_KNOWLEDGE_BASE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to create a knowledge base group.'),
      403: i18n.t('You are not allowed to create a knowledge base group.'),
      500: i18n.t('Αn error occurred creating the knowledge base group.'),
      default: i18n.t('Αn error occurred creating the knowledge base group.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base group created successfully!')
    }
  },

  UPDATE_KNOWLEDGE_BASE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to update the knowledge base group.'),
      403: i18n.t('You are not allowed to update the knowledge base group.'),
      404: i18n.t('The knowledge base group you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the knowledge base group.'),
      default: i18n.t('An error occurred updating the knowledge base group.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base group updated successfully!')
    }
  },

  DELETE_KNOWLEDGE_BASE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the knowledge base group.'),
      403: i18n.t('You are not allowed to delete the knowledge base group.'),
      404: i18n.t('The knowledge base group you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the knowledge base group.'),
      default: i18n.t('An error occurred deleting the knowledge base group.')
    },
    SUCCESS: {
      200: i18n.t('Knowledge base group deleted successfully!')
    }
  }
};
