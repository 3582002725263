import { createApi } from '@reduxjs/toolkit/query/react';
import { API_TAGS } from '../constants/api';
import { API_CORE_URL } from '../constants/env';
import _fetch from './utils/apiUtils/_fetch';
import makeUrl from './utils/makeUrl';
import { message } from 'antd';
import { isTokenExpired } from '../utils/auth/isTokenExpired';

/**
 * Create requests to fetch or send resources to the server using the `Fetch` API.
 * @param {String} url Endpoint url to be appended to API_URL
 * @param {String} API_URL API URL, defaults to API_CORE_URL, which is set in env.js, but can be overriden by passing a different value to this param
 * @param {String} method HTTP method to be used
 * @param {Object} params Query params to be added to request from specific endpoints (eg: { page: 1, limit: 10 })
 * @param {Object} body Request body to be added to request from specific endpoints
 * @param {Object} stringifyBody Wether to json stringify the body of the request or not, defaults to true
 * @param {Object} errorMessage Contains boolean to show error message and messages object (eg: { showMessage: true, messages: { 404: 'Not found', 500: 'Server error' } })
 * @param {Object} successMessage Contains boolean to show success message and messages object (eg: { showMessage: true, messages: { 200: 'Success', 201: 'Created' } })
 * @param {Object} extraHeaders Extra headers to be added to request from specific endpoints (eg: { 'Content-Type': 'application/json' })
 * @param {String} extraToken Extra token to replace the auth one for public usage of specific resources
 * @param {String} responseType Response type; tha way to handle the response. Available values: `arrayBuffer`, `blob`, `json`, `text`, `formData`.
 * @param {Boolean} withAuth Denotes if should add auth header or not. Defaults to true.
 * @returns {Object} Response object in the form of { data : response } or { error : error } depending on the response status
*/
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (fetchArg, { getState }) => {
    const {
      url,
      API_URL = API_CORE_URL,
      method,
      params = {},
      body = null,
      stringifyBody = true,
      errorMessage = {
        showMessage: false,
        messages: {},
        errorCodesToIgnore: []
      },
      successMessage = {
        showMessage: false,
        messages: {}
      },
      extraHeaders = {
        'Accept': 'application/json;charset=utf-8',
        'Content-Type': 'application/json;charset=utf-8'
      },
      extraToken = '',
      responseType = 'json',
      withAuth = true,
      includeResponseHeaders = false
    } = fetchArg;

    // Getting token from state
    const { auth } = getState();
    const { token, refreshToken } = auth;

    // Creating url params to create url
    let urlParams = [];
    Object.entries(params).forEach(([key, value]) => {
      urlParams.push(`${key}=${value}`);
    });

    // Creating user options to pass to _fetch
    let userOptions = {
      withAuth: withAuth,
      responseType: responseType,
      includeResponseHeaders: includeResponseHeaders
    };

    // Creating url combining API_URL, endpoint url and urlParams
    const URL = makeUrl(API_URL, url, { urlSearchParams: urlParams });

    // Creating config object to pass to _fetch
    const config = {
      method,
      body: body ? (stringifyBody ? JSON.stringify(body) : body) : null,
      headers: {
        ...extraHeaders
      },
      ...params.reqConfig
    };

    // Creating context object to pass to _fetch
    const context = { _token: extraToken ? extraToken : token, _refreshToken: refreshToken };

    // Calling _fetch and returning response
    const response = await _fetch(URL, userOptions, config)(context).then(response => {
      if (successMessage.showMessage) {
        message.success(successMessage.messages[200]);
      }
      return response;
    }).catch(error => {
      const hasTokenExpired = isTokenExpired(token) && error.status === 401;
      if (errorMessage.showMessage && !errorMessage?.errorCodesToIgnore?.includes(error?.status) && !hasTokenExpired) {
        if (error?.metadata?.graphErrorCode && errorMessage.messages[error.metadata.graphErrorCode]) {
          message.error(errorMessage.messages[error.metadata.graphErrorCode]);
        } else if (error?.code && errorMessage.messages[error.code]) {
          message.error(errorMessage.messages[error.code]);
        } else if (error?.status && errorMessage.messages[error?.status]) {
          message.error(errorMessage.messages[error.status]);
        } else {
          message.error(errorMessage.messages['default']);
        }
      }
      return {
        error: error
      };
    });

    // Returning response formatted according to RTK Query
    if (response?.error) {
      return {
        error: response
      };
    } else {
      return {
        data: response
      };
    }
  },
  endpoints: (_builder) => ({}),
  tagTypes: [...Object.values(API_TAGS)],
  refetchOnReconnect: false,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0
});
