import { API_LIVECHAT_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import _fetch from '../utils/apiUtils/_fetch';
import { sendConversationMessage } from '../liveChat/liveChatThunks';
import { LIVECHAT_MESSAGE_TYPES } from '../../constants/liveChat';
import i18n from '../../i18n';
import { MESSAGES } from './predefinedEntitiesMessages';
import { API_TAGS } from '../../constants/api';

export const predefinedEntitiesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPredefinedResponses: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/predefined-responses`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_PREDEFINED_RESPONSES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.PREDEFINED_ENTITIES, id: 'PredefinedResponses' }];
        }
        return [
          ...result.map(({ id }) => ({ type: API_TAGS.PREDEFINED_ENTITIES, id })),
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'PredefinedResponses' }
        ];
      }
    }),

    fetchAutomations: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/predefined-actions`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_AUTOMATIONS.ERROR
        }
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}${queryArgs.orgId}`;
      },
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.PREDEFINED_ENTITIES, id: 'Automations' }];
        }
        return [
          ...result.map(({ id }) => ({ type: API_TAGS.PREDEFINED_ENTITIES, id })),
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'Automations' }
        ];
      }
    }),

    updatePredefinedResponse: builder.mutation({
      query: ({ orgId, predefinedResponseId, data }) => ({
        url: `organizations/${orgId}/predefined-responses/${predefinedResponseId}`,
        method: 'PATCH',
        API_URL: API_LIVECHAT_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_PREDEFINED_RESPONSES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_PREDEFINED_RESPONSES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'PredefinedResponses' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'PredefinedResponses' }
        ];
      }
    }),

    updateAutomation: builder.mutation({
      query: ({ orgId, automationId, data }) => ({
        url: `organizations/${orgId}/predefined-actions/${automationId}`,
        method: 'PATCH',
        API_URL: API_LIVECHAT_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_AUTOMATIONS.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_AUTOMATIONS.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'Automations' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'Automations' }
        ];
      }
    }),

    createPredefinedResponse: builder.mutation({
      query: ({ orgId, data }) => ({
        url: `organizations/${orgId}/predefined-responses`,
        method: 'POST',
        API_URL: API_LIVECHAT_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_PREDEFINED_RESPONSES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_PREDEFINED_RESPONSES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'PredefinedResponses' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'PredefinedResponses' }
        ];
      }
    }),

    createAutomation: builder.mutation({
      query: ({ orgId, data }) => ({
        url: `organizations/${orgId}/predefined-actions`,
        method: 'POST',
        API_URL: API_LIVECHAT_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_AUTOMATIONS.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_AUTOMATIONS.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'Automations' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'Automations' }
        ];
      }
    }),

    deletePredefinedResponse: builder.mutation({
      query: ({ orgId, predefinedResponseId }) => ({
        url: `organizations/${orgId}/predefined-responses/${predefinedResponseId}`,
        method: 'DELETE',
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_PREDEFINED_RESPONSES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_PREDEFINED_RESPONSES.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'PredefinedResponses' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'PredefinedResponses' }
        ];
      }
    }),

    deleteAutomation: builder.mutation({
      query: ({ orgId, automationId }) => ({
        url: `organizations/${orgId}/predefined-actions/${automationId}`,
        method: 'DELETE',
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_AUTOMATIONS.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_AUTOMATIONS.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error?.error) {
          return;
        }
        return [
          { type: API_TAGS.PREDEFINED_ENTITIES, id: 'Automations' },
          { type: API_TAGS.LIVE_CHAT_SETTINGS, id: 'Automations' }
        ];
      }
    }),

    triggerAutomation: builder.mutation({
      queryFn: async (arg) => {
        // eslint-disable-next-line no-unused-vars
        const { callbackUrl, callbackHeaders, data, conversationId, automation, urlParams={} } = arg;

        const url = callbackUrl;
        const headers = new Headers();

        for (const header of callbackHeaders) {
          headers.append(header.key, header.value);
        }

        headers.set('Content-Type', 'application/json;charset=utf-8');

        const config = {
          method: 'POST',
          body: JSON.stringify(data),
          headers,
          ...urlParams?.reqConfig
        };

        const response = await _fetch(url, {
          withAuth: false,
          ...urlParams
        }, config)().then(response => {
          return {
            data: response
          };
        }).catch(error => {
          if (error?.code) {
            return {
              error: error
            };
          }else {
            return {
              error: {
                message: 'Failed to fetch'
              }
            };
          }

        });

        return response;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        let result;

        const formatSystemMessage = (item, result) => {
          return `${i18n.t('The automation')} "${item.name}" ${!('error' in result)
            ? `${i18n.t('was triggered successfully')}${result.msgToAgent ? ': ' + result.msgToAgent : '.'}`
            : `${i18n.t('failed. The automation returned an error')}: ${result.error}.`}`;
        };

        try {
          const { data } = await queryFulfilled;
          result = data;
          if (result?.msgToUser) {
            dispatch(sendConversationMessage({
              text: result.msgToUser,
              livechatConversation: {
                id: arg.conversationId
              },
              type: LIVECHAT_MESSAGE_TYPES.TEXT
            }));
          }
          dispatch(sendConversationMessage({
            text: formatSystemMessage(arg.automation, result),
            livechatConversation: {
              id: arg.conversationId
            },
            type: LIVECHAT_MESSAGE_TYPES.SYSTEM_MESSAGE
          }));
        } catch(error) {
          result = {
            error: error?.error?.code ? `[${error?.error?.message || error?.error?.description}](##${error?.error?.code})` : error?.error?.message
          };

          dispatch(sendConversationMessage({
            text: formatSystemMessage(arg.automation, result),
            livechatConversation: {
              id: arg.conversationId
            },
            type: LIVECHAT_MESSAGE_TYPES.SYSTEM_MESSAGE
          }));
        }

      }
    })
  })
});

export const {
  useFetchPredefinedResponsesQuery,
  useFetchAutomationsQuery,
  useUpdatePredefinedResponseMutation,
  useUpdateAutomationMutation,
  useCreatePredefinedResponseMutation,
  useCreateAutomationMutation,
  useDeletePredefinedResponseMutation,
  useDeleteAutomationMutation,
  useTriggerAutomationMutation
} = predefinedEntitiesSlice;
