// Core imports
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';

// Component imports
import { Button, Collapse, Dropdown, Form, Input, Menu, Popconfirm, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { CustomerServiceFilled, DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { LiveChatSettingsIcon } from '../Icons/LiveChatSettingsIcon';
import { HrwizIcon } from '../Icons/HrwizIcon';
import BotAvatar from '../UI/BotAvatar';
import BulkActionsModal from '../Modals/BulkActionsModal';
import OrganizationUserForm from '../Forms/OrganizationUserForm';
import ResponsiveTagsList from '../UI/ResponsiveTagsList';

// Misc imports
import { useDeleteUserMutation, useDeleteUsersMutation, useFetchUsersQuery } from '../../store/users/usersApi';
import { useFetchBotsQuery } from '../../store/bots/botsApi';
import { ADDITIONAL_ROLES_ORG, PERMISSIONS_ORG } from '../../constants/main';
import useDebouncedCallback from '../../utils/hooks/useDebounce';

// Constant declarations
const PAGE_SIZE = 10;
const BULK_ACTIONS = {
  DELETE: 'delete'
};
const OrganizationUsersTable = () => {
  const { orgId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [bulkAction, setBulkAction] = useState();
  // isBasicFilteringReady returns true if we have the most important filters for initial query available
  // else it returns false and we skip the query to avoid errors and unnecessary requests
  const isBasicFilteringReady = useMemo(() => {
    let isReady = !!(searchParams.get('page') && searchParams.get('pageSize') && searchParams.get('sortBy') && searchParams.get('order'));
    return isReady;
  }, [location.search]);

  // currentFilters represent the filters available
  // it helps avoid race conditions and load the correct filters after page reload
  // If we navigate from different origin then defaults to initial values for url params
  const currentFilters = useMemo(() => {
    const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 0;
    const pageSize = PAGE_SIZE;
    const sortBy = searchParams.get('sortBy') ? searchParams.get('sortBy') : 'fullName';
    const order = searchParams.get('order') ? searchParams.get('order') : 'ASC';

    const filters = {
      page: currentPage,
      pageSize,
      sortBy,
      order,
      ...(searchParams.get('search') && { search: searchParams.get('search') }),
      ...(searchParams.get('tenantId') && { tenantIds: searchParams.get('tenantId') }),
      ...(searchParams.get('tenantIds') && { tenantIds: searchParams.get('tenantIds') }),
      ...(searchParams.get('isActive') && { isActive: searchParams.get('isActive') }),
      ...(searchParams.get('organizationRole') && { organizationRole: searchParams.get('organizationRole') }),
      ...(searchParams.get('userId') && { userId: searchParams.get('userId') })
    };
    return filters;
  }, [location.search]);

  // columnSortingInfo transforms the sortBy and order properties to the correct form to be used for table filtering
  // and it's persistance through reloading
  const columnSortingInfo = useMemo(() => {
    const columnKey = searchParams.get('sortBy');
    const order = searchParams.get('order') === 'DESC' ? 'descend' : 'ascend';

    return {
      columnKey,
      order
    };
  }, [searchParams.get('sortBy'), searchParams.get('order')]);

  // Skip the query if no orgId or basic filtering values are not ready yet
  const { data: users, isFetching: isFetchingUsers } = useFetchUsersQuery({ orgId, urlParams: currentFilters }, { skip: !orgId || !isBasicFilteringReady });
  const { data: bots, isFetching: isFetchingBots } = useFetchBotsQuery({ orgId }, { skip: !orgId });
  const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();
  const [deleteUsers, { isLoading: isDeletingUsers }] = useDeleteUsersMutation();

  // State
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersToShow, setUsersToShow] = useState(0);
  const [isBulkActionsUsersModalOpen, setIsBulkActionsUsersModalOpen] = useState(false);

  // Update the url params with the current filters on mount
  useEffect(() => {
    updateUrlSearchParams({ initialFilters: currentFilters });
  }, []);

  // Returns the highest permission available to the user from effective and organizationRoles
  const userHighestPermission = (user, orgId) => {
    const effectiveRoles = user?.effectiveRoles?.organizationRoles[orgId];
    const organizationRoles = user.organizationRoles[orgId];

    const combinedRoles = (effectiveRoles || []).concat(organizationRoles);

    if (combinedRoles.includes(PERMISSIONS_ORG.ADMIN)) {
      return PERMISSIONS_ORG.ADMIN;
    }

    if (combinedRoles.includes(PERMISSIONS_ORG.EDITOR)) {
      return PERMISSIONS_ORG.EDITOR;
    }

    if (combinedRoles.includes(PERMISSIONS_ORG.VIEWER)) {
      return PERMISSIONS_ORG.VIEWER;
    }

    return null;
  };

  const updateUrlSearchParams = ({ currentPage = 0, formFilters=null, sorting = { sortBy: 'fullName', order: 'ASC' }, initialFilters=null, tableFilters=null, userId=null }) => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (userId && !initialFilters) {
      urlSearchParams.set('userId', userId);
    } else {
      urlSearchParams.delete('userId');
    }

    // If we have initials filters then iterate through them and set them to url
    // Else set the incoming values
    if (initialFilters) {
      Object.entries(initialFilters).forEach(([key, value]) => {
        urlSearchParams.set(key, value);
      });
    } else {
      const { sortBy, order } = sorting;

      urlSearchParams.set('page', currentPage);
      urlSearchParams.set('pageSize', PAGE_SIZE);
      urlSearchParams.set('sortBy', sortBy);
      urlSearchParams.set('order', order);

      if (tableFilters) {
        Object.entries(tableFilters).forEach(([key, value]) => {
          if (key && value) {
            urlSearchParams.set(key, value);
          }

          if (key && !value) {
            urlSearchParams.delete(key);
          }

          return;
        });
      }

      if (formFilters) {
        Object.entries(formFilters).forEach(([key, value]) => {
          if (key && value) {
            urlSearchParams.set(key, value);
          }
          if (key && !value) {
            urlSearchParams.delete(key);
          }
          return;
        });
      }
    }

    navigate({
      pathname: location.pathname,
      search: urlSearchParams.toString()
    }, { replace: true });

    return;
  };

  const onFieldsChange = (changedFields, allFields) => {
    let formFilters = {};

    allFields?.forEach((field) => {
      formFilters = {
        ...formFilters,
        [field?.name[field?.name?.length - 1]]: field.value
      };
    });

    updateUrlSearchParams({
      currentPage: 0,
      sorting: {
        sortBy: currentFilters.sortBy,
        order: currentFilters.order
      },
      tableFilters: {
        isActive: currentFilters.isActive
      },
      formFilters
    });
  };

  const debouncedOnChange = useDebouncedCallback(onFieldsChange);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra?.action === 'paginate') {
      setSelectedUsers([]);
      updateUrlSearchParams({
        currentPage: pagination.current - 1,
        sorting: {
          sortBy: currentFilters.sortBy,
          order: currentFilters.order
        },
        tableFilters: filters
      });
    }

    if (extra?.action === 'sort') {
      if (sorter.order) {
        updateUrlSearchParams({
          currentPage: pagination.current - 1,
          sorting: {
            sortBy: sorter.columnKey,
            order: sorter.order === 'descend' ? 'DESC' : 'ASC'
          },
          tableFilters: filters
        });
      } else {
        updateUrlSearchParams({
          currentPage: pagination.current - 1,
          sorting: {
            sortBy: 'fullName',
            order: 'ASC'
          },
          tableFilters: filters
        });
      }
    }

    if (extra?.action === 'filter') {
      updateUrlSearchParams({
        currentPage: 0,
        sorting: {
          sortBy: currentFilters.sortBy,
          order: currentFilters.order
        },
        tableFilters: filters
      });
    }
  };

  const handleBulkActionsMenuClick = (e) => {
    setBulkAction(e.key);
    openBulkActionsUsersModal();
  };

  const onRowSelectChange = (record, selected, selectedRows) => {
    const userIds = selectedRows.map(user => user?.id);
    if (selected) {
      setSelectedUsers(userIds);
    } else {
      setSelectedUsers(prev => {
        return prev.filter(value => value !== record.id);
      });
    }
  };

  const onRowSelectAll = (selected, selectedRows, changeRows) => {
    const userIds = changeRows.map(user => user?.id);
    if (selected) {
      setSelectedUsers(userIds);
    } else {
      setSelectedUsers([]);
    }
  };

  const onDropdownSelect = (key) => {
    let newSelectedUsers = [];
    if (key === 'all') {
      newSelectedUsers = users?.items?.map((item) => item.id);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const rowSelection = {
    selectedRowKeys: selectedUsers,
    onSelect: onRowSelectChange,
    onSelectAll: onRowSelectAll,
    selections: [
      {
        key: 'all',
        text: t('All'),
        onSelect: () => onDropdownSelect('all')
      },
      {
        key: 'none',
        text: t('None'),
        onSelect: () => onDropdownSelect('none')
      }
    ]
  };

  const bulkActionsUsersModalData = () => {
    const usersToDelete = selectedUsers?.map((selectedUser) => {
      return users?.items?.find(item => item.id === selectedUser);
    });

    return usersToDelete;
  };

  const openBulkActionsUsersModal = () => {
    setUsersToShow(selectedUsers.length > 5 ? 5 : selectedUsers.length);
    setIsBulkActionsUsersModalOpen(true);
  };

  const closeBulkActionsUsersModal =(props) => {
    setIsBulkActionsUsersModalOpen(false);
  };

  const handleBulkActionsUsers = async () => {
    const isDeleteSuccessful = await deleteUsers({ orgId, data: selectedUsers }).unwrap();
    if (isDeleteSuccessful) {
      setSelectedUsers([]);
      setIsBulkActionsUsersModalOpen(false);
    }
  };

  const onBulkActionsUsersModalShowMore = () => {
    setUsersToShow(usersToShow + 5);
  };

  return (
    <Collapse
      ghost
      accordion
      activeKey={currentFilters?.userId ? 1 : 0}
      destroyInactivePanel
    >
      <Collapse.Panel
        key={0}
        showArrow={false}
        className="ant-collapse-p-0"
      >
        <Space
          size={16}
          align="center"
          className="justify-content-between mb-4 w-100"
          wrap
        >
          <Space wrap>
            <Form
              form={form}
              name="organizationUsersFilters"
              initialValues={{
                search: currentFilters.search ? currentFilters.search : ''
              }}
              onFieldsChange={debouncedOnChange}
            >
              <Form.Item
                name="search"
                className="mb-0"
              >
                <Input
                  size="large"
                  placeholder={t('Search in contents')}
                  allowClear
                  prefix={<SearchOutlined className="text-muted" />}
                />
              </Form.Item>
            </Form>
          </Space>

          <Space wrap>
            <Dropdown
              trigger="click"
              overlay={
                <Menu
                  onClick={handleBulkActionsMenuClick}
                  items={[
                    {
                      label: t('Delete Selected'),
                      key: BULK_ACTIONS.DELETE
                    }
                  ]}
                />
              }
              disabled={!selectedUsers.length > 0}
            >
              <Button
                block
                size="large"
              >
                {t('Bulk Actions')}
                <DownOutlined />
              </Button>
            </Dropdown>

            <Button
              size="large"
              type="primary"
              className="float-right"
              disabled={!bots}
              icon={<PlusOutlined />}
              onClick={() => {
                updateUrlSearchParams({ userId: 'invite-new-user' });
              }}
            >
              {t('Invite new user')}
            </Button>
          </Space>
        </Space>

        <Table
          rowKey="id"
          size="small"
          rowSelection={rowSelection}
          dataSource={users && users?.items}
          loading={isFetchingUsers || isFetchingBots}
          onChange={handleTableChange}
          pagination={{
            size: 'default',
            total: users?.total,
            defaultCurrent: 1,
            current: currentFilters.page + 1,
            showSizeChanger: false
          }}
          columns={[
            {
              key: 'fullName',
              dataIndex: 'fullName',
              title: t('User Name'),
              ellipsis: true,
              sorter: true,
              sortOrder: columnSortingInfo.columnKey === 'fullName' && columnSortingInfo.order,
              render: (_text, record) => (
                <Highlighter
                  highlightClassName="bg-highlight p-0"
                  className="strong"
                  searchWords={[currentFilters?.search]}
                  autoEscape
                  textToHighlight={record.fullName}
                />
              )
            },
            {
              key: 'email',
              dataIndex: 'email',
              title: t('Email'),
              ellipsis: true,
              sorter: true,
              sortOrder: columnSortingInfo.columnKey === 'email' && columnSortingInfo.order,
              render: (_text, record) => (
                <Highlighter
                  highlightClassName="bg-highlight p-0"
                  className="strong"
                  searchWords={[currentFilters?.search]}
                  autoEscape
                  textToHighlight={record.email}
                />
              )
            },
            {
              key: 'botAccess',
              dataIndex: ['effectiveRoles', 'tenantRoles'],
              title: t('Bots'),
              responsive: ['lg'],
              render: (_, record) => {
                const effectiveRolesBotIds = Object.keys(record.effectiveRoles.tenantRoles[orgId] ?? {});
                const organizationRolesBotIds = Object.keys(record.tenantRoles[orgId] ?? {});
                const botIds = [...new Set((effectiveRolesBotIds || []).concat(organizationRolesBotIds))];
                const permission = userHighestPermission(record, orgId);

                if (permission === PERMISSIONS_ORG.ADMIN) {
                  return 'All bots available';
                }

                if (!botIds.length) {
                  return '-';
                }

                const botsToShow = (bots?.items || []).filter(bot => botIds.includes(bot.id)) ?? [];

                return (
                  <ResponsiveTagsList
                    values={
                      botsToShow.map((bot) => ({
                        label: <BotAvatar bot={bot} className="mt-1" />,
                        value: bot.id
                      }))
                    }
                    maxTagCount={3}
                    tagRender={(tag) => {
                      const matchedTag = botsToShow.find(bot => bot.id === tag.value);
                      return (
                        <Tooltip title={matchedTag.displayName}>
                          <BotAvatar className="mr-2" bot={matchedTag} showBotName={false}/>
                        </Tooltip>
                      );
                    }}
                  />
                );
              }
            },
            {
              key: 'organizationRole',
              title: t('Organization Roles'),
              responsive: ['md'],
              render: (value, record) => {
                const permission = userHighestPermission(record, orgId);
                const access = t(permission, { context: 'user role' });
                if (access) {
                  return <Typography.Text className="fw-500 fs-sm">{access}</Typography.Text>;
                }
                return '-';
              }
            },
            {
              key: 'roles',
              title: t('Application Roles'),
              responsive: ['xl'],
              ellipsis: true,
              render: (value, record) => {
                const effectiveAppRoles = (record.effectiveRoles?.organizationRoles[orgId] || []).filter(role => Object.values(ADDITIONAL_ROLES_ORG).includes(role));
                const organizationAppRoles = (record.organizationRoles[orgId] || []).filter(role => Object.values(ADDITIONAL_ROLES_ORG).includes(role));

                const appRoles = [...new Set((effectiveAppRoles || []).concat(organizationAppRoles))];

                if (!appRoles.length) {
                  return '-';
                }

                const tags = [];
                for (const role of appRoles) {
                  switch (role) {
                    case ADDITIONAL_ROLES_ORG.LIVE_AGENT:
                      tags.push(
                        <Tooltip title={t('LiveChat Agent')}>
                          <Tag style={{ borderRadius: 4, width: 32 }} className="fs-base" key="liveAgent"><CustomerServiceFilled /></Tag>
                        </Tooltip>
                      );
                      break;
                    case ADDITIONAL_ROLES_ORG.LIVECHAT_ADMIN:
                      tags.push(
                        <Tooltip title={t('LiveChat Admin')}>
                          <Tag style={{ borderRadius: 4, width: 32 }} className="fs-base" key="liveAdmin"><LiveChatSettingsIcon style={{ verticalAlign: -3 }}/></Tag>
                        </Tooltip>
                      );
                      break;
                    case ADDITIONAL_ROLES_ORG.HRWIZ_EMPLOYEE:
                      tags.push(
                        <Tooltip title={t('HRwiz Employee')}>
                          <Tag style={{ borderRadius: 4, width: 32 }} className="fs-base" key="hrwiz"><HrwizIcon /></Tag>
                        </Tooltip>
                      );
                      break;
                    default:
                      break;
                  }
                }
                return tags;

              }
            },
            {
              title: t('Status'),
              key: 'isActive',
              filters: [{
                text: t('Active'),
                value: true
              }, {
                text: t('Inactive'),
                value: false
              }],
              filterMultiple: false,
              filteredValue: searchParams.get('isActive') ? [searchParams.get('isActive')] : [],
              responsive: ['xl'],
              render: (_text, record) => {
                return (
                  record.isActive ? (
                    <Tag color="success">{t('Active')}</Tag>
                  ) : (
                    <Tag color="error">{t('Inactive')}</Tag>
                  )
                );
              }
            },
            {
              key: 'actions',
              title: t('Actions'),
              width: 114,
              align: 'center',
              render: (_value, record) => (
                <Space wrap={false} align="center">
                  <Tooltip
                    title={t('Edit')}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      icon={<EditOutlined />}
                      disabled={!bots}
                      onClick={() => {
                        updateUrlSearchParams({ userId: record.id });
                      }}
                    />
                  </Tooltip>

                  <Popconfirm
                    title={t('Delete this user?')}
                    okText={t('Delete')}
                    onConfirm={ async () => {
                      const isDeleted = await deleteUser({ orgId, userId: record.id }).unwrap();
                      if (isDeleted) {
                        if (selectedUsers.includes(record.id)) {
                          const newSelectedUsers = [...selectedUsers];
                          const reqIndex = newSelectedUsers.indexOf(record.id);
                          newSelectedUsers.splice(reqIndex, 1);
                          setSelectedUsers(newSelectedUsers);
                        }

                        // If current page has only one user before deletion then persist all current filters
                        // and navigate to correct page
                        if (users.items.length === 0) {
                          updateUrlSearchParams({ initialFilters: {
                            ...currentFilters,
                            page: currentFilters.page - 1
                          } });
                        }
                      }

                      return;
                    }}
                    placement="topRight"
                    zIndex={1100}
                  >
                    <Tooltip
                      title={t('Delete')}
                    >
                      <Button
                        type="text"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              )
            }
          ]}
        />

        <BulkActionsModal
          title={`${selectedUsers.length === 1 ? t('User') : t('Users')}`}
          action={bulkAction}
          isModalOpen={isBulkActionsUsersModalOpen}
          data={bulkActionsUsersModalData()}
          onModalClose={closeBulkActionsUsersModal}
          onModalConfirm={handleBulkActionsUsers}
          loader={isFetchingUsers || isDeletingUser || isDeletingUsers}
          onShowMore={onBulkActionsUsersModalShowMore}
          entityCount={usersToShow}
          displayProperty="fullName"
        />
      </Collapse.Panel>

      <Collapse.Panel
        key={1}
        showArrow={false}
        className="ant-collapse-p-0"
      >
        <OrganizationUserForm />
      </Collapse.Panel>
    </Collapse>
  );
};

export default OrganizationUsersTable;
