// Description: Modal for displaying the funneling graph

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Component imports
import { Button, Col, Image, Modal, Popconfirm, Row } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

// Misc imports
import { copyTextToClipboard } from '../../utils/function/copyToClipboard';

const MediaPreviewModal = ({
  id = null,
  isPreviewModalOpen = false,
  isDeletingMediaFile = null,
  onPreviewClose = () => {},
  handleDelete = () => {},
  file = {}
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isPreviewModalOpen && id === file.id}
      center
      title={file.fileName}
      onCancel={onPreviewClose}
      style={{ maxWidth: 600 }}
      destroyOnClose
      width={'95%'}
      centered
      bodyStyle={{ textAlign: 'center' }}
      footer={
        <Row justify="end" align="center">
          <Col>
            <Button
              shape="circle"
              type="text"
              icon={<CopyOutlined />}
              loading={isDeletingMediaFile}
              onClick={() => copyTextToClipboard(file.url)}
            />

            <Popconfirm
              title={t('Are you sure you want to delete this file?')}
              okText={t('Yes')}
              cancelText={t('No')}
              placement="topRight"
              onConfirm={() => handleDelete(file)}
            >
              <Button
                shape="circle"
                type="text"
                icon={<DeleteOutlined />}
                loading={isDeletingMediaFile}
              />
            </Popconfirm></Col>
        </Row>
      }
    >
      {file.mimeType.match(/(mp4)$/)
        ? <ReactPlayer
          controls={true}
          url={file.url}
          width="100%"
          height="100%"
        />
        : <Image
          align="middle"
          src={file.url}
          preview={{
            visible: false,
            src: file.url,
            mask: null
          }}
        />
      }

    </Modal>
  );
};

MediaPreviewModal.propTypes = {
  id: PropTypes.number,
  file: PropTypes.object,
  isPreviewModalOpen: PropTypes.bool,
  isDeletingMediaFile: PropTypes.bool,
  onPreviewClose: PropTypes.func,
  handleDelete: PropTypes.func
};

export default MediaPreviewModal;
