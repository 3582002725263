import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { authActions } from '../auth/authSlice';
import { MESSAGES } from './organizationMessages';

export const organizationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrganization: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_ORGANIZATION.ERROR
        }
      }),
      providesTags: (result, error, orgId) => {
        return [
          { type: API_TAGS.ORGANIZATION, id: orgId }
        ];
      }
    }),

    createOrganization: builder.mutation({
      query: ({ data, successMessage = { showMessage: true } }) => ({
        url: `organizations`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_ORGANIZATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_ORGANIZATION.SUCCESS
        }
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled.then(async response => {
          // Get the current user from the store and extend it with the new organization
          await dispatch(authActions.userFetchComplete({
            ...getState().auth.currentUser,
            organizations: [
              ...getState().auth.currentUser.organizations,
              response?.data
            ]
          }));
        });
      },
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ORGANIZATION, id: 'LIST' },
          { type: API_TAGS.AUTH, id: 'Me' }
        ];
      }
    }),

    updateOrganization: builder.mutation({
      query: ({ orgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_ORGANIZATION.ERROR,
          errorCodesToIgnore: [401, 403]
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_ORGANIZATION.SUCCESS
        }
      }),
      invalidatesTags: (result, error, orgId) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ORGANIZATION, id: orgId },
          { type: API_TAGS.AUDIT_LOGS, id: 'LIST' }
        ];
      }
    }),

    deleteOrganization: builder.mutation({
      query: ({ orgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_ORGANIZATION.ERROR,
          errorCodesToIgnore: [401, 403]
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_ORGANIZATION.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ORGANIZATION, id: 'LIST' },
          { type: API_TAGS.AUTH, id: 'Me' }
        ];
      }
    })

  })
});

export const {
  useFetchOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation
} = organizationSlice;

