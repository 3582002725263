import { apiSlice } from '../apiSlice';
import { MESSAGES } from './registerMessages';

export const registerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: ({ data, urlParams = {} }) => ({
        url: 'public/registration',
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.REGISTER_USER.ERROR
        }
      })
    })
  })
});

export const {
  useRegisterUserMutation
} = registerSlice;
