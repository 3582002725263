// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// import Components
import AppLogo from '../../assets/logo-full.svg';
import { Card, Col, Grid, Row } from 'antd';

const { useBreakpoint } = Grid;

const GradientCard = ({
  rightSizeContent,
  leftSizeContent
}) => {
  const screens = useBreakpoint();
  const isTabletSize = !screens.xl && !screens.xxl;

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      className="border-0"
    >
      <Row>
        <Col className="d-flex flex-column" xs={isTabletSize ? 24 : 10}>
          <Row className="mb-5" justify={'start'}>
            <Col span={24}>
              <img className="mt-5 ml-5" src={AppLogo} alt="logo" width={80} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center" span={24}>
              {leftSizeContent}
            </Col>
          </Row>
        </Col>
        {
          !isTabletSize
          && <Col
            xs={14}
            className="auth-screen-background flex-column position-relative"
          >
            <div style={{ minHeight: '650px' }}>
              {rightSizeContent}
            </div>
          </Col>
        }
      </Row>
    </Card>
  );
};

GradientCard.propTypes = {
  rightSizeContent: PropTypes.any,
  leftSizeContent: PropTypes.any
};

export default GradientCard;
