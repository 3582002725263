import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './usersMessages';

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/users`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USERS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [
            { type: API_TAGS.USERS, id: 'LIST' }
          ];
        } else {
          return [
            { type: API_TAGS.USERS, id: 'LIST' },
            ...result.items.map(({ id }) => ({ type: API_TAGS.USERS, id }))
          ];
        }
      }
    }),

    fetchUser: builder.query({
      query: ({ orgId, userId, urlParams = {} }) => ({
        url: `organizations/${orgId}/users/${userId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USERS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.USERS, id: args.userId }
        ];
      }
    }),

    fetchUsersThinList: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/users/list`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USERS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [
            { type: API_TAGS.USERS, id: 'LIST' }
          ];
        } else {
          return [
            { type: API_TAGS.USERS, id: 'LIST' },
            ...result.map(({ id }) => ({ type: API_TAGS.USERS, id }))
          ];
        }
      }
    }),

    createUser: builder.mutation({
      query: ({ orgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/users`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_USER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_USER.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' }
        ];
      }
    }),

    deleteUser: builder.mutation({
      query: ({ orgId, userId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/users/${userId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_USER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_USER.SUCCESS
        }
      }),
      transformResponse: () => {
        return true;
      },
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' }
        ];
      }
    }),

    updateUser: builder.mutation({
      query: ({ orgId, userId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/users/${userId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_USER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_USER.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' },
          { type: API_TAGS.USERS, id: args.userId }
        ];
      }
    }),

    deleteUsers: builder.mutation({
      query: ({ orgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/users/batch`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        body: { ids: data },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_USERS.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_USERS.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' }
        ];
      }
    }),

    updateUserInfo: builder.mutation({
      query: ({ userId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `users/${userId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_USER_INFO.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_USER_INFO.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: 'Auth', id: 'Me' }
        ];
      }
    }),

    inviteUser: builder.mutation({
      query: ({ orgId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/user-invitations`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.INVITE_USER.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.INVITE_USER.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' },
          { type: API_TAGS.USERS, id: 'INVITATION' }
        ];
      }
    }),

    acceptUserInvitation: builder.mutation({
      query: ({ data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `public/user-invitations`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.ACCEPT_USER_INVITATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.ACCEPT_USER_INVITATION.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' },
          { type: API_TAGS.USERS, id: 'INVITATION' },
          { type: API_TAGS.AUTH, id: 'Me' }
        ];
      }
    }),

    revokeUserInvitation: builder.mutation({
      query: ({ orgId, userId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/user-invitations/${userId}/revoke`,
        method: 'POST',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.REVOKE_USER_INVITATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.REVOKE_USER_INVITATION.SUCCESS
        }
      }),
      transformResponse: () => true,
      transformErrorResponse: () => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' },
          { type: API_TAGS.USERS, id: 'INVITATION' }
        ];
      }
    }),

    validateUserInvitation: builder.mutation({
      query: ({ data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `public/user-invitations`,
        method: 'PUT',
        params: {
          ...urlParams
        },
        body: data,
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.VALIDATE_USER_INVITATION.ERROR,
          errorCodesToIgnore: [404]
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.VALIDATE_USER_INVITATION.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USERS, id: 'LIST' },
          { type: API_TAGS.USERS, id: 'INVITATION' }
        ];
      }
    }),

    fetchUserInvitations: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/user-invitations`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USER_INVITATIONS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.USERS, id: 'INVITATION' }
        ];
      }
    }),

    fetchUserInvitation: builder.query({
      query: ({ invitationId, urlParams = {} }) => ({
        url: `public/user-invitations/${invitationId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USER_INVITATION.ERROR
        }
      })
    })
  })
});

export const {
  useFetchUsersQuery,
  useFetchUsersThinListQuery,
  useFetchUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useDeleteUsersMutation,
  useUpdateUserInfoMutation,
  useInviteUserMutation,
  useAcceptUserInvitationMutation,
  useRevokeUserInvitationMutation,
  useValidateUserInvitationMutation,
  useFetchUserInvitationsQuery,
  useFetchUserInvitationQuery
} = usersSlice;
