import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_MEDIA_FILES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch media files.'),
      403: i18n.t('You are not allowed to fetch media files.'),
      404: i18n.t('The media files you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the media files.'),
      default: i18n.t('An error occurred fetching the media files.')
    }
  },

  CREATE_MEDIA_FILE: {
    ERROR: {
      401: i18n.t('You are not authorized to create media file.'),
      403: i18n.t('You are not allowed to create media file.'),
      404: i18n.t('The media file you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the media file.'),
      default: i18n.t('An error occurred creating the media file.')
    },
    SUCCESS: {
      200: i18n.t('Media file created successfully!')
    }
  },

  DELETE_MEDIA_FILE: {
    ERROR: {
      401: i18n.t('You are not authorized to delete media file.'),
      403: i18n.t('You are not allowed to delete media file.'),
      404: i18n.t('The media file you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the media file.'),
      default: i18n.t('An error occurred deleting the media file.')
    },
    SUCCESS: {
      200: i18n.t('Media file deleted successfully!')
    }
  },

  DELETE_MEDIA_FILES: {
    ERROR: {
      401: i18n.t('You are not authorized to delete media files.'),
      403: i18n.t('You are not allowed to delete media files.'),
      404: i18n.t('The media files you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the media files.'),
      default: i18n.t('An error occurred deleting the media files.')
    },
    SUCCESS: {
      200: i18n.t('Media files deleted successfully!')
    }
  }
};
