import { API_TAGS } from '../../constants/api';
import { API_CORE_URL, API_LIVECHAT_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './userGroupsMessages';

export const userGroupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserGroups: builder.query({
      query: ({ orgId, urlParams = { page: 0, pageSize: 100 } }) => ({
        url: `organizations/${orgId}/user-groups`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USER_GROUPS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [
            { type: API_TAGS.USER_GROUPS, id: 'LIST' }
          ];
        } else {
          return [
            { type: API_TAGS.USER_GROUPS, id: 'LIST' },
            ...result.items.map(({ id }) => ({ type: API_TAGS.USER_GROUPS, id }))
          ];
        }
      }
    }),
    fetchUserGroup: builder.query({
      query: ({ orgId, userGroupId, urlParams = {}, isLCM = false }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}${isLCM ? '/business-hours' : ''}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: isLCM ? API_LIVECHAT_URL : API_CORE_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USER_GROUP.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.USER_GROUPS, id: args.userGroupId }
        ];
      }
    }),
    createUserGroup: builder.mutation({
      query: ({ orgId, userGroupId, data, urlParams = {}, isLCM = false, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/user-groups${isLCM ? `/${userGroupId}/business-hours` : ''}`,
        method: 'POST',
        params: {
          ...urlParams
        },
        API_URL: isLCM ? API_LIVECHAT_URL : API_CORE_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_USER_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_USER_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USER_GROUPS, id: 'LIST' }
        ];
      }
    }),
    updateUserGroup: builder.mutation({
      query: ({ orgId, userGroupId, businessHourId, data, urlParams = {}, isLCM = false, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}${isLCM ? `/business-hours/${businessHourId}` : ''}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        API_URL: isLCM ? API_LIVECHAT_URL : API_CORE_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_USER_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_USER_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USER_GROUPS, id: args.userGroupId }
        ];
      }
    }),
    deleteUserGroup: builder.mutation({
      query: ({ orgId, userGroupId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_USER_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_USER_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.USER_GROUPS, id: 'LIST' }
        ];
      }
    }),
    fetchGroupBusinessHour: builder.query({
      query: ({ orgId, userGroupId, urlParams = {} }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}/business-hours`,
        method: 'GET',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_USER_GROUP.ERROR
        }
      })
    }),
    createGroupBusinessHour: builder.mutation({
      query: ({ orgId, userGroupId, data, urlParams = {} }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}/business-hours`,
        method: 'POST',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        body: data
      }),
      transformResponse: (response) => true
    }),
    updateGroupBusinessHour: builder.mutation({
      query: ({ orgId, userGroupId, businessHourId, data, urlParams = {} }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}/business-hours/${businessHourId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL,
        body: data
      }),
      transformResponse: (response) => true
    }),
    deleteGroupBusinessHour: builder.mutation({
      query: ({ orgId, userGroupId, businessHourId, urlParams = {} }) => ({
        url: `organizations/${orgId}/user-groups/${userGroupId}/business-hours/${businessHourId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        API_URL: API_LIVECHAT_URL
      }),
      transformResponse: (response) => true
    })
  })
});

export const {
  useFetchUserGroupsQuery,
  useFetchUserGroupQuery,
  useLazyFetchUserGroupQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useLazyFetchGroupBusinessHourQuery,
  useCreateGroupBusinessHourMutation,
  useUpdateGroupBusinessHourMutation,
  useDeleteGroupBusinessHourMutation
} = userGroupsSlice;
