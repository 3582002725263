import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_TEST_SETS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the test sets.'),
      403: i18n.t('You are not allowed to fetch the test sets.'),
      404: i18n.t('The test sets you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the test sets.'),
      default: i18n.t('Αn error occurred fetching the test sets.')
    }
  },

  FETCH_TEST_SET: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the test set.'),
      403: i18n.t('You are not allowed to fetch the test set.'),
      404: i18n.t('The test set you are trying to fetch do not exist.'),
      500: i18n.t('Αn error occurred fetching the test set.'),
      default: i18n.t('Αn error occurred fetching the test set.')
    }
  },

  CREATE_TEST_SET: {
    ERROR: {
      401: i18n.t('You are not authorized to create the test set.'),
      403: i18n.t('You are not allowed to create the test set.'),
      404: i18n.t('The test set you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the test set.'),
      default: i18n.t('Αn error occurred creating the test set.')
    },
    SUCCESS: {
      200: i18n.t('Test set created successfully!')
    }
  },

  UPDATE_TEST_SET: {
    ERROR: {
      401: i18n.t('You are not authorized to update the test set.'),
      403: i18n.t('You are not allowed to update the test set.'),
      404: i18n.t('The test set you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the test set.'),
      default: i18n.t('Αn error occurred updating the test set.')
    },
    SUCCESS: {
      200: i18n.t('Test set updated successfully!')
    }
  },

  DELETE_TEST_SET: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the test set.'),
      403: i18n.t('You are not allowed to delete the test set.'),
      404: i18n.t('The test set you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the test set.'),
      default: i18n.t('Αn error occurred deleting the test set.')
    },
    SUCCESS: {
      200: i18n.t('Test set deleted successfully!')
    }
  },

  RUN_TEST_SET: {
    ERROR: {
      401: i18n.t('You are not authorized to run the test set.'),
      403: i18n.t('You are not allowed to run the test set.'),
      404: i18n.t('The test set you are trying to run does not exist.'),
      500: i18n.t('An error occurred running the test set.'),
      default: i18n.t('Αn error occurred running the test set.')
    },
    SUCCESS: {
      200: i18n.t('Test set started successfully!')
    }
  }
};
