// Description: Modal for displaying the funneling graph

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import { Modal } from 'antd';
import FlowGraph from '../Graphs/FlowGraph';

const AnalyticsFunnelingGraphModal = ({
  isModalVisible= false,
  handleModalCancel= () => {},
  graphData={}
}) => {
  return (
    <Modal
      destroyOnClose={true}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={null}
      title={graphData?.tenantDisplayName}
      centered
      width={'80%'}
    >
      <div>
        <FlowGraph
          injectedNodeData={graphData?.injectedNodeData}
          isViewOnlyMode={true}
          bot={{
            id: graphData?.tenantId
          }}
          activityName={graphData?.activityId}
        />
      </div>
    </Modal>
  );
};

AnalyticsFunnelingGraphModal.propTypes = {
  isModalVisible: PropTypes.bool,
  handleModalCancel: PropTypes.func,
  graphData: PropTypes.object
};

export default React.memo(AnalyticsFunnelingGraphModal);
