import Icon from '@ant-design/icons';

const ImportantSvg = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.64588 5.63744L10.9883 9.89648H1.33398V0.896484H10.9883L8.64588 5.15553L8.51335 5.39648L8.64588 5.63744Z" stroke="currentColor"/>
  </svg>
);

export const ImportantIcon = (props) => (
  <Icon component={ImportantSvg} {...props} />
);
