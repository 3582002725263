// Core imports
import { Fragment, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

// Component imports
import { Avatar, Button, Col, Collapse, Dropdown, Form, Grid, Input, Modal, Popconfirm, Row, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import { SearchOutlined, SyncOutlined, PlusOutlined, DeleteOutlined, EditOutlined, DownOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Permissioned from '../Permissioned';
import ResponsiveTagsList from '../UI/ResponsiveTagsList';
import { BotIcon } from '../Icons/BotIcon';
import BotAvatar from '../UI/BotAvatar';
import BulkActionsModal from '../Modals/BulkActionsModal';
import KnowledgeBasesForm from '../Forms/KnowledgeBasesForm';

// Misc imports
import { LANGUAGE_OPTIONS, PERMISSIONS_GROUP_EDITOR, PERMISSIONS_GROUP_VIEWER } from '../../constants/main';
import useUserPermissions from '../../utils/hooks/useUserPermissions';
import userHasEditAccess from '../../utils/misc/hasEditAccess';
import { useDeleteKnowledgeBaseMutation, useDeleteKnowledgeBasesMutation, useFetchKnowledgeBasesQuery, useSyncKnowledgeBaseMutation, useSyncKnowledgeBasesMutation } from '../../store/knowledgeBases/knowledgeBasesApi';
import { isEmptyArray } from '../../utils/is/isEmptyArray';
import useDebouncedCallback from '../../utils/hooks/useDebounce';
import { NAV_ORGANIZATION_KNOWLEDGE_BASE, NAV_ORGANIZATION_KNOWLEDGE_BASE_NEW } from '../../constants/navigation';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { getCurrentQueryState } from '../../store/utils/getCurrentQueryState';

// Constant declarations
const SPACE_TYPES = {
  EX: 'EX',
  CX: 'CX'
};
const BULK_ACTIONS = {
  SYNC: 'sync',
  DELETE: 'delete'
};

const { useBreakpoint } = Grid;
const { Text, Paragraph } = Typography;
const PAGE_SIZE = 20;

const KnowledgeBasesTable = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { tenantId, orgId, knowledgeBaseId } = useParams();
  const userPermissions = useUserPermissions(orgId, tenantId);
  const screens = useBreakpoint();
  const isMobileSize = (screens.xs || screens.sm) && !screens.lg && !screens.md;
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);

  const [syncKB, { isLoading: isSyncingKnowledgeBase }] = useSyncKnowledgeBaseMutation();
  const [syncKBs, { isLoading: isSyncingKnowledgeBases }] = useSyncKnowledgeBasesMutation();
  const { data: knowledgeBases, isFetching: isFetchingKnowledgeBases } = useFetchKnowledgeBasesQuery({ orgId, urlParams: {
    includeTenants: true,
    includeIntegration: true,
    page: currentPage - 1,
    pageSize: PAGE_SIZE,
    ...(searchParams.get('hasIntegration') && { hasIntegration: searchParams.get('hasIntegration') }),
    ...(searchParams.get('spaces') && { space: searchParams.get('spaces') }),
    ...(searchParams.get('bots') && { tenantIds: searchParams.get('bots') }),
    sortBy: searchParams.get('sortBy') || 'createdAt',
    order: searchParams.get('order') || 'desc',
    ...(searchParams.get('searchTerm') && { name: searchParams.get('searchTerm') })
  } });
  const { data: bots } = getCurrentQueryState('fetchBots', { orgId }, !orgId);
  const [deleteKnowledgeBase] = useDeleteKnowledgeBaseMutation();
  const [deleteKnowledgeBases, { isLoading: isDeletingKnowledgeBases }] = useDeleteKnowledgeBasesMutation();
  const { organizationRoles, tenantRoles } = useSelector((state) => state.auth.currentUser);

  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);
  const [knowledgeBasesToShow, setKnowledgeBasesToShow] = useState([]);
  const [bulkAction, setBulkAction] = useState();
  const [isBulkActionsKnowledgeBasesModalOpen, setIsBulkActionsKnowledgeBasesModalOpen] = useState(false);
  const [isAddKBModalOpen, setIsAddKBModalOpen] = useState(false);

  const hasEditAccess = userHasEditAccess(orgId, organizationRoles, tenantId, tenantRoles, false);

  const onRowSelectChange = (newSelectedKnowledgeBases) => {
    setSelectedKnowledgeBases(newSelectedKnowledgeBases);
  };

  const updateUrlSearchParams = (current = 1, sorting = { createdAt: 'desc' }, filters) => {
    setCurrentPage(current);
    const urlSearchParams = new URLSearchParams(location.search);

    const searchTerm = form.getFieldValue('searchTerm');
    urlSearchParams.set('page', current - 1);
    urlSearchParams.set('pageSize', PAGE_SIZE);

    if (sorting) {
      urlSearchParams.set('sortBy', Object.entries(sorting).flat()[0]);
      urlSearchParams.set('order', Object.entries(sorting).flat()[1]);
    } else {
      urlSearchParams.delete('sortBy');
      urlSearchParams.delete('order');
    }

    if (filters?.type) {
      urlSearchParams.set('hasIntegration', encodeURIComponent(filters.type[0]));
    } else {
      urlSearchParams.delete('hasIntegration');
    }

    if (filters?.space) {
      urlSearchParams.set('spaces', encodeURIComponent(filters.space[0]));
    } else {
      urlSearchParams.delete('spaces');
    }

    if (filters?.bots) {
      urlSearchParams.set('bots', encodeURIComponent(filters.bots));
    } else {
      urlSearchParams.delete('bots');
    }

    if (searchTerm) {
      urlSearchParams.set('searchTerm', encodeURIComponent(searchTerm));
    } else {
      urlSearchParams.set('searchTerm', encodeURIComponent(''));
    }

    // Set the filters to the url
    navigate({
      pathname: location.pathname,
      search: urlSearchParams.toString()
    }, { replace: true });
  };

  const onKnowledgeBaseDelete = async (record) => {
    await deleteKnowledgeBase({ orgId: orgId, id: record.id });
  };

  const onKnowledgeBaseSync = async (id) => {
    await syncKB({ orgId: orgId, id: id });
  };

  const handleBulkActionsMenuClick = (e) => {
    openBulkActionsKnowledgeBasesModal();
    setBulkAction(e.key);
  };

  const openBulkActionsKnowledgeBasesModal = () => {
    setKnowledgeBasesToShow(selectedKnowledgeBases.length > 5 ? 5 : selectedKnowledgeBases.length);
    setIsBulkActionsKnowledgeBasesModalOpen(true);
  };

  const closeBulkActionsKnowledgeBasesModal =(props) => {
    setIsBulkActionsKnowledgeBasesModalOpen(false);
  };

  const onBulkActionsKnowledgeBasesModalShowMore = () => {
    setKnowledgeBasesToShow(knowledgeBasesToShow + 5);
  };

  const handleBulkActionsKnowledgeBases = async () => {
    switch(bulkAction) {
      case BULK_ACTIONS.DELETE:
        await deleteKnowledgeBases({ orgId: orgId, urlParams: { ids: selectedKnowledgeBases } } );
        break;
      case BULK_ACTIONS.SYNC:
        await syncKBs({ orgId: orgId, urlParams: { ids: selectedKnowledgeBases } });
        break;
      default:
        break;
    }

    setSelectedKnowledgeBases([]);
    setIsBulkActionsKnowledgeBasesModalOpen(false);
  };

  const bulkActionsKnowledgeBasesModalData = () => {
    return selectedKnowledgeBases?.map((selectedKnowledgeBase) => {
      return knowledgeBases.items.find(item => item.id === selectedKnowledgeBase);
    });
  };

  const onAddKBModalOpen = () => {
    setIsAddKBModalOpen(true);
  };

  const onAddKBModalClose = () => {
    setIsAddKBModalOpen(false);
  };

  const debouncedOnChange = useDebouncedCallback(updateUrlSearchParams);

  const handleTableChange = (pagination, filters, sorter) => {
    let sorting;
    if (sorter.column) {
      sorting = { [sorter.columnKey]: sorter.order === 'ascend' ? 'asc' : 'desc' };
    } else {
      if (sorter.field === 'createdAt' && searchParams.get('order') === 'desc') {
        sorting = { [sorter.columnKey]: 'asc' };
      }
    }
    updateUrlSearchParams(pagination.current, sorting, filters);
  };

  const sortingInfo = useMemo(() => {
    let order = searchParams.get('order') ? (searchParams.get('order') === 'desc' ? 'descend' : 'ascend') : 'descend';
    let columnKey = searchParams.get('sortBy') ? searchParams.get('sortBy') : 'createdAt';

    return {
      order,
      columnKey
    };
  }, [searchParams.get('order'), searchParams.get('sortBy')]);

  const columns = [
    {
      title: t('KB Name'),
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortingInfo.columnKey === 'name' && sortingInfo.order,
      render: record => {
        return record ? (
          <Space>
            <Highlighter
              highlightClassName="bg-highlight p-0"
              searchWords={[searchParams.get('searchTerm')]}
              autoEscape
              textToHighlight={record.name}
            />
          </Space>
        ) : null;
      }
    },
    {
      title: t('Type'),
      key: 'type',
      filters: [{
        text: 'Integration',
        value: true
      }, {
        text: 'Internal',
        value: false
      }],
      filterMultiple: false,
      render: record => {
        return record?.integration ? t('Integration') : t('Internal');
      }
    },
    {
      key: 'bots',
      title: t('Bots'),
      responsive: ['sm'],
      dataIndex: 'tenantAssociations',
      ellipsis: true,
      filterMultiple: true,
      filteredValue: searchParams.get('bots') ? decodeURIComponent(searchParams.get('bots'))?.split(',') : null,
      filterSearch: (input, record) => {
        const botDisplayName = bots.items.find((bot) => bot.id === record.value).displayName.toLowerCase();
        return botDisplayName.includes(input.toLowerCase());
      },
      filters: bots?.items.map(bot => ({
        text: (
          <Space size={4} align="center" wrap={false}>
            <Avatar
              src={bot?.image}
              size={24}
              shape="square"
              className="mr-2"
            >
              <BotIcon />
            </Avatar>

            <Typography.Text ellipsis>
              {bot.displayName}
            </Typography.Text>
          </Space>
        ),
        value: bot.id
      })),
      render: record => {
        if (!record && isFetchingKnowledgeBases) {
          return <Skeleton.Button active size="small" />;
        }

        if (!record?.length > 0) {
          return '-';
        }
        const formattedRecord = record.map(tenant => tenant.tenantId);
        const associatedBots = bots?.items?.filter(bot => formattedRecord.includes(bot.id)) ?? [];

        return (
          <ResponsiveTagsList
            values={
              associatedBots.map((bot) => ({
                icon: <BotAvatar avatarSize={24} bot={bot} showBotName={false} />,
                label: bot.displayName,
                value: bot.id
              }))
            }
            maxTagCount={4}
            tagRender={(tag) => {
              const matchedTag = associatedBots.find(bot => bot.id === tag.value);
              return (
                <Tooltip title={matchedTag.displayName}>
                  <BotAvatar avatarSize={24} bot={matchedTag} showBotName={false} className="mr-2" />
                </Tooltip>
              );
            }}
          />
        );
      }
    },
    {
      title: t('Languages'),
      key: 'languages',
      render: record => {
        return (
          <ResponsiveTagsList
            values={
              record.languages.map((language) => ({
                icon: LANGUAGE_OPTIONS.find(lang => lang.key === language.languageCode)?.icon,
                label: LANGUAGE_OPTIONS.find(lang => lang.key === language.languageCode)?.label,
                value: language.languageCode
              }))
            }
            maxTagCount={4}
            tagRender={(tag) => {
              return (
                <Tooltip title={tag.label}>
                  <Avatar
                    shape="square"
                    size={24}
                    className={`flex-none bg-transparent`}
                    icon={LANGUAGE_OPTIONS.find(lang => lang.key === tag.value)?.icon}
                  />
                </Tooltip>
              );
            }}
          />
        );
      }
    },
    {
      title: t('Related Space'),
      key: 'space',
      filters: Object.values(SPACE_TYPES).map((type) => {
        return {
          text: type,
          value: type
        };
      }),
      filterMultiple: false,
      render: record => {
        if (!record.spaces || isEmptyArray(record.spaces)) {
          return '-';
        }

        return (
          <ResponsiveTagsList
            values={record.spaces.map((space, index) => ({ label: space.toUpperCase(), value: index }))}
            mode="tags"
            style={{ width: '100%' }}
            tagRender={(tag) => <span className="ant-select-selection-item p-0 px-1 mr-2">{tag.label}</span>}
          />
        );
      }
    },
    {
      title: t('Actions'),
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 215,
      hidden: !userPermissions.some(permission => PERMISSIONS_GROUP_VIEWER.includes(permission)),
      render: record => {
        return (
          <Space>
            <Tooltip title={t('Edit KB')}>
              <Button
                type="text"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => navigate(`${replaceUrlParams(NAV_ORGANIZATION_KNOWLEDGE_BASE, { orgId: orgId, knowledgeBaseId: record.id })}`)}
              />
            </Tooltip>

            <Tooltip title={t('Sync KB')}>
              <Button
                shape="circle"
                type="text"
                icon={<SyncOutlined />}
                loading={isFetchingKnowledgeBases || isSyncingKnowledgeBase}
                disabled={!record.integration}
                onClick={() => onKnowledgeBaseSync(record.id)}
              />
            </Tooltip>

            <Popconfirm
              title={t('Delete Knowledge Base?')}
              okText={t('Yes')}
              cancelText={t('No')}
              placement="topRight"
              onConfirm={() => {
                onKnowledgeBaseDelete(record);
              }}
            >
              <Tooltip title={t('Delete KB')}>
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  loading={isFetchingKnowledgeBases}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedKnowledgeBases,
    onChange: onRowSelectChange,
    selections: [
      {
        key: 'all',
        text: t('All'),
        onSelect: () => onDropdownSelect('all')
      },
      {
        key: 'none',
        text: t('None'),
        onSelect: () => onDropdownSelect('none')
      }
    ]
  };

  const onDropdownSelect = (key) => {
    let newSelectedKnowledgeBases = [];
    if (key === 'all') {
      newSelectedKnowledgeBases = knowledgeBases?.items?.map((item) => item.name);
    }
    setSelectedKnowledgeBases(newSelectedKnowledgeBases);
  };

  return (
    <Fragment>
      <Collapse
        ghost
        accordion
        activeKey={knowledgeBaseId ? 1 : 0}
        destroyInactivePanel
      >
        <Collapse.Panel
          key={0}
          showArrow={false}
          className="ant-collapse-p-0"
          forceRender
        >
          <Row gutter={24} className="mb-2">
            <Col flex="auto">
              <Space className="flex-wrap mb-2">
                <Form
                  name="knowledgeBasesForm"
                  className="align-items-center"
                  initialValues={{
                    searchTerm: searchParams.get('searchTerm') ? decodeURIComponent(searchParams.get('searchTerm')) : ''
                  }}
                  form={form}
                >
                  <Form.Item
                    name="searchTerm"
                  >
                    <Input
                      size="large"
                      prefix={
                        <SearchOutlined
                          className="text-secondary"
                        />
                      }
                      placeholder={t('Search in contents')}
                      allowClear
                      onChange={(e) => {
                        debouncedOnChange();
                        form.setFieldsValue({ search: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Form>
              </Space>
            </Col>

            <Col>
              <Space>
                <Permissioned
                  allowedPermissions={PERMISSIONS_GROUP_EDITOR}
                  currentPermissions={userPermissions}
                  fallbackComponent={null}
                >
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      onClick: handleBulkActionsMenuClick,
                      items: [
                        {
                          label: t('Delete Selected'),
                          key: BULK_ACTIONS.DELETE
                        },
                        {
                          label: t('Sync Selected'),
                          key: BULK_ACTIONS.SYNC
                        }
                      ]
                    }}
                    disabled={!selectedKnowledgeBases.length > 0}
                  >
                    <Button
                      block
                      size="large"
                    >
                      {t('Bulk Actions')}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Permissioned>

                <Permissioned
                  allowedPermissions={PERMISSIONS_GROUP_EDITOR}
                  currentPermissions={userPermissions}
                  fallbackComponent={null}
                >
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    className="float-right"
                    size="large"
                    onClick={onAddKBModalOpen}
                    loading={isFetchingKnowledgeBases}
                  >
                    {t('Add New KB')}
                  </Button>
                </Permissioned>
              </Space>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24}>
              <Table
                rowSelection={hasEditAccess ? rowSelection : null}
                size="small"
                rowKey="id"
                columns={columns}
                dataSource={knowledgeBases?.items}
                loading={isFetchingKnowledgeBases}
                onChange={handleTableChange}
                pagination={{
                  total: knowledgeBases?.total || 0,
                  defaultCurrent: 1,
                  current: currentPage,
                  pageSize: knowledgeBases?.pageSize || 0,
                  showSizeChanger: false,
                  size: 'default'
                }}
              />
            </Col>
          </Row>
        </Collapse.Panel>

        <Collapse.Panel
          key={1}
          showArrow={false}
          className="ant-collapse-p-0"
        >
          <KnowledgeBasesForm knowledgeBaseId={knowledgeBaseId} />
        </Collapse.Panel>
      </Collapse>

      <BulkActionsModal
        title={`${selectedKnowledgeBases.length === 1 ? 'knowledge base' : 'knowledge bases'}`}
        action={bulkAction}
        isModalOpen={isBulkActionsKnowledgeBasesModalOpen}
        data={bulkActionsKnowledgeBasesModalData()}
        onModalClose={closeBulkActionsKnowledgeBasesModal}
        onModalConfirm={handleBulkActionsKnowledgeBases}
        loader={isDeletingKnowledgeBases || isSyncingKnowledgeBases}
        onShowMore={onBulkActionsKnowledgeBasesModalShowMore}
        entityCount={knowledgeBasesToShow}
        displayProperty="name"
      />

      <Modal
        title={
          <Fragment>
            <Paragraph className="d-flex mb-0">
              {t('Choose KB type')}
            </Paragraph>
            <Paragraph className={`fs-lg fw-normal text-muted mb-0 mt-2`}>
              {t('Choose how you prefer to insert the articles of your new Knowledge Base')}
            </Paragraph>
          </Fragment>
        }
        open={isAddKBModalOpen}
        onCancel={onAddKBModalClose}
        onOk={onAddKBModalClose}
        centered
        footer={null}
        width={ isMobileSize ? '95%' : '45%'}
      >
        <Row justify={'center'} gutter={48}>
          <Col span={isMobileSize ? 24 : 12}>
            <Row>
              <Col span={24} style={{ width: isMobileSize ? '100%' : '250px' }}>
                <Row justify={'center'}>
                  <Button disabled type="text" className="bg-layout" style={{ height: '100px', width: '100%' }}>
                    <Space.Compact direction="vertical">
                      <Text className="fw-bold">{t('Integration')}</Text>
                      <Text className="fs-sm fw-400">{t('Coming soon!')}</Text>
                    </Space.Compact>
                  </Button>
                </Row>
              </Col>

              <Col span={24} className="mt-3 mb-5">
                <Text className="fs-sm text-muted mt-5">{t('Import articles automatically by integrating one of the available platforms')}</Text>
              </Col>
            </Row>
          </Col>

          <Col span={isMobileSize ? 24 : 12}>
            <Row >
              <Col span={24} style={{ width: isMobileSize ? '100%' : '250px' }}>
                <Row justify={'center'}>
                  <Button
                    onClick={() => {
                      navigate(`${replaceUrlParams(NAV_ORGANIZATION_KNOWLEDGE_BASE_NEW, { orgId: orgId })}`);
                      onAddKBModalClose();
                    }}
                    type="text"
                    className="bg-layout"
                    style={{ height: '100px', width: '100%' }}
                  >
                    <Text className="fw-bold">{t('Internal')}</Text>
                  </Button>
                </Row>
              </Col>

              <Col span={24} className="mt-3 mb-5">
                <Text className="fs-sm text-muted mt-2">{t('Insert articles manually within the console using our editor')}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default KnowledgeBasesTable;
