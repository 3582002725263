import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const NodeAppearances = ({
  appearances=0
}) => {
  return (
    <Row
      className={`nodrag bg-black shadow text-center align-center align-content-center text-white `}
      gutter={18}
      style={{
        zIndex: -1,
        position: 'absolute',
        top: -12,
        height: 30,
        width: appearances >= 1000 ? 78 : 70,
        borderRadius: 50,
        right: -23

      }}
    >
      <Col span={24}>
        <span>
          <EyeOutlined className="mr-1"/>
        </span>
        <span>
          {`: ${appearances > 0 ? appearances : 0}`}
        </span>
      </Col>

    </Row>
  );
};

NodeAppearances.propTypes = {
  appearances: PropTypes.number
};

export default React.memo(NodeAppearances);
