import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_BOT_DEPLOYMENTS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot deployments.'),
      403: i18n.t('You are not allowed to fetch bot deployments.'),
      404: i18n.t('The bot deployments you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the bot deployments.'),
      default: i18n.t('An error occurred fetching the bot deployments.')
    }
  },
  FETCH_BOT_DEPLOYMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot deployment.'),
      403: i18n.t('You are not allowed to fetch bot deployment.'),
      404: i18n.t('The bot deployment you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the bot deployment.'),
      default: i18n.t('An error occurred fetching the bot deployment.')
    }
  },

  FETCH_BOT_DEPLOYMENT_PREVIEW: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot deployment preview.'),
      403: i18n.t('You are not allowed to fetch bot deployment preview.'),
      404: i18n.t('The bot deployment preview you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the bot deployment preview.'),
      default: i18n.t('An error occurred fetching the bot deployment preview.')
    }
  },
  CREATE_BOT_DEPLOYMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to create bot deployment.'),
      403: i18n.t('You are not allowed to create bot deployment.'),
      404: i18n.t('The bot deployment you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the bot deployment.'),
      default: i18n.t('An error occurred creating the bot deployment.')
    },
    SUCCESS: {
      200: i18n.t('Bot deployment created successfully!')
    }
  },
  UPDATE_BOT_DEPLOYMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to update bot deployment.'),
      403: i18n.t('You are not allowed to update bot deployment.'),
      404: i18n.t('The bot deployment you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the bot deployment.'),
      default: i18n.t('An error occurred updating the bot deployment.')
    },
    SUCCESS: {
      200: i18n.t('Bot deployment updated successfully!')
    }
  },
  DELETE_BOT_DEPLOYMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to delete bot deployment.'),
      403: i18n.t('You are not allowed to delete bot deployment.'),
      404: i18n.t('The bot deployment you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the bot deployment.'),
      default: i18n.t('An error occurred deleting the bot deployment.')
    },
    SUCCESS: {
      200: i18n.t('Bot deployment deleted successfully!')
    }
  },
  CLONE_BOT_DEPLOYMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to clone bot deployment.'),
      403: i18n.t('You are not allowed to clone bot deployment.'),
      404: i18n.t('The bot deployment you are trying to clone does not exist.'),
      500: i18n.t('An error occurred cloning the bot deployment.'),
      default: i18n.t('An error occurred cloning the bot deployment.')
    },
    SUCCESS: {
      200: i18n.t('Bot deployment cloned successfully!')
    }
  },
  DELETE_BOT_DEPLOYMENTS: {
    ERROR: {
      401: i18n.t('You are not authorized to delete bot deployments.'),
      403: i18n.t('You are not allowed to delete bot deployments.'),
      404: i18n.t('The bot deployments you are trying to delete do not exist.'),
      500: i18n.t('An error occurred deleting the bot deployments.'),
      default: i18n.t('An error occurred deleting the bot deployment.')
    },
    SUCCESS: {
      200: i18n.t('Bot deployments deleted successfully!')
    }
  }
};
