import { IS_HRWIZ_CONSOLE_ENABLED } from '../../constants/env';
import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_AUTOMATED_ANSWERS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch automated answers.'),
      403: i18n.t('You are not allowed to fetch automated answers.'),
      404: i18n.t('The automated answers you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the automated answers.'),
      default: i18n.t('An error occurred fetching the automated answers.')
    }
  },
  FETCH_AUTOMATED_ANSWER: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch automated answer.'),
      403: i18n.t('You are not allowed to fetch automated answer.'),
      404: i18n.t('The automated answer you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the automated answer.'),
      default: i18n.t('An error occurred fetching the automated answer.')
    }
  },
  CREATE_AUTOMATED_ANSWER: {
    ERROR: {
      401: i18n.t('You are not authorized to create automated answer.'),
      403: i18n.t('You are not allowed to create automated answer.'),
      404: i18n.t('The automated answer you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the automated answer.'),
      default: i18n.t('An error occurred creating the automated answer.')
    },
    SUCCESS: {
      200: i18n.t('Automated answer created successfully!')
    }
  },
  CREATE_AUTOMATED_ANSWERS: {
    ERROR: {
      401: i18n.t('You are not authorized to create automated answers.'),
      403: i18n.t('You are not allowed to create automated answers.'),
      404: i18n.t('The automated answers you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the automated answers.'),
      default: i18n.t('An error occurred creating the automated answers.')
    },
    SUCCESS: {
      200: i18n.t('Automated answers created successfully!')
    }
  },
  UPDATE_AUTOMATED_ANSWER: {
    ERROR: {
      401: i18n.t('You are not authorized to update automated answer.'),
      403: i18n.t('You are not allowed to update automated answer.'),
      404: i18n.t('The automated answer you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the automated answer.'),
      default: i18n.t('An error occurred updating the automated answer.')
    },
    SUCCESS: {
      200: IS_HRWIZ_CONSOLE_ENABLED ? i18n.t('FAQ updated') : i18n.t('Automated answer updated successfully!')
    }
  },
  DELETE_AUTOMATED_ANSWER: {
    ERROR: {
      401: i18n.t('You are not authorized to delete automated answer.'),
      403: i18n.t('You are not allowed to delete automated answer.'),
      404: i18n.t('The automated answer you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the automated answer.'),
      default: i18n.t('An error occurred deleting the automated answer.')
    },
    SUCCESS: {
      200: i18n.t('Automated answer deleted successfully!')
    }
  },

  UPDATE_AUTOMATED_ANSWERS: {
    ERROR: {
      401: i18n.t('You are not authorized to update automated answers.'),
      403: i18n.t('You are not allowed to update automated answers.'),
      404: i18n.t('The automated answers you are trying to update do not exist.'),
      500: i18n.t('An error occurred updating the automated answers.'),
      default: i18n.t('An error occurred updating the automated answers.')
    },
    SUCCESS: {
      200: i18n.t('Automated answers updated successfully!')
    }
  },

  DELETE_AUTOMATED_ANSWERS: {
    ERROR: {
      401: i18n.t('You are not authorized to delete automated answers.'),
      403: i18n.t('You are not allowed to delete automated answers.'),
      404: i18n.t('The automated answers you are trying to delete do not exist.'),
      500: i18n.t('An error occurred deleting the automated answers.'),
      default: i18n.t('An error occurred deleting the automated answers.')
    },
    SUCCESS: {
      200: i18n.t('Automated answers deleted successfully!')
    }
  }
};
