import { apiSlice } from '../apiSlice';
import { MESSAGES } from './liveChatSystemMessagesMessages';
import { ACTIVITY_TYPES, CHAPTERSOURCE_TYPES } from '../../constants/main';
import { generateLiveChatSystemMessagesChapterSources } from '../../utils/misc/generateLiveChatSystemMessagesChapterSources';
import { API_TAGS } from '../../constants/api';

export const liveChatMessagesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchLiveChatSystemMessages: build.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'GET',
        params: {
          ...urlParams,
          type: CHAPTERSOURCE_TYPES.FLOWGRAPH,
          activityTypes: `${ACTIVITY_TYPES.LIVECHAT_SYSTEM_MESSAGE}`
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_LIVE_CHAT_SYSTEM_MESSAGES.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_SYSTEM_MESSAGES, id: 'LIST' }
        ];
      }
    }),

    createLiveChatSystemMessage: build.mutation({
      query: ({ orgId, tenantId, primaryBotLanguage, successMessage = { showMessage: true }, errorMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'PUT',
        body: generateLiveChatSystemMessagesChapterSources(primaryBotLanguage),
        errorMessage: {
          ...errorMessage,
          messages: MESSAGES.CREATE_LIVE_CHAT_SYSTEM_MESSAGE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_LIVE_CHAT_SYSTEM_MESSAGE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.LIVE_CHAT_SYSTEM_MESSAGES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    updateLiveChatSystemMessage: build.mutation({
      query: ({ orgId, tenantId, liveChatSystemMessageName, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${liveChatSystemMessageName}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_LIVE_CHAT_SYSTEM_MESSAGE.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_LIVE_CHAT_SYSTEM_MESSAGE.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.LIVE_CHAT_SYSTEM_MESSAGES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchLiveChatSystemMessagesQuery,
  useCreateLiveChatSystemMessageMutation,
  useUpdateLiveChatSystemMessageMutation
} = liveChatMessagesSlice;
