import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_SCHEDULED_REPORTS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the reports.'),
      403: i18n.t('You are not allowed to fetch the reports.'),
      404: i18n.t('The reports you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the reports.'),
      default: i18n.t('An error occurred fetching the reports')
    }
  },
  FETCH_SCHEDULED_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the report.'),
      403: i18n.t('You are not allowed to fetch the report.'),
      404: i18n.t('The report you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the report.'),
      default: i18n.t('An error occurred fetching the report.')
    }
  },
  UPDATE_SCHEDULED_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to update the report.'),
      403: i18n.t('You are not allowed to update the report.'),
      404: i18n.t('The report you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the report.'),
      default: i18n.t('An error occurred updating the report.')
    },
    SUCCESS: {
      200: i18n.t('Report updated successfully!')
    }
  },
  DELETE_SCHEDULED_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the report.'),
      403: i18n.t('You are not allowed to delete the report.'),
      404: i18n.t('The report you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the report.'),
      default: i18n.t('An error occurred deleting the report')
    },
    SUCCESS: {
      200: i18n.t('Report deleted successfully!')
    }
  },
  CREATE_SCHEDULED_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to create the report.'),
      403: i18n.t('You are not allowed to create the report.'),
      404: i18n.t('The report you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the report.'),
      default: i18n.t('An error occurred creating the report')
    },
    SUCCESS: {
      200: i18n.t('Report created successfully!')
    }
  },
  TRIGGER_SCHEDULED_REPORT: {
    ERROR: {
      401: i18n.t('You are not authorized to trigger the report.'),
      403: i18n.t('You are not allowed to trigger the report.'),
      404: i18n.t('The report you are trying to trigger does not exist.'),
      500: i18n.t('An error occurred triggering the report.'),
      default: i18n.t('An error occurred triggering the report')
    },
    SUCCESS: {
      200: i18n.t('Report triggered successfully!')
    }
  }

};
