import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_LIVE_CHAT_MESSAGES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat messages.'),
      403: i18n.t('You are not allowed to fetch LiveChat messages.'),
      404: i18n.t('The LiveChat messages you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your LiveChat messages.'),
      default: i18n.t('An error occurred fetching your LiveChat messages.')
    }
  },
  FETCH_LIVE_CHAT_REQUESTS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat requests.'),
      403: i18n.t('You are not allowed to fetch LiveChat requests.'),
      404: i18n.t('The LiveChat requests you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your LiveChat requests.'),
      default: i18n.t('An error occurred fetching your LiveChat requests.')
    }
  },
  FETCH_LIVE_CHAT_CONVERSATION_INFO: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat conversation info.'),
      403: i18n.t('You are not allowed to fetch LiveChat conversation info.'),
      404: i18n.t('The LiveChat conversation info you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your LiveChat conversation info.'),
      default: i18n.t('An error occurred fetching your LiveChat conversation info.')
    }
  },

  FETCH_LIVE_CHAT_SESSION: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch chat session.'),
      403: i18n.t('You are not allowed to fetch chat session.'),
      404: i18n.t('The chat session you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your chat session.'),
      default: i18n.t('An error occurred fetching your chat session.')
    }
  },
  FETCH_LIVE_CHAT_CONVERSATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat conversations.'),
      403: i18n.t('You are not allowed to fetch LiveChat conversations.'),
      404: i18n.t('The LiveChat conversations you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your LiveChat conversations.'),
      default: i18n.t('An error occurred fetching your LiveChat conversations.')
    }
  },

  UPLOAD_ATTACHMENT: {
    ERROR: {
      401: i18n.t('You are not authorized to upload attachments.'),
      403: i18n.t('You are not allowed to upload attachments.'),
      500: i18n.t('An error occurred uploading your attachments.'),
      default: i18n.t('An error occurred uploading your attachments.')
    },
    SUCCESS: {
      200: i18n.t('Attachment uploaded successfully!')
    }
  },

  EXPORT_TRANSCRIPT: {
    ERROR: {
      401: i18n.t('You are not authorized to download the transcript.'),
      403: i18n.t('You are not allowed to download the transcript.'),
      500: i18n.t('An error occurred downloading your transcript.'),
      default: i18n.t('An error occurred downloading your transcript.')
    },
    SUCCESS: {
      200: i18n.t('Transcript downloaded successfully!')
    }
  }

};
