// Core imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Component imports
import { Button, Card, Checkbox, Col, Collapse, Form, Input, Row } from 'antd';
import { RightOutlined, SearchOutlined, ShareAltOutlined } from '@ant-design/icons';

// Misc imports
import { ANALYTICS_SECTIONS } from '../../constants/main';

const { Panel } = Collapse;

const ShareAnalyticsDropdown = (props) => {
  const { onShare } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [activeKey, setActiveKey] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, updateState] = useState();

  const onParentCheck = (sectionKey, checked) => {
    // Update children checkboxes
    const section = Object.values(ANALYTICS_SECTIONS).find((section) => section.key === sectionKey);
    const subSections = Object.values(section.subSections);
    subSections.forEach((subSection) => {
      form.setFieldValue(['sections', `${section.key}_${subSection.key}`], checked);
    });
  };

  const onChildCheck = (sectionKey) => {
    const section = Object.values(ANALYTICS_SECTIONS).find((section) => section.key === sectionKey);
    const areAllChildrenChecked = Object.values(section.subSections).every((subSection) => form.getFieldValue(['sections', `${sectionKey}_${subSection.key}`]) === true);

    form.setFieldValue(['sections', sectionKey], areAllChildrenChecked);
  };

  const onExpandIconClick = (panelKey) => {
    if (activeKey.includes(panelKey)) {
      setActiveKey(activeKey.filter((key) => key !== panelKey));
    } else {
      setActiveKey([...activeKey, panelKey]);
    }
  };

  const onSelectAllCheck = (checked) => {
    const allSections = Object.values(ANALYTICS_SECTIONS);

    allSections.forEach((sectionKey) => {
      form.setFieldValue(['sections', sectionKey.key], checked);
      onParentCheck(sectionKey.key, checked);
    });
  };

  const filteredSections = Object.values(ANALYTICS_SECTIONS).map((section) => {
    const filteredSubSections = Object.values(section.subSections).filter((subSection) =>
      t(subSection.key, { context: 'analytics section' }).toLowerCase().includes(searchText.toLowerCase())
    );

    return { ...section, subSections: filteredSubSections };
  }).filter((section) => section.subSections.length > 0);

  return (
    <Card
      size="small"
      className="overflow-y-auto overflow-x-hidden p-0"
      title={
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {t('Search Analytics for share via URL')}
          </Col>
          <Col span={24}>
            <Input
              prefix={<SearchOutlined className="text-secondary" />}
              placeholder={t('Search analytics sections')}
              onChange={(e) => setSearchText(e.target.value)}
              size="large"
              allowClear
            />
          </Col>
        </Row>
      }
    >
      <Form
        initialValues={{
          includeDatePicker: true
        }}
        onFinish={(values) => {
          const { includeDatePicker, sections } = values;
          const valuesToSend = Object.keys(sections).reduce((acc, key) => {
            if (sections[key] === true) {
              acc.push(key);
            }
            return acc;
          }, []);

          onShare(valuesToSend, includeDatePicker);
        }}
        form={form}
        onFieldsChange={(_, allFields) => updateState(allFields)}
      >
        <Card.Grid
          className="w-100 p-0 shadow-none"
          hoverable={false}
        >
          <Form.Item
            name="includeDatePicker"
            valuePropName="checked"
            className="pt-3 pl-5 mb-0"
          >
            <Checkbox>
              {t('Include datepicker')}
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="selectAll"
            valuePropName="checked"
            className="pl-5 mb-0"
          >
            <Checkbox
              onChange={(e) => onSelectAllCheck(e.target.checked)}
            >
              {t('Select all')}
            </Checkbox>
          </Form.Item>

          <Collapse
            bordered={false}
            ghost
            activeKey={activeKey}
            className="scrollbar-custom scrollbar-custom-nohide ant-collapse-header-py-0 ant-collapse-content-box-py-0 pb-3"
            style={{ maxHeight: 340, overflowY: 'auto' }}
            expandIcon={({ isActive, panelKey }) => (
              <Button
                icon={(
                  <RightOutlined
                    rotate={isActive ? 90 : 0}
                    onClick={(e) => {
                      e.stopPropagation();
                      onExpandIconClick(panelKey);
                    }}
                    className="fs-sm p-1"
                  />
                )}
                type="text"
                shape="circle"
                size="small"
                className="mr-2 mt-1"
              />
            )}
          >
            {
              filteredSections.map((section) => (
                <Panel
                  header={
                    <Form.Item
                      name={['sections', section.key]}
                      valuePropName="checked"
                      className="mb-0"
                    >
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => onParentCheck(section.key, e.target.checked)}
                      >
                        {t(section.key, { context: 'analytics section' })}
                      </Checkbox>
                    </Form.Item>
                  }
                  key={section.key}
                >
                  {
                    Object.values(section.subSections)
                      .filter(subSection => subSection.shareable)
                      .map((subSection) => (
                        <Form.Item
                          name={['sections', `${section.key}_${subSection.key}`]}
                          key={`${section.key}_${subSection.key}`}
                          valuePropName="checked"
                          className="ml-14 mb-0"
                        >
                          <Checkbox
                            onChange={() => onChildCheck(section.key)}
                          >
                            {t(subSection.key, { context: 'analytics section' })}
                          </Checkbox>
                        </Form.Item>
                      ))
                  }
                </Panel>
              ))
            }
          </Collapse>
        </Card.Grid>

        <Card.Grid className="w-100 shadow-none border-top py-2 px-10" hoverable={false}>
          <Button
            type="primary"
            icon={<ShareAltOutlined />}
            htmlType="submit"
            block
            size="large"
            disabled={!Object.values((form?.getFieldValue('sections') || {})).some((value) => value === true)}
          >
            {t('Share')}
          </Button>
        </Card.Grid>
      </Form>
    </Card>
  );
};

ShareAnalyticsDropdown.propTypes = {
  onShare: PropTypes.func
};

export default ShareAnalyticsDropdown;
