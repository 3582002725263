import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const location = useLocation();

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        }
      };
      if (
        autoUnblockingTx.location.pathname === location.pathname
        && autoUnblockingTx.location.search === location.search
      ) {
        return;
      }
      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message, when = true) {
  const blocker = useCallback((tx) => {
    Modal.confirm({
      title: 'Unsaved Changes',
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk() {
        tx.retry();
      }
    });
  }, [message]);
  return useBlocker(blocker, when);
}
