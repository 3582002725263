import { AUTH_ERROR_CODES } from '../../constants/errors';
import i18n from '../../i18n';

export const MESSAGES = {
  LOGIN_USER: {
    ERROR: {
      ...AUTH_ERROR_CODES,
      default: i18n.t('An error ocurred logging in.')
    }
  },

  REFRESH_TOKEN: {
    ERROR: {
      default: i18n.t('An error occurred fetching the user profile.')
    }
  },

  FETCH_ME: {
    ERROR: {
      default: i18n.t('An error occurred fetching the user profile.')
    }
  },

  VERIFY_EMAIL: {
    ERROR: {
      default: i18n.t('An error occurred verifying your email.')
    }
  }
};
