// Core imports
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// Component imports
import { Button, Card, Checkbox, Col, Divider, Form, Input, Radio, Row, Select, Space, Spin, Tag, Typography } from 'antd';
import { CustomerServiceFilled, LeftOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { LiveChatSettingsIcon } from '../Icons/LiveChatSettingsIcon';
import BotAvatar from '../UI/BotAvatar';
import MultiDropdown from './Fields/MultiDropdown';

// Misc imports
import { useFetchUserQuery, useInviteUserMutation, useUpdateUserMutation } from '../../store/users/usersApi';
import { ADDITIONAL_ROLES_ORG, PERMISSIONS_ORG, PERMISSIONS_TENANT } from '../../constants/main';
import { useFetchBotsQuery } from '../../store/bots/botsApi';
import { useFetchUserGroupsQuery } from '../../store/userGroups/userGroupsApi';

const OrganizationUserForm = () => {
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();

  const isCreateMode = useMemo(() => {
    return searchParams.get('userId') === 'invite-new-user';
  }, [location]);

  const { data: user, isLoading: isFetchingUser } = useFetchUserQuery({ orgId, userId: searchParams.get('userId') }, { skip: !orgId || !searchParams.get('userId') || isCreateMode });
  const { data: bots, isLoading: isLoadingBots } = useFetchBotsQuery({ orgId }, { skip: !orgId || !searchParams.get('userId') });
  const { data: userGroups, isLoading: isLoadingUserGroups } = useFetchUserGroupsQuery({ orgId, urlParams: { pageSize: 500, includeUsers: true } }, { skip: !orgId || !searchParams.get('userId') });
  const [inviteUser, { isLoading: isInvitingUser }] = useInviteUserMutation();
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const [tenantRoles, setTenantRoles] = useState({});

  useEffect(() => {
    if (user) {
      setTenantRoles(user.tenantRoles[orgId] || {});
    }
  }, [user, userGroups]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const SaveButton = () => (
    <Button
      type="primary"
      size="large"
      className="d-block ml-auto"
      onClick={() => form.submit()}
      loading={isInvitingUser || isUpdatingUser || isFetchingUser}
    >
      {!isCreateMode ? t('Save Changes') : t('Invite User')}
    </Button>
  );

  const onBackButtonPress = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete('userId');
    navigate({
      pathname: location.pathname,
      search: urlSearchParams.toString()
    });
  };

  const onSubmit = async (_values) => {
    const { fullName, email, orgAccess, appRoles = [], userGroupsIds } = form.getFieldsValue(true);

    let valuesToSubmit = {};

    if (user) {
      const organizationRoles = [
        ...appRoles,
        ...(user.organizationRoles?.[orgId] || []).filter(role => !Object.values(PERMISSIONS_ORG).includes(role) && !Object.values(ADDITIONAL_ROLES_ORG).includes(role))
      ];

      if (orgAccess) {
        organizationRoles.push(orgAccess);
      }

      valuesToSubmit = {
        organizationRoles: {
          [orgId]: organizationRoles
        },
        tenantRoles: {
          [orgId]: tenantRoles
        },
        groupIds: userGroupsIds
      };

      if (user.fullName !== fullName) {
        valuesToSubmit.fullName = fullName;
      }

      if (user.email !== email) {
        valuesToSubmit.email = email;
      }
    } else {
      const organizationRoles = [...appRoles];

      if (orgAccess) {
        organizationRoles.push(orgAccess);
      }

      valuesToSubmit = {
        fullName,
        email,
        organizationRoles: {
          [orgId]: organizationRoles
        },
        tenantRoles: {
          [orgId]: tenantRoles
        }
      };
    }

    let isSaveSuccessful = false;
    if (isCreateMode) {
      isSaveSuccessful = await inviteUser({ orgId, data: valuesToSubmit }).unwrap();

      if (isSaveSuccessful) {
        onBackButtonPress();
      }
    } else {
      await updateUser({ orgId, userId: user.id, data: valuesToSubmit }).unwrap();
    }
  };

  return (
    <Fragment>
      <Space wrap align="center" size={16} className="mb-4 w-100 justify-content-between">
        <Space size={12} align="center">
          <Button
            ghost
            size="large"
            icon={<LeftOutlined />}
            className="shadow border-0"
            onClick={onBackButtonPress}
          />

          <Form.Item
            noStyle
            shouldUpdate
          >
            <h3 className="mb-0">
              {!isCreateMode ? t('Edit User') :t('Invite New User')}
            </h3>
          </Form.Item>
        </Space>

        <SaveButton />
      </Space>

      <Card className="mb-4">
        {
          isFetchingUser || isLoadingBots || isLoadingUserGroups ? (
            <Spin style={{ display: 'block', margin: 'auto' }} />
          ) : (
            <Form
              form={form}
              layout="vertical"
              className="px-5"
              onFinish={onSubmit}
              initialValues={
                user ? {
                  fullName: user.fullName,
                  email: user.email,
                  orgAccess: user?.organizationRoles[orgId]?.find(role => Object.values(PERMISSIONS_ORG).includes(role)) || '',
                  appRoles: user?.organizationRoles[orgId]?.filter(role => Object.values(ADDITIONAL_ROLES_ORG).includes(role)),
                  botIds: user?.tenantRoles[orgId] ? Object.entries(user?.tenantRoles[orgId] || {}).map(([botId, role]) => botId) : [],
                  userGroupsIds: userGroups?.items?.map(group => {
                    const isUserIncluded = group.users.some(currentUser => currentUser.id === user.id);
                    if (isUserIncluded) {
                      return group.id;
                    } else {
                      return null;
                    }
                  }).filter(group => group !== null)
                } : {
                  fullName: '',
                  email: '',
                  orgAccess: '',
                  appRoles: [],
                  botIds: [],
                  userGroupsIds: []
                }
              }
            >
              <Row style={{ flexWrap: 'wrap-reverse' }}>
                <Col flex="1 1 760px">
                  <Row
                    wrap
                    gutter={16}
                    className="mb-4"
                  >
                    <Col flex="0 0 280px" className="mb-5">
                      <Typography.Paragraph className="mb-0">{t('User Details')}</Typography.Paragraph>
                      <Typography.Paragraph style={{ maxWidth: 180 }} className="fs-sm mb-0" type="secondary" strong>{t('Configure the details of the user')}</Typography.Paragraph>
                    </Col>

                    <Col flex="1 1 480px" style={{ maxWidth: 480 }} className="overflow-x-hidden">
                      <Form.Item
                        hidden={isCreateMode}
                        name="fullName"
                        label={t('User Name')}
                      >
                        <Input size="large" placeholder={t('Enter a name for the user')} disabled/>
                      </Form.Item>

                      <Form.Item
                        name="email"
                        label={t('Email')}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{
                          required: isCreateMode,
                          type: 'email',
                          whitespace: true,
                          message: t('Please enter a valid email for this user')
                        }]}
                      >
                        <Input size="large" placeholder={t('Enter an email for the user')} disabled= {!isCreateMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider />

              <Row
                wrap
                gutter={16}
                className="mb-4"
              >
                <Col flex="0 0 280px" className="mb-5">
                  <Typography.Paragraph className="mb-0">{t('Organization Level Access')}</Typography.Paragraph>
                  <Typography.Paragraph style={{ maxWidth: 180 }} className="fs-sm mb-0" type="secondary" strong>{t('Set organization access and permissions for the user')}</Typography.Paragraph>
                </Col>

                <Col flex="1 1 480px" style={{ maxWidth: 480 }} className="overflow-x-hidden">
                  <Row align="top" justify="space-between">
                    <Col flex="1 1 50%">
                      <Form.Item
                        label={t('Access Roles')}
                        tooltip={{
                          className: 'ml-2',
                          icon: <QuestionCircleTwoTone />,
                          title: t('Select the users\'s access level')
                        }}
                        name="orgAccess"
                        labelCol={{ span: 24 }}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={''}>{t('No organization access')}</Radio>
                            <Radio value={PERMISSIONS_ORG.VIEWER}>{t('Organization viewer')}</Radio>
                            <Radio value={PERMISSIONS_ORG.EDITOR}>{t('Organization editor')}</Radio>
                            <Radio value={PERMISSIONS_ORG.ADMIN}>{t('Organization admin')}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col flex="1 1 50%">
                      <Form.Item
                        name="appRoles"
                        tooltip={{
                          className: 'ml-2',
                          icon: <QuestionCircleTwoTone />,
                          title: t('Add roles that grant permission to certain applications (e.g. LiveChat )')
                        }}
                        label={<Typography.Text>{t('Application Roles')}</Typography.Text>}
                        labelCol={{ span: 24 }}
                      >
                        <Checkbox.Group>
                          <Space direction="vertical">
                            <Checkbox value={ADDITIONAL_ROLES_ORG.LIVE_AGENT}>
                              <Tag style={{ borderRadius: 4, width: 32 }} className="fs-base"><CustomerServiceFilled /></Tag>
                              {t('LiveChat Agent')}
                            </Checkbox>

                            <Checkbox value={ADDITIONAL_ROLES_ORG.LIVECHAT_ADMIN}>
                              <Tag style={{ borderRadius: 4, width: 32 }} className="fs-base"><LiveChatSettingsIcon style={{ verticalAlign: -3 }} /></Tag>
                              {t('LiveChat Admin')}
                            </Checkbox>
                          </Space>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider />

              <Row
                wrap
                gutter={16}
                className="mb-4"
              >
                <Col flex="0 0 280px" className="mb-5">
                  <Typography.Paragraph className="mb-0">{t('Bot Level Access')}</Typography.Paragraph>
                  <Typography.Paragraph style={{ maxWidth: 180 }} className="fs-sm mb-0" type="secondary" strong>{t('Set bot access and permissions for the user')}</Typography.Paragraph>
                </Col>

                <Col flex="1 1 480px" style={{ maxWidth: 480 }} className="overflow-x-hidden">
                  <Form.Item
                    label={t('Access to Bots')}
                    name="botIds"
                  >
                    <MultiDropdown
                      size="large"
                      placeholder={t('Select bots')}
                      allowClear
                      loading={isLoadingBots}
                      autoClearSearchValue={false}
                      hideExtraFromDropdown
                      onChange={(values) => {
                        let newTenantRoles = {};

                        // If a new bot has been added or edited, set it's role to the new one (or the default)
                        values.forEach((value) => {
                          newTenantRoles[value] = tenantRoles[value] || [PERMISSIONS_TENANT.VIEWER];
                        });

                        // If a bot has been removed, set it's role to null
                        Object.keys(tenantRoles).forEach((key) => {
                          if (!values.includes(key)) {
                            newTenantRoles[key] = null;
                          }
                        });

                        setTenantRoles(newTenantRoles);
                      }}
                      options={(bots?.items || []).map(bot => ({
                        label: <BotAvatar bot={bot} wrapBotName maxNameCharacters={28} className="d-inline-block w-auto" />,
                        value: bot.id,
                        searchValue: bot.displayName,
                        extra: (
                          <Select
                            value={Object.entries(tenantRoles).find(([botId, role]) => botId === bot.id)?.[1] || PERMISSIONS_TENANT.VIEWER}
                            className="ml-auto"
                            style={{ width: 100 }}
                            options={[
                              {
                                label: t('Viewer'),
                                value: PERMISSIONS_TENANT.VIEWER
                              },
                              {
                                label: t('Editor'),
                                value: PERMISSIONS_TENANT.EDITOR
                              },
                              {
                                label: t('Admin'),
                                value: PERMISSIONS_TENANT.ADMIN
                              }
                            ]}
                            onSelect={(value) => {
                              setTenantRoles({ ...tenantRoles, [bot.id]: [value] });
                            }}
                          />
                        )
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {
                !isCreateMode ? (
                  <Fragment>
                    <Divider />

                    <Row
                      wrap
                      gutter={16}
                      className="mb-4"
                    >
                      <Col flex="0 0 280px" className="mb-5">
                        <Typography.Paragraph className="mb-0">{t('Member in User Groups')}</Typography.Paragraph>
                        <Typography.Paragraph style={{ maxWidth: 180 }} className="fs-sm mb-0" type="secondary" strong>{t('Add console users to User Groups')}</Typography.Paragraph>
                      </Col>

                      <Col flex="1 1 480px" style={{ maxWidth: 480 }} className="overflow-x-hidden">
                        <Form.Item
                          label={t('User Groups')}
                          name="userGroupsIds"
                        >
                          <MultiDropdown
                            size="large"
                            placeholder={t('Select User Groups')}
                            allowClear
                            loading={isLoadingUserGroups}
                            autoClearSearchValue={false}
                            hideExtraFromDropdown
                            options={(userGroups?.items || []).map(userGroup => ({
                              label: <Typography.Text>{userGroup.name}</Typography.Text>,
                              value: userGroup.id,
                              searchValue: userGroup.name
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Fragment>
                ) : null
              }
            </Form>
          )}
      </Card>

      <SaveButton />
    </Fragment>
  );
};

export default OrganizationUserForm;
