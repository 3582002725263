import { apiSlice } from '../apiSlice';
import { MESSAGES } from './activitiesMessages';
import { ACTIVITY_TYPES, CHAPTERSOURCE_TYPES } from '../../constants/main';
import { API_TAGS } from '../../constants/api';

export const activitiesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchActivities: build.query({
      query: ({ orgId, tenantId, type = '', activityTypes = '', token, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'GET',
        extraToken: token,
        params: {
          ...urlParams,
          type: type ? type : CHAPTERSOURCE_TYPES.FLOWGRAPH,
          activityTypes: activityTypes ? activityTypes : `${ACTIVITY_TYPES.DECISION_TREE},${ACTIVITY_TYPES.INTERVIEW},${ACTIVITY_TYPES.LIVECHAT_FLOW},${ACTIVITY_TYPES.USER_FEEDBACK}`
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_ACTIVITIES.ERROR
        }
      }),
      providesTags: (result, error, _args) => {
        if (error?.error) {
          return [{ type: API_TAGS.ACTIVITIES, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ name }) => ({ type: API_TAGS.ACTIVITIES, name })),
          { type: API_TAGS.ACTIVITIES, id: 'LIST' }
        ];
      }
    }),

    fetchActivity: build.query({
      query: ({ orgId, tenantId, activityName, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${activityName}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_ACTIVITY.ERROR
        }
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}${queryArgs.orgId}${queryArgs.tenantId}${queryArgs.activityName}`;
      },
      providesTags: (_result, _error, args) => {
        return [{ type: API_TAGS.ACTIVITIES, id: args.activityName }];
      }
    }),

    createActivity: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true }, errorMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources`,
        method: 'POST',
        body: data,
        errorMessage: {
          ...errorMessage,
          messages: MESSAGES.CREATE_ACTIVITY.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_ACTIVITY.SUCCESS
        }
      }),
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    updateActivity: build.mutation({
      query: ({ orgId, tenantId, activityName, data, doNotInvalidate, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${activityName}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_ACTIVITY.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_ACTIVITY.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        if (args.doNotInvalidate) {
          return [
            { type: API_TAGS.ACTIVITIES, id: 'LIST' },
            { type: API_TAGS.BOTS, id: args.tenantId },
            { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
          ];
        }
        return [
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.ACTIVITIES, id: args.activityName },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    deleteActivity: build.mutation({
      query: ({ orgId, tenantId, activityName, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/${activityName}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_ACTIVITY.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_ACTIVITY.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    deleteActivities: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'DELETE',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_ACTIVITIES.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: data.names.length > 1 ? MESSAGES.DELETE_ACTIVITIES.SUCCESS : MESSAGES.DELETE_ACTIVITY.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    }),

    createActivities: build.mutation({
      query: ({ orgId, tenantId, data, successMessage = { showMessage: false } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/chaptersources/batch`,
        method: 'PUT',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_ACTIVITIES.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_ACTIVITIES.SUCCESS
        }
      }),
      transformResponse: () => true,
      invalidatesTags: (_result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.ACTIVITIES, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId },
          { type: API_TAGS.CHAPTER_SOURCES, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchActivitiesQuery,
  useFetchActivityQuery,
  useLazyFetchActivityQuery,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
  useDeleteActivitiesMutation,
  useCreateActivitiesMutation
} = activitiesSlice;
