import { API_REPORTS_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './analyticsMessages';
import handleBlobTypes from '../utils/handleBlobTypes';
import { API_TAGS } from '../../constants/api';

const createEndpoint = (builder, path, errorMessage) => {
  return builder.query({
    query: ({ orgId, token, urlParams = {} }) => ({
      url: `organizations/${orgId}/analytics/${path}`,
      method: 'GET',
      extraToken: token,
      params: {
        ...urlParams
      },
      errorMessage: {
        showMessage: true,
        messages: errorMessage
      }
    })
  });
};

const fetchAnalyticsEndpoints = (builder) => ({
  fetchAnalyticsSummary: createEndpoint(builder, 'summary', MESSAGES.FETCH_ANALYTICS.ERROR),
  fetchAnalyticsLiveChat: createEndpoint(builder, 'live-chat', MESSAGES.FETCH_ANALYTICS.ERROR),
  fetchAnalyticsAutomatedAnswers: createEndpoint(builder, 'automated-answers', MESSAGES.FETCH_ANALYTICS.ERROR),
  fetchAnalyticsInterviews: createEndpoint(builder, 'interviews', MESSAGES.FETCH_ANALYTICS.ERROR),
  fetchAnalyticsDecisionTrees: createEndpoint(builder, 'decision-trees', MESSAGES.FETCH_ANALYTICS.ERROR),
  fetchUserFeedback: createEndpoint(builder, 'user-feedback', MESSAGES.FETCH_ANALYTICS.ERROR)
});

const waitForAll = async (requests) => {
  const results = await Promise.all(requests);
  results.forEach((result, index) => {
    result?.data && requests[index].unsubscribe();
  });
  return results;
};

export const analyticsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ...fetchAnalyticsEndpoints(builder),
    fetchAnalytics: builder.query({
      queryFn: async ({ orgId, token, urlParams = {} }, { dispatch, endpoint }, options, fetchWithBQ) => {
        const requests = [
          dispatch(analyticsSlice.endpoints.fetchAnalyticsSummary.initiate({ orgId, token, urlParams })),
          dispatch(analyticsSlice.endpoints.fetchAnalyticsLiveChat.initiate({ orgId, token, urlParams })),
          dispatch(analyticsSlice.endpoints.fetchAnalyticsAutomatedAnswers.initiate({ orgId, token, urlParams })),
          dispatch(analyticsSlice.endpoints.fetchAnalyticsInterviews.initiate({ orgId, token, urlParams })),
          dispatch(analyticsSlice.endpoints.fetchAnalyticsDecisionTrees.initiate({ orgId, token, urlParams })),
          dispatch(analyticsSlice.endpoints.fetchUserFeedback.initiate({ orgId, token, urlParams }))
        ];

        const [summary, livechat, automatedAnswers, interviews, decisionTrees, userFeedback] = await waitForAll(requests);

        return {
          data: {
            summary: summary.data,
            livechat: livechat.data,
            automatedAnswers: automatedAnswers.data,
            interviews: interviews.data,
            decisionTrees: decisionTrees.data,
            userFeedback: userFeedback.data
          }
        };
      },
      providesTags: () => {
        return [{ type: API_TAGS.ANALYTICS, id: 'LIST' }];
      }
    }),

    exportAnalyticsReport: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organization/${orgId}/report/export`,
        method: 'GET',
        API_URL: API_REPORTS_URL,
        params: {
          ...urlParams
        },
        extraHeaders: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob',
        includeResponseHeaders: true,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_ANALYTICS_REPORT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.EXPORT_ANALYTICS_REPORT.SUCCESS
        }
      }),
      transformResponse: (response) => {
        return handleBlobTypes(response, true);
      }
    })
  })
});

export const {
  useFetchAnalyticsQuery,
  useLazyExportAnalyticsReportQuery
} = analyticsSlice;
