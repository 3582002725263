import i18n from '../../i18n';

export const MESSAGES = {
  VARIABLES: {
    ERROR: {
      500: i18n.t('An error occurred while fetching the story variables.'),
      default: i18n.t('An error occurred while fetching the story variables.')
    }
  }
};
