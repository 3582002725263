// Core imports
import { Fragment, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

// Component imports
import { Button, Col, Dropdown, Form, Input, Popconfirm, Row, Space, Table, Typography, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, DownOutlined, ApiOutlined, DisconnectOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import BulkActionsModal from '../Modals/BulkActionsModal';
import ResponsiveTagsList from '../UI/ResponsiveTagsList';
import ConnectKnowledgeBaseModal from '../Modals/ConnectKnowledgeBaseModal';
import Permissioned from '../Permissioned';

// Misc imports
import { PERMISSIONS_GROUP_EDITOR, PERMISSIONS_GROUP_VIEWER } from '../../constants/main';
import useUserPermissions from '../../utils/hooks/useUserPermissions';
import userHasEditAccess from '../../utils/misc/hasEditAccess';
import { useFetchKnowledgeBasesQuery, useUpdateKnowledgeBaseMutation, useUpdateKnowledgeBasesMutation } from '../../store/knowledgeBases/knowledgeBasesApi';
import { isEmptyArray } from '../../utils/is/isEmptyArray';
import useDebouncedCallback from '../../utils/hooks/useDebounce';

// Constant declarations
const SPACE_TYPES = {
  EX: 'EX',
  CX: 'CX'
};
const BULK_ACTIONS = {
  DISCONNECT: 'disconnect'
};
const PAGE_SIZE = 20;
const { Text, Paragraph } = Typography;

const KnowledgeBaseSourcesTable = () => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { tenantId, orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userPermissions = useUserPermissions(orgId, tenantId);

  // Local state
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);
  const [knowledgeBasesToShow, setKnowledgeBasesToShow] = useState([]);
  const [isBulkActionsKnowledgeBasesModalOpen, setIsBulkActionsKnowledgeBasesModalOpen] = useState(false);
  const [bulkAction, setBulkAction] = useState();
  const [isConnectKBModalOpen, setIsConnectKBModalOpen] = useState(false);
  const [knowledgeBaseToEdit, setKnowledgeBaseToEdit] = useState();

  // Redux state
  const { data: knowledgeBases, isFetching: isFetchingKnowledgeBases } = useFetchKnowledgeBasesQuery({ orgId, urlParams: {
    includeTenants: true,
    includeIntegration: true,
    tenantIds: [tenantId],
    page: currentPage -1,
    pageSize: PAGE_SIZE,
    ...(searchParams.get('hasIntegration') && { hasIntegration: searchParams.get('hasIntegration') }),
    ...(searchParams.get('spacesFilter') && { space: searchParams.get('spacesFilter') }),
    sortBy: searchParams.get('sortBy') || 'createdAt',
    order: searchParams.get('order') || 'desc',
    ...(searchParams.get('searchTerm') && { name: searchParams.get('searchTerm') })
  } });
  const [updateKnowledgeBase, { isLoading: isUpdatingKnowledgeBase }] = useUpdateKnowledgeBaseMutation();
  const [updateKnowledgeBases, { isLoading: isUpdatingKnowledgeBases }] = useUpdateKnowledgeBasesMutation();
  const { organizationRoles, tenantRoles } = useSelector((state) => state.auth.currentUser);
  const hasEditAccess = userHasEditAccess(orgId, organizationRoles, tenantId, tenantRoles, false);

  const onRowSelectChange = (newSelectedKnowledgeBases) => {
    setSelectedKnowledgeBases(newSelectedKnowledgeBases);
  };

  const updateUrlSearchParams = (current = 1, sorting = { createdAt: 'desc' }, filters) => {
    setCurrentPage(current);
    const urlSearchParams = new URLSearchParams(location.search);

    const searchTerm = form.getFieldValue('searchTerm');
    urlSearchParams.set('page', current - 1);
    urlSearchParams.set('pageSize', PAGE_SIZE);

    if (sorting) {
      urlSearchParams.set('sortBy', Object.entries(sorting).flat()[0]);
      urlSearchParams.set('order', Object.entries(sorting).flat()[1]);
    } else {
      urlSearchParams.delete('sortBy');
      urlSearchParams.delete('order');
    }

    if (filters?.method) {
      urlSearchParams.set('hasIntegration', encodeURIComponent(filters.method[0]));
    } else {
      urlSearchParams.delete('hasIntegration');
    }

    if (filters?.space) {
      urlSearchParams.set('spacesFilter', encodeURIComponent(filters.space[0]));
    } else {
      urlSearchParams.delete('spacesFilter');
    }

    if (searchTerm) {
      urlSearchParams.set('searchTerm', encodeURIComponent(searchTerm));
    } else {
      urlSearchParams.set('searchTerm', encodeURIComponent(''));
    }

    // Set the filters to the url
    navigate({
      pathname: location.pathname,
      search: urlSearchParams.toString()
    }, { replace: true });
  };

  const onKnowledgeBaseDisconnect = async (record) => {
    const valuesToSubmit = record.tenantAssociations?.filter((association) => association.tenantId !== tenantId);
    await updateKnowledgeBase({ orgId: orgId, id: record.id, data: { tenantAssociationRefs: valuesToSubmit } });
  };

  const handleBulkActionsMenuClick = (e) => {
    setBulkAction(e.key);
    openBulkActionsKnowledgeBasesModal();
  };

  const openBulkActionsKnowledgeBasesModal = () => {
    setKnowledgeBasesToShow(selectedKnowledgeBases.length > 5 ? 5 : selectedKnowledgeBases.length);
    setIsBulkActionsKnowledgeBasesModalOpen(true);
  };

  const closeBulkActionsKnowledgeBasesModal =(props) => {
    setIsBulkActionsKnowledgeBasesModalOpen(false);
  };

  const onBulkActionsKnowledgeBasesModalShowMore = () => {
    setKnowledgeBasesToShow(knowledgeBasesToShow + 5);
  };

  const handleBulkActionsKnowledgeBases = async () => {
    const knowledgeBasesToDisconnect = knowledgeBases.items.filter((kb) => selectedKnowledgeBases.includes(kb.name));

    const valuesToSubmit = knowledgeBasesToDisconnect.map(item => {
      const { tenantAssociations } = item;
      return {
        id: item.id,
        tenantAssociationRefs: tenantAssociations.map(association => association.tenantId !== tenantId ? association.tenantId : null).filter(tenantId => tenantId !== null)
      };
    });

    const isDisconnectSuccessful = await updateKnowledgeBases({ orgId: orgId, data: valuesToSubmit }).unwrap();

    if (isDisconnectSuccessful) {
      setSelectedKnowledgeBases([]);
      setIsBulkActionsKnowledgeBasesModalOpen(false);
    }
  };

  const bulkActionsKnowledgeBasesModalData = () => {
    const knowledgeBasesToDisconnect = selectedKnowledgeBases?.map((selectedKnowledgeBase) => {
      return knowledgeBases.items.find(item => item.name === selectedKnowledgeBase);
    });
    return knowledgeBasesToDisconnect;
  };

  const onConnectKBModalClose = () => {
    setIsConnectKBModalOpen(false);
    setKnowledgeBaseToEdit(null);
  };

  const debouncedOnChange = useDebouncedCallback(updateUrlSearchParams);

  const handleTableChange = (pagination, filters, sorter) => {
    let sorting;
    if (sorter.column) {
      sorting = { [sorter.columnKey]: sorter.order === 'ascend' ? 'asc' : 'desc' };
    } else {
      if (sorter.field === 'createdAt' && searchParams.get('order') === 'desc') {
        sorting = { [sorter.columnKey]: 'asc' };
      }
    }
    updateUrlSearchParams(pagination.current, sorting, filters);
  };

  const sortingInfo = useMemo(() => {
    let order = searchParams.get('order') ? (searchParams.get('order') === 'desc' ? 'descend' : 'ascend') : 'descend';
    let columnKey = searchParams.get('sortBy') ? searchParams.get('sortBy') : 'createdAt';

    return {
      order,
      columnKey
    };
  }, [searchParams.get('order'), searchParams.get('sortBy')]);

  const columns = [
    {
      title: t('KB Name (Sources)'),
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortingInfo.columnKey === 'name' && sortingInfo.order,
      render: record => {
        return record ? (
          <Space>
            <Highlighter
              highlightClassName="bg-highlight p-0"
              searchWords={[searchParams.get('searchTerm')]}
              autoEscape
              textToHighlight={record.name}
            />
          </Space>
        ) : null;
      }
    },
    {
      title: t('Import Method'),
      key: 'method',
      filters: [{
        text: 'Integration',
        value: true
      }, {
        text: 'Internal',
        value: false
      }],
      filterMultiple: false,
      render: record => {
        return record?.integration ? t('Integration') : t('Internal');
      }
    },
    {
      title: t('Related Space'),
      key: 'space',
      filters: Object.values(SPACE_TYPES).map((type) => {
        return {
          text: type,
          value: type
        };
      }),
      filterMultiple: false,
      render: record => {
        if (!record.spaces || isEmptyArray(record.spaces)) {
          return '-';
        }

        return (
          <ResponsiveTagsList
            values={record.spaces.map((space, index) => ({ label: space.toUpperCase(), value: index }))}
            mode="tags"
            style={{ width: '100%' }}
            tagRender={(tag) => <span className="ant-select-selection-item p-0 px-1 mr-2">{tag.label}</span>}
          />
        );
      }
    },
    {
      title: (
        <Space>
          {t('Default Bot Responses')}

          <Tooltip title={(
            <Fragment>
              <Paragraph>{t('The Bot will reply with AI generated text or with the whole Article body by default.')}</Paragraph>
              <Text>{t('You can change your preference in each Automated Answer separately.')}</Text>
            </Fragment>
          )}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      key: 'useGenerative',
      render: record => {
        const { tenantAssociations } = record || {};
        const { useGenerative } = (tenantAssociations || []).find((association) => association.tenantId === tenantId) || {};

        if (useGenerative === true) {
          return t('AI generated text');
        } else if (useGenerative === false) {
          return t('Article body');
        } else {
          return '-';
        }
      }
    },
    {
      title: t('Actions'),
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 100,
      hidden: !userPermissions.some(permission => PERMISSIONS_GROUP_VIEWER.includes(permission)),
      render: record => {
        return (
          <Space>
            <Tooltip title={t('Edit connection')}>
              <Button
                shape="circle"
                type="text"
                icon={<EditOutlined />}
                loading={isFetchingKnowledgeBases || isUpdatingKnowledgeBase || isUpdatingKnowledgeBases}
                onClick={() => {
                  setKnowledgeBaseToEdit(record);
                  setIsConnectKBModalOpen(true);
                }}
              />
            </Tooltip>

            <Popconfirm
              title={t('Disconnect Knowledge Base & Delete from Bot\'s KB sources list?')}
              okText={t('Yes')}
              cancelText={t('No')}
              placement="topRight"
              onConfirm={() => {
                onKnowledgeBaseDisconnect(record);
              }}
            >
              <Tooltip title={t('Unlink')}>
                <Button
                  shape="circle"
                  type="text"
                  icon={<DisconnectOutlined />}
                  loading={isFetchingKnowledgeBases || isUpdatingKnowledgeBase || isUpdatingKnowledgeBases}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedKnowledgeBases,
    onChange: onRowSelectChange,
    selections: [
      {
        key: 'all',
        text: t('All'),
        onSelect: () => onDropdownSelect('all')
      },
      {
        key: 'none',
        text: t('None'),
        onSelect: () => onDropdownSelect('none')
      }
    ]
  };

  const onDropdownSelect = (key) => {
    let newSelectedKnowledgeBases = [];
    if (key === 'all') {
      newSelectedKnowledgeBases = knowledgeBases?.items?.map((item) => item.name);
    }
    setSelectedKnowledgeBases(newSelectedKnowledgeBases);
  };

  return (
    <Fragment>
      <Row gutter={24} className="mb-2">
        <Col flex="auto">
          <Space className="flex-wrap">
            <Form
              name="knowledgeBasesFilterForm"
              className="align-items-center"
              initialValues={{
                searchTerm: searchParams.get('searchTerm') ? decodeURIComponent(searchParams.get('searchTerm')) : ''
              }}
              form={form}
            >
              <Form.Item
                name="searchTerm"
                className="mb-2"
              >
                <Input
                  size="large"
                  prefix={
                    <SearchOutlined
                      className="text-secondary"
                    />
                  }
                  placeholder={t('Search in contents')}
                  allowClear
                  onChange={(e) => {
                    debouncedOnChange();
                    form.setFieldsValue({ search: e.target.value });
                  }}
                />
              </Form.Item>
            </Form>
          </Space>
        </Col>

        <Col>
          <Space>
            <Permissioned
              allowedPermissions={PERMISSIONS_GROUP_EDITOR}
              currentPermissions={userPermissions}
              fallbackComponent={null}
            >
              <Dropdown
                trigger={['click']}
                menu={{
                  onClick: handleBulkActionsMenuClick,
                  items: [
                    {
                      label: t('Disconnect Selected'),
                      key: BULK_ACTIONS.DISCONNECT
                    }
                  ]
                }}
                disabled={!selectedKnowledgeBases.length > 0}
              >
                <Button
                  block
                  size="large"
                >
                  {t('Bulk Actions')}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Permissioned>

            <Permissioned
              allowedPermissions={PERMISSIONS_GROUP_EDITOR}
              currentPermissions={userPermissions}
              fallbackComponent={null}
            >
              <Button
                icon={<ApiOutlined />}
                type="primary"
                className="float-right"
                size="large"
                onClick={() => setIsConnectKBModalOpen(true)}
                loading={isFetchingKnowledgeBases || isUpdatingKnowledgeBase || isUpdatingKnowledgeBases}
              >
                {t('Connect New KB')}
              </Button>
            </Permissioned>
          </Space>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24}>
          <Table
            rowSelection={hasEditAccess ? rowSelection : null}
            size="small"
            rowKey="name"
            columns={columns}
            dataSource={knowledgeBases?.items}
            loading={isFetchingKnowledgeBases}
            onChange={handleTableChange}
            pagination={{
              total: knowledgeBases?.total || 0,
              defaultCurrent: 1,
              current: currentPage,
              pageSize: knowledgeBases?.pageSize || 0,
              showSizeChanger: false,
              size: 'default'
            }}
          />
        </Col>
      </Row>

      <BulkActionsModal
        title={`${selectedKnowledgeBases.length === 1 ? 'knowledge base' : 'knowledge bases'}`}
        action={bulkAction}
        isModalOpen={isBulkActionsKnowledgeBasesModalOpen}
        data={bulkActionsKnowledgeBasesModalData()}
        onModalClose={closeBulkActionsKnowledgeBasesModal}
        onModalConfirm={handleBulkActionsKnowledgeBases}
        loader={isFetchingKnowledgeBases || isUpdatingKnowledgeBase || isUpdatingKnowledgeBases}
        onShowMore={onBulkActionsKnowledgeBasesModalShowMore}
        entityCount={knowledgeBasesToShow}
        displayProperty="name"
      />

      <ConnectKnowledgeBaseModal isModalOpen={isConnectKBModalOpen} closeModal={onConnectKBModalClose} selectedKnowledgeBase={knowledgeBaseToEdit} />
    </Fragment>
  );
};

export default KnowledgeBaseSourcesTable;

