/**
 * Converts a string to [capitalized](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 * Combines words by capitalizing all words following the first word and removing spaces.
 *
 * @param {String} subjectString The string to convert.
 * @throws {TypeError} Throws if `subjectString` is not string.
 * @return {String} Returns the camel cased string.
 * @example
 *
 * capitalize('quick');
 * // -> 'Quick'
 *
 * capitalize('BROWN');
 * // -> 'Brown'
 *

 */
export const capitalize = (str=null) => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string for first argument');
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
