import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './auditLogsMessages';

export const auditLogsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAuditLogs: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/audit-logs`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_AUDIT_LOGS.ERROR
        }
      }),
      providesTags: () => {
        return [{ type: API_TAGS.AUDIT_LOGS, id: 'LIST' }];
      }
    })
  })
});

export const {
  useFetchAuditLogsQuery
} = auditLogsSlice;
