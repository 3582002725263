import { apiSlice } from '../apiSlice';

export const apiTokensSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createApiToken: build.mutation({
      query: ({ orgId, data }) => ({
        url: `organizations/${orgId}/api-tokens`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useCreateApiTokenMutation
} = apiTokensSlice;
