/**
 * Converts a string representation of a boolean value to a JavaScript boolean.
 * Accepts 'true', 'false', '0', and '1'.
 * Returns false for any other values.
 *
 * @param {String} str The string to convert.
 * @throws {TypeError} Throws if `str` is not a string.
 * @return {Boolean} Returns the converted boolean.
 * @example
 *
 * stringToBoolean('true');
 * // -> true
 *
 * stringToBoolean('false');
 * // -> false
 *
 * stringToBoolean('0');
 * // -> false
 *
 * stringToBoolean('1');
 * // -> true
 *
 * stringToBoolean('any other string');
 * // -> false
 */
export const stringToBoolean = (str) => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string for first argument');
  }

  switch (str.toLowerCase()) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
      return false;
    default:
      return false;
  }
};

