/**
 * Group entities by category
 * @param {Array} entities
 * @returns {Object} An object with keys as categories and values as arrays of entities
 */
const generateGroupedEntities = (entities) => {
  let groupedEntities = {};

  // Group entities by category
  entities.forEach(entity => {
    let categories = entity.categories?.length > 0 ? entity.categories : ['Uncategorized'];

    categories.forEach(category => {
      if (!groupedEntities[category]) {
        groupedEntities[category] = [];
      }

      groupedEntities[category].push(entity);
    });
  });

  // Sort keys alphabetically, with 'Uncategorized' always last
  const orderedGroupedEntities = {};

  Object.keys(groupedEntities)
    .sort((a, b) => {
      if (a === 'Uncategorized') {
        return 1;
      }
      if (b === 'Uncategorized') {
        return -1;
      }
      return a.localeCompare(b);
    })
    .forEach(key => {
      orderedGroupedEntities[key] = groupedEntities[key];
    });

  return orderedGroupedEntities;
};

export default generateGroupedEntities;
