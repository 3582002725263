import { API_TAGS } from '../../constants/api';
import { API_MEDIA_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './mediaMessages';

export const mediaSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchMediaFiles: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organization/${orgId}/media`,
        method: 'GET',
        API_URL: API_MEDIA_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_MEDIA_FILES.ERROR
        }
      }),

      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}${queryArgs.orgId}`;
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.MEDIA_FILES, id: 'LIST' }
        ];
      }
    }),

    createMediaFile: builder.mutation({
      query: ({ orgId, data, urlParams = {} }) => ({
        url: `organization/${orgId}/media`,
        method: 'POST',
        API_URL: API_MEDIA_URL,
        body: data,
        stringifyBody: false,
        params: {
          ...urlParams
        },
        extraHeaders: {
          'Accept': 'multipart/form-data'
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_MEDIA_FILE.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_MEDIA_FILE.SUCCESS
        }
      }),
      transformErrorResponse: (response) => false,
      transformResponse: (response) => response,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.MEDIA_FILES, id: 'LIST' }
        ];
      }
    }),

    deleteMediaFile: builder.mutation({
      query: ({ orgId, id, urlParams = {} }) => ({
        url: `organization/${orgId}/media/${id}`,
        method: 'DELETE',
        API_URL: API_MEDIA_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_MEDIA_FILE.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_MEDIA_FILE.SUCCESS
        }
      }),
      transformErrorResponse: (response) => false,
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.MEDIA_FILES, id: 'LIST' }
        ];
      }
    }),

    deleteMediaFiles: builder.mutation({
      query: ({ orgId, ids, urlParams = {} }) => ({
        url: `organization/${orgId}/media/batch`,
        method: 'DELETE',
        API_URL: API_MEDIA_URL,
        params: {
          ...urlParams
        },
        body: ids,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_MEDIA_FILES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_MEDIA_FILES.SUCCESS
        }
      }),
      transformErrorResponse: (response) => false,
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        return [
          { type: API_TAGS.MEDIA_FILES, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useFetchMediaFilesQuery,
  useCreateMediaFileMutation,
  useDeleteMediaFileMutation,
  useDeleteMediaFilesMutation
} = mediaSlice;
