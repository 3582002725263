import { apiSlice } from '../apiSlice';
import { MESSAGES } from './surveysMessages';
import handleBlobTypes from '../utils/handleBlobTypes';
import { API_TAGS } from '../../constants/api';

export const surveysSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchSurveys: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/surveys`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_SURVEYS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.SURVEYS, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ id }) => ({ type: API_TAGS.SURVEYS, id })),
          { type: API_TAGS.SURVEYS, id: 'LIST' }
        ];
      }
    }),
    downloadSurveys: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/surveys/export`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DOWNLOAD_SURVEYS.ERROR
        },
        responseType: 'blob',
        includeResponseHeaders: true
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.SURVEYS, id: 'LIST' }
        ];
      },
      transformResponse: (response) => {
        return handleBlobTypes(response);
      }
    })
  })
});

export const { useFetchSurveysQuery, useLazyDownloadSurveysQuery } = surveysSlice;
