import i18n from '../../i18n';

export const MESSAGES = {
  FORGOT_PASSWORD: {
    SUCCESS: {
      200: i18n.t('Please check your email.')
    },
    ERROR: {
      default: i18n.t('An error ocurred while sending the request.')
    }
  },

  RESET_PASSWORD: {
    ERROR: {
      default: i18n.t('An error ocurred resetting your password.')
    }
  }
};
