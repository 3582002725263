import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './dashboardMessages';

export const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchDashboard: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants-stats`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_DASHBOARD.ERROR,
          errorCodesToIgnore: [401, 403]
        }
      }),
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.DASHBOARD, id: 'LIST' }
        ];
      }
    })
  })
});

export const { useFetchDashboardQuery } = dashboardSlice;
