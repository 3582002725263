// Core imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as microsoftTeams from '@microsoft/teams-js';

// Component imports
import { Card, Col, Row, Spin } from 'antd';
import GlobalFooter from '../components/Layout/GlobalFooter';
import LoginScreen from './LoginScreen';
import HRWIZLogo from '../assets/hrwiz-logo.svg';
import AppLogo from '../assets/logo.svg';

// Misc imports
import { NAV_DASHBOARD, NAV_LIVECHAT } from '../constants/navigation';
import { AUTHENTICATION_METHODS, STORAGE_KEYS, TEAMS_APPLICATIONS } from '../constants/main';
import replaceUrlParams from '../utils/string/replaceUrlParams';
import { useAuthenticateUserMutation } from '../store/auth/authApi';
import { IS_HRWIZ_CONSOLE_ENABLED } from '../constants/env';
import { teamsAppLogin } from '../utils/auth/teamsAppLogin';

// Constant declarations
const baseClassName = 'teams-login-screen';

const TeamsLoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);

  const { currentUser, organizationId } = useSelector((state) => state.auth);
  const isAuthenticated = !!currentUser;
  const [loginUser] = useAuthenticateUserMutation();
  /*
  * If the MSTeams SSO fails we should provide
  * the users the option to sign in manually
  */
  const [teamsSsoFailure, setTeamsSsoFailure] = useState(false);

  // On mount
  useEffect(() => {
    // In case there is no app parameter (e.g. due to a logout),
    // we can use the value stored in the local storage
    if (!searchParams.get('app')) {
      searchParams.set('app', window.localStorage.getItem(STORAGE_KEYS.TEAMS_APPLICATION) || TEAMS_APPLICATIONS.LIVECHAT);
      navigate({
        search: searchParams.toString()
      }, { replace: true });
    }
    if (!isAuthenticated) {
      teamsLogin(AUTHENTICATION_METHODS.TEAMS);
    }
  }, []);

  /*
  * If the user is already signed in redirect them
  */
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    // Redirect to the correct app
    if (IS_HRWIZ_CONSOLE_ENABLED) {
      navigate(replaceUrlParams(NAV_DASHBOARD, { orgId: organizationId }));
    } else if (searchParams.get('app') === TEAMS_APPLICATIONS.LIVECHAT) {
      navigate(replaceUrlParams(NAV_LIVECHAT, { orgId: organizationId }));
    }
  }, [isAuthenticated]);

  /*
  * Teams SDK initialization and SSO
  */
  const teamsLogin = async () => {
    try {
      await microsoftTeams.app.initialize();
      if (!microsoftTeams.app.isInitialized()) {
        setTeamsSsoFailure(true);
      }

      const token = await microsoftTeams.authentication.getAuthToken();

      if (token) {
        onSuccessfulLogin(token);
      } else {
        setTeamsSsoFailure(true);
      }
    } catch (e) {
      setTeamsSsoFailure(true);
      console.error(e);
    }
  };

  /*
  * Callback function called on successful Teams login
  */
  const onSuccessfulLogin = async (values) => {
    let loginValues = { token: values };
    const loginResponse = await loginUser({ authenticationMethod: AUTHENTICATION_METHODS.TEAMS, data: loginValues }).unwrap();
    // If there is no user object return
    if (!loginResponse?.user) {
      return setTeamsSsoFailure(true);
    }

    return teamsAppLogin(loginResponse, navigate, location, dispatch);
  };

  if (teamsSsoFailure) {
    return(
      <div className="mx-auto" style={{ maxWidth: '75%' }}>
        <LoginScreen />
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <Row justify="center">
        <img
          src={searchParams.get('app') === TEAMS_APPLICATIONS.HRWIZ ? HRWIZLogo : AppLogo}
          alt="logo"
          width={200}
          className="pt-10 my-10"
        />
      </Row>
      <Row justify="center">
        <Col xs={24} sm={20} md={12} lg={12} xl={7}>
          <Card
            title={(
              <Row justify="center">
                {t('Please wait while we are logging you in')}...
              </Row>
            )}
            bordered={false}
          >
            <Row justify="center">
              <Spin />
            </Row>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <GlobalFooter/>
        </Col>
      </Row>
    </div>
  );
};

export default TeamsLoginScreen;
