
// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import { Divider, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const BotDeploymentFormsSectionHeader = ({
  title
}) => {
  return (
    <Divider className="fs-base section-divider" orientation="left">
      <Typography.Text strong>
        <span className="title">
          {
            title
          }
        </span>
        <span className="ml-3 icon">
          <DownOutlined className="fs-base"/>
        </span>
      </Typography.Text>
    </Divider>
  );
};

BotDeploymentFormsSectionHeader.propTypes = {
  title: PropTypes.string
};

export default BotDeploymentFormsSectionHeader;
