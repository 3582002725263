import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './groupsMessages';

export const groupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchGroups: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/groups`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_GROUPS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.GROUPS, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ id }) => ({ type: API_TAGS.GROUPS, id })),
          { type: API_TAGS.GROUPS, id: 'LIST' }
        ];
      }
    }),
    deleteGroup: builder.mutation({
      query: ({ orgId, tenantId, groupId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/groups/${groupId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.GROUPS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),
    updateGroup: builder.mutation({
      query: ({ orgId, tenantId, groupId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/groups/${groupId}`,
        method: 'PATCH',
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.GROUPS, id: args.groupId },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),
    createGroup: builder.mutation({
      query: ({ orgId, tenantId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/groups`,
        method: 'POST',
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_GROUP.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_GROUP.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.GROUPS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    })
  })
});

export const {
  useFetchGroupsQuery,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useCreateGroupMutation
} = groupsSlice;

