
// Core imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component Imports
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty, Row, Spin, Typography } from 'antd';
import { ExternalLinkIcon } from '../Icons/ExternalLinkIcon';

// Misc imports
import { useLazyFetchCrmIntegrationsSubscriberQuery } from '../../store/integrations/integrationsApi';
import { useLazyFetchBotQuery } from '../../store/bots/botsApi';
import { Fragment } from 'react';
import { t } from 'i18next';

const CrmIntegrationsPanel = ({ item }) => {
  const { orgId } = useParams();
  const [contactProperties, setContactProperties] = useState(null);
  const [contactLinkInfo, setContactLinkInfo] = useState(null);
  const [fetchCrmIntegrationSubscriber] = useLazyFetchCrmIntegrationsSubscriberQuery();
  const [fetchBot] = useLazyFetchBotQuery();

  useEffect(() => {
    return () => {
      setContactProperties(null);
      setContactLinkInfo(null);
    };
  }, []);

  useEffect(() => {
    if (!item) {
      return;
    }

    const getContactLinkInfo = async (entityProperties) => {
      try {
        // Get integrations from bot to extract link template (if exists) as well as implementation type
        const { integrations } = await fetchBot({ orgId, tenantId: item.tenantId }).unwrap();
        const { crm } = integrations;
        if (crm?.type && crm?.contactLinkTemplate) {
          let link = null;
          let friendlyName = null;
          let setInfo = true;
          switch (crm.type) {
            case 'MICROSOFT_DYNAMICS_CRM': {
              const contactIdProperty = entityProperties?.find(entityProperty => entityProperty.dataPropertyPath === 'contactid');
              link = crm?.contactLinkTemplate?.replace('{contactId}', contactIdProperty?.value);
              friendlyName = 'Microsoft Dynamics';
              break;
            }
            default: {
              setInfo = false;
            }
          }
          if (setInfo) {
            setContactLinkInfo({ type: friendlyName, link });
          }
        } else {
          setContactLinkInfo({});
        }
      } catch (ignore) {
        setContactLinkInfo({});
      }
    };

    const getContactInfo = async () => {
      try {
        // Contact properties is an array of the contact's entity properties
        const { data } = await fetchCrmIntegrationSubscriber({ orgId, tenantId: item.tenantId, handle: item.platformUserId }).unwrap();
        setContactProperties(data.entityProperties);

        // No need to continue if previous request failed
        getContactLinkInfo(data.entityProperties);
      } catch (ignore) {
        setContactProperties([]);
        setContactLinkInfo({});
      }
    };

    getContactInfo();

    return () => {
      setContactProperties(null);
    };
  }, [item?.id]);

  const renderLink = () => {
    if (contactLinkInfo?.type && contactLinkInfo?.link) {
      return (
        <a href={contactLinkInfo?.link} rel="noreferrer" target="_blank" className="fw-bold fs-sm">
          {'View in ' + contactLinkInfo?.type}
          <ExternalLinkIcon className="ml-1" />
        </a>
      );
    }
  };

  const renderCrmInfo = () => {
    if (contactProperties === null || contactLinkInfo == null) {
      return (
        <Row
          align="middle"
          justify="center"
          className="p-3 text-center"
        >
          <Spin />
        </Row>
      );
    }

    if (Array.isArray(contactProperties) && contactProperties.length === 0) {
      return (
        <Empty className="text-muted fs-sm fw-bold" description={t('No CRM data found')}/>
      );
    }
    return (
      <Fragment
        key={'contactInfo'}
      >
        {
          contactProperties.filter(field => field.displayName).map((field, index) => {
            if (field.type === 'LOOKUP') {
              const { lookupEntityProperties } = field;
              return (
                <Collapse
                  ghost
                  key={index}
                  className="ant-collapse-p-0"
                >
                  <Collapse.Panel
                    key={index}
                    className="mb-1"
                    showArrow={false}
                    header={
                      <Row
                        wrap={false}
                        align="middle"
                      >
                        <Typography.Text
                          style={{ minWidth: 88 }}
                          className="fw-bold fs-sm text-muted"
                        >
                          {field.displayName}:
                        </Typography.Text>
                        <Button
                          className="fs-sm bg-layout"
                          type="link"
                          size="small"
                          style={{ wordBreak: 'break-all' }}
                        >
                          <Typography.Text
                            ellipsis
                            style={{ color: 'inherit', maxWidth: '22ch' }}
                          >
                            {field.displayName}
                          </Typography.Text>
                          <EllipsisOutlined className="fs-lg" style={{ verticalAlign: -3 }} />
                        </Button>
                      </Row>
                    }
                  >
                    {
                      lookupEntityProperties?.map(nestedField => (
                        <Row
                          wrap={false}
                          align="middle"
                          className="pl-3 mb-2"
                        >
                          <Typography.Text
                            className="fw-bold fs-sm text-muted"
                            style={{ minWidth: 76 }}
                          >
                            {nestedField.displayName}:
                          </Typography.Text>
                          <Typography.Text
                            className="fs-sm"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {nestedField.value || '-'}
                          </Typography.Text>
                        </Row>
                      ))
                    }
                  </Collapse.Panel>
                </Collapse>
              );
            }
            return (
              <Row
                wrap={false}
                align="middle"
                className="mb-2"
              >
                <Typography.Text
                  style={{ minWidth: 88 }}
                  className="fw-bold fs-sm text-muted"
                >
                  {field.displayName}:
                </Typography.Text>
                <Typography.Text
                  className="fs-sm"
                  ellipsis
                >
                  {field.value || '-'}
                </Typography.Text>
              </Row>
            );
          })
        }
        {renderLink()}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div
        className="pb-4 px-6 overflow-y-auto overflow-x-hidden"
      >
        {renderCrmInfo()}
      </div>
    </Fragment>
  );
};

CrmIntegrationsPanel.propTypes = {
  item: PropTypes.object
};

export default CrmIntegrationsPanel;
