import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './organizationSubscribersMessages';

export const organizationSubscribersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrganizationSubscribers: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/subscribers`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_ORGANIZATION_SUBSCRIBERS.ERROR
        }
      }),
      providesTags: (result, error, orgId) => {
        return [
          { type: API_TAGS.ORGANIZATION_SUBSCRIBERS, id: orgId }
        ];
      }
    })
  })
});

export const {
  useFetchOrganizationSubscribersQuery
} = organizationSubscribersSlice;
