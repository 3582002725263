import { AUTH_ERROR_CODES } from '../../constants/errors';
import i18n from '../../i18n';

export const MESSAGES = {
  REGISTER_USER: {
    ERROR: {
      ...AUTH_ERROR_CODES,
      default: i18n.t('An error occurred while registering.')
    }
  }
};
