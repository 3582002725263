import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_CONVERSATION_NOTES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch conversation notes.'),
      403: i18n.t('You are not allowed to fetch conversation notes.'),
      404: i18n.t('The conversation notes you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching your conversation notes.'),
      default: i18n.t('An error occurred fetching your conversation notes.')
    }
  },
  UPDATE_CONVERSATION_NOTES: {
    ERROR: {
      401: i18n.t('You are not authorized to update conversation notes.'),
      403: i18n.t('You are not allowed to update conversation notes.'),
      404: i18n.t('The conversation notes you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating your conversation notes.'),
      default: i18n.t('An error occurred updating your conversation notes.')
    },
    SUCCESS: {
      200: i18n.t('Conversation notes updated successfully!')
    }
  },
  CREATE_CONVERSATION_NOTES: {
    ERROR: {
      401: i18n.t('You are not authorized to create conversation notes.'),
      403: i18n.t('You are not allowed to create conversation notes.'),
      404: i18n.t('The conversation notes you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating your conversation notes.'),
      default: i18n.t('An error occurred creating your conversation notes.')
    },
    SUCCESS: {
      200: i18n.t('Conversation notes created successfully!')
    }
  },
  DELETE_CONVERSATION_NOTES: {
    ERROR: {
      401: i18n.t('You are not authorized to delete conversation notes.'),
      403: i18n.t('You are not allowed to delete conversation notes.'),
      404: i18n.t('The conversation notes you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting your conversation notes.'),
      default: i18n.t('An error occurred deleting your conversation notes.')
    },
    SUCCESS: {
      200: i18n.t('Conversation notes deleted successfully!')
    }
  }
};
