// Core imports
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Component imports
import { Button, Space, Form, Col, Modal, Row, Typography, Input, Select, Divider, Tree, Card, Image, Spin, Empty, Grid, Checkbox } from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { CreateBotIcon } from '../Icons/CreateBotIcons';
import AvatarFormFieldSelection from '../UI/AvatarFormFieldSelection';
import ResponsiveTagsList from '../UI/ResponsiveTagsList';

// Misc imports
import { BOT_DEPLOYMENTS_CHANNELS, BOT_TEMPLATE_CATEGORIES, LANGUAGE_OPTIONS } from '../../constants/main';
import { useLazyFetchBotTemplatesQuery } from '../../store/botTemplates/botTemplatesApi';
import { useCreateBotMutation } from '../../store/bots/botsApi';
import { getCurrentQueryState } from '../../store/utils/getCurrentQueryState';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { NAV_BOTS } from '../../constants/navigation';

import AppLogo from '../../assets/logo.svg';

// Constant declarations
const { useBreakpoint } = Grid;
const { Text, Paragraph } = Typography;
const { Meta } = Card;
const { TextArea } = Input;

const CreateBotModal = ({
  isModalOpen,
  closeModal
}) => {
  const { orgId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const isMobileSize = (screens.xs || screens.sm || screens.md) && !screens.lg;

  const { isLoading: isFetchingMediaFiles } = getCurrentQueryState('fetchMediaFiles', { orgId });
  const [fetchTemplates, { data: botTemplates, isFetching: isLoadingBotTemplates }] = useLazyFetchBotTemplatesQuery({ orgId });

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [createBot, { isLoading: isCreatingBot }] = useCreateBotMutation();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableUseCases, setAvailableUseCases] = useState([]);
  const [, updateState] = useState();

  useEffect(() => {
    if (isModalOpen) {
      fetchTemplates({ orgId });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (botTemplates) {
      setAvailableChannels(
        [...new Set(botTemplates.items.flatMap(item => {
          return item.platformCompatibility || [];
        }))]
      );

      setAvailableLanguages(
        [...new Set(botTemplates.items.flatMap(item => {
          return item.availableLanguages || [];
        }))]
      );

      setAvailableUseCases(
        [...new Set(botTemplates.items.flatMap(item => {
          return item.useCase || [];
        }))]
      );

      setFilteredTemplates(botTemplates.items.filter((template) => !template.blank));
    }
  }, [botTemplates]);

  useEffect(() => {
    if (botTemplates && form) {
      const originalTemplates = botTemplates.items.filter((template) => !template.blank);
      const filters = {
        channelFilters: form.getFieldValue('channelFilters')?.filter(channel => channel !== 'channel') || [],
        languageFilters: form.getFieldValue('languageFilters')?.filter(language => language !== 'language') || [],
        useCaseFilters: form.getFieldValue('useCaseFilters')?.filter(useCase => useCase !== 'useCases') || []
      };

      const filteredTemplates = originalTemplates.filter(template => {
        return Object.entries(filters).every(([filterName, filterValues]) => {
          if (filterValues.length === 0) {
            return true;
          }
          if (filterName === 'channelFilters') {
            return template.platformCompatibility?.some(value => filterValues.includes(value));
          }
          if (filterName === 'languageFilters') {
            return template.availableLanguages?.some(value => filterValues.includes(value));
          }
          if (filterName === 'useCaseFilters') {
            return filterValues.includes(template.useCase);
          }
          return true;
        });
      });

      setFilteredTemplates(filteredTemplates);
    }
  }, [form.getFieldsValue(true)]);

  const clearFilters = () => {
    form.resetFields(['channelFilters', 'languageFilters']);
    if (botTemplates) {
      setFilteredTemplates(botTemplates.items.filter((template) => !template.blank));
    }
  };

  const onSubmit = async () => {
    let valuesToSubmit = {
      displayName: form.getFieldValue('displayName'),
      primaryLanguage: form.getFieldValue('language'),
      description: form.getFieldValue('description'),
      image: form.getFieldValue('image')
    };

    let templateId;
    if (!selectedTemplate) {
      templateId = botTemplates.items.find((template) => template.blank).id;
    } else {
      templateId = selectedTemplate;
    }

    const createdResourceId = await createBot({ orgId: orgId, data: valuesToSubmit, urlParams: { templateId: templateId } }).unwrap();
    if (createdResourceId) {
      // Hide the modal after form submission
      navigate(`${replaceUrlParams(NAV_BOTS, { orgId })}/${createdResourceId}`);
      closeModal();
      setCurrentStep(0);
      form.resetFields();
    }
  };

  const selectTemplate = (id) => {
    setSelectedTemplate((prevSelectedTemplate) => {
      if (prevSelectedTemplate === id) {
        return null;
      } else {
        return id;
      }
    });
  };

  const handleCheckboxChange = (checkedValues) => {
    form.setFieldValue('useCaseFilters', checkedValues);
    updateState(checkedValues);
  };

  const steps = useMemo(() => [
    {
      title: t('NAME'),
      content: (
        <Row className="p-8 w-100" gutter={[16, 40]}>
          <Col span={24}>
            <Form.Item
              name="displayName"
              label={t('Bot Name')}
              required
              validateTrigger={['onChange', 'onBlur']}
              rules={[{
                required: true,
                type: 'string',
                whitespace: true,
                message: t('Please enter a name for this Chatbot')
              }]}
            >
              <Input
                size="large"
                placeholder={t('Set chatbot name (max 240 characters)')}
                maxLength={240}
              />
            </Form.Item>

            <Form.Item
              name="primaryLanguage"
              label={t('Language')}
              required
              validateTrigger={['onChange', 'onBlur']}
              rules={[{
                required: true,
                type: 'string',
                max: 20,
                whitespace: true,
                message: t('Please set a language')
              }]}
            >
              <Select
                size="large"
                allowClear
                className="fs-base"
                placeholder={t('Select one of the available languages')}
                showAction={['focus', 'click']}
                onChange={(value) => {
                  form.setFieldsValue({ language: value });
                }}
              >
                {
                  LANGUAGE_OPTIONS.map(language => (
                    <Select.Option key={language.key} value={language.key}>{language.icon} {language.label}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name="description"
              label={t('Description')}
              rules={[{
                required: false,
                type: 'string',
                min: 0,
                max: 100,
                whitespace: true
              }]}
            >
              <TextArea size="large" placeholder={t('Set chatbot description (max 100 characters)')} style={{ maxHeight: 360 }}/>
            </Form.Item>

            <Form.Item
              name="image"
            >
              <AvatarFormFieldSelection
                initialValue={form.getFieldValue('image')}
                fields={{
                  inputField: {
                    name: 'image',
                    label: 'Image URL',
                    placeholder: t('Enter image URL'),
                    validationMessage: t('Please enter an image URL')
                  },
                  uploadField: {
                    name: 'image',
                    label: t('Bot avatar'),
                    validationMessage: t('Please select or upload an image')
                  }
                }}
                shouldValidate
                showInput={false}
                formInstance={form}
                loading={isFetchingMediaFiles}
              />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      title: t('SETTINGS'),
      description: t('Step 2: Configuration Settings.'),
      content: (
        <Row className={isMobileSize ? 'flex-column' : ''}>
          <Col span={isMobileSize ? 24 : 8} flex={1} className="overflow-y-auto overflow-x-hidden scrollbar-custom pb-2" style={{ height: isMobileSize ? 'auto' : '500px' }}>
            <Row className="px-6 py-3" justify={'space-between'} align={'middle'}>
              <Col>
                <Text className="fs-sm" type="primary">
                  {t('TEMPLATE FILTERS')}
                </Text>
              </Col>

              <Col>
                <Button className="fs-sm fw-400" type="link" onClick={() => clearFilters()}>
                  <Text className="fs-sm" type="secondary">{t('CLEAR FILTERS')}</Text>
                </Button>
              </Col>
            </Row>

            <Form.Item
              name="channelFilters"
              valuePropName="checkedKeys"
              trigger="onCheck"
              className="mb-0"
            >
              <Tree
                className="px-6"
                checkable
                switcherIcon={<DownOutlined />}
                disabled={isLoadingBotTemplates || availableChannels?.length === 0}
                treeData={[
                  {
                    title: t('Channel'),
                    key: 'channel',
                    selectable: false,
                    children: availableChannels?.map(channel => {
                      return {
                        title: BOT_DEPLOYMENTS_CHANNELS[channel].name,
                        key: channel,
                        selectable: false
                      };
                    })
                  }
                ]}
              />
            </Form.Item>

            <Form.Item
              name="languageFilters"
              valuePropName="checkedKeys"
              trigger="onCheck"
            >
              <Tree
                className="px-6"
                checkable
                switcherIcon={<DownOutlined />}
                disabled={isLoadingBotTemplates || availableLanguages?.length === 0}
                treeData={[
                  {
                    title: t('Language'),
                    key: 'language',
                    selectable: false,
                    children: availableLanguages?.map(language => {
                      return {
                        title: LANGUAGE_OPTIONS.find((item) => item.key === language).label,
                        key: language,
                        selectable: false
                      };
                    })
                  }
                ]}
              />
            </Form.Item>

            <Divider />

            <Text className="fs-sm px-6" type="secondary">
              {t('USE CASES')}
            </Text>

            <Row className="px-6 py-3">
              <Form.Item
                name="useCaseFilters"
                valuePropName="checkedKeys"
                trigger="onCheck"
              >
                <Checkbox.Group style={{ width: '100%' }} onChange={handleCheckboxChange}>
                  <Row className="px-2">
                    {availableUseCases?.map(category => {
                      return (
                        <Col span={24}>
                          <Checkbox value={category}>{BOT_TEMPLATE_CATEGORIES[category].label}</Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Row>
          </Col>

          <Divider type="vertical" className="h-auto m-0" />

          <Col span={isMobileSize ? 24 : 15} flex={4} className={`py-6 ${isMobileSize ? 'px-6' : 'pl-12'}`}>
            <Row className="mb-4" justify={'space-between'}>
              <Col>
                <Text className="fs-sm" type="primary">
                  {t('TEMPLATES')}
                </Text>

                {
                  filteredTemplates?.length === botTemplates?.items.length - 1
                    ? <Text className="fs-sm ml-3" type="secondary">{t('All Templates')} ({filteredTemplates.length})</Text>
                    : <Text className="fs-sm ml-3" type="secondary">({filteredTemplates.length})</Text>
                }
              </Col>

              <Col className="mr-2">
                <Button loading={isCreatingBot || isLoadingBotTemplates} htmlType="submit" type="primary" icon={<PlusOutlined />}>{t('Create from Scratch')}</Button>
              </Col>
            </Row>

            {
              filteredTemplates.length > 0 ? (
                <Row gutter={[12, 12]} className="overflow-y-auto overflow-x-hidden scrollbar-custom py-2" style={{ minHeight: '300px', maxHeight: '400px' }}>
                  {
                    filteredTemplates?.filter((botTemplate) => !botTemplate.blank).map((botTemplate) => {
                      return (
                        <Col className="w-50" >
                          <Card
                            hoverable={botTemplate.id !== selectedTemplate}
                            className={botTemplate.id === selectedTemplate ? `ant-card-selected cursor-pointer` : `cursor-pointer`}
                            onClick={() => selectTemplate(botTemplate.id)}
                            style={{ minHeight: 285 }}
                            cover={
                              botTemplate.image ? (
                                <Image
                                  align="middle"
                                  src={botTemplate.image || AppLogo}
                                  key={botTemplate.id}
                                  className="object-fit-fill object-position-top"
                                  style={{ height: 125 }}
                                  preview={{
                                    visible: false,
                                    src: botTemplate.image || AppLogo,
                                    mask: (
                                      <Row justify="center" align="top">
                                        <Col span={24}>
                                          <Text className="text-white fs-base">{BOT_TEMPLATE_CATEGORIES[botTemplate.useCase]?.label}</Text>
                                        </Col>
                                        <Col className="pt-2" span={24}>
                                          <Text className="text-white fs-lg fw-600">{botTemplate.displayName}</Text>
                                        </Col>
                                      </Row>
                                    ),
                                    maskClassName: 'mask-chatbot-template pt-3'
                                  }}
                                  placeholder={
                                    <Row className="w-100" style={{ maxHeight: '125px' }}>
                                      <Col flex="auto" className="text-center">
                                        <Spin className="mt-10 mb-8"/>
                                      </Col>
                                    </Row>
                                  }
                                />
                              ) : null
                            }
                          >
                            <Meta
                              description={
                                <Row>
                                  <Col span={24} className="mb-3">
                                    {
                                      botTemplate?.description ? (
                                        <Paragraph
                                          ellipsis={{
                                            rows: 2,
                                            tooltip: true,
                                            title: botTemplate.description
                                          }}>{botTemplate.description}
                                        </Paragraph>
                                      ) : <Text type="secondary">{t('(no description)')}</Text>
                                    }
                                  </Col>

                                  <Col span={24} className="text-overflow-ellipsis overflow-hidden white-space-nowrap mb-2">
                                    <Row className="align-items-center">
                                      <Text className="mr-2 fw-400">{t('Channels')}:</Text>
                                      <Text className="fw-300">{BOT_DEPLOYMENTS_CHANNELS[botTemplate.platformCompatibility[0]].name}</Text>
                                      <ResponsiveTagsList
                                        style={{ width: '60px' }}
                                        maxTagCount={0}
                                        values={botTemplate.platformCompatibility
                                          .slice(1) // Skip the first element since it's already displayed
                                          .map((platform) => ({
                                            label: BOT_DEPLOYMENTS_CHANNELS[platform].name,
                                            value: BOT_DEPLOYMENTS_CHANNELS[platform].name
                                          }))}
                                      />
                                    </Row>
                                  </Col>

                                  <Col span={24}>
                                    <Row className="align-items-center">
                                      <Text className="mr-2 fw-400">{t('Languages')}:</Text>
                                      <Text className="fw-300">{LANGUAGE_OPTIONS.find((item) => item.key === botTemplate.availableLanguages[0]).label}</Text>
                                      <ResponsiveTagsList
                                        style={{ width: '60px' }}
                                        maxTagCount={0}
                                        values={botTemplate.availableLanguages
                                          .slice(1) // Skip the first element since it's already displayed
                                          .map((language) => ({
                                            label: LANGUAGE_OPTIONS.find((item) => item.key === language).label,
                                            value: LANGUAGE_OPTIONS.find((item) => item.key === language).label
                                          }))}
                                      />
                                    </Row>
                                  </Col>
                                </Row>
                              }
                            />
                          </Card>
                        </Col>
                      );
                    })
                  }
                </Row>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('No Templates found')}
                  className="mt-10"
                />
              )
            }
          </Col>
        </Row>
      )
    }
  ], [form.getFieldsValue(true), selectedTemplate, filteredTemplates]);

  const goToStep = (stepNumber) => {
    form.validateFields()
      .then(() => {
        setCurrentStep(stepNumber);
      }).catch();
  };

  const goToNextStep = () => {
    goToStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    goToStep(currentStep - 1);
  };

  const renderFooter = () => {
    return (
      <Row justify="space-between" gutter={16}>
        <Col>
          {
            currentStep !== 0 && (
              <Button type="text" size="large" className="text-primary" onClick={() => goToPreviousStep()}>
                {t('BACK')}
              </Button>
            )
          }
        </Col>

        <Col>
          {
            currentStep === steps.length - 1 ? (
              <Button loading={isCreatingBot || isLoadingBotTemplates} disabled={!selectedTemplate} type="primary" size="large" onClick={() => onSubmit()}>
                {t('Use Template')}
              </Button>
            ) : (
              <Button type="primary" size="large" onClick={() => goToNextStep()}>
                {t('Next')}
              </Button>
            )
          }
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      className={currentStep === 0 ? 'w-30' : 'w-50' }
      centered
      footer={renderFooter()}
      onCancel={closeModal}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      forceRender
      title={
        <Fragment>
          <Paragraph className="mb-1">
            <Space size={8} className="align-items-center">
              <CreateBotIcon />
              {t('Create new Chatbot')}
            </Space>
          </Paragraph>

          <Text className="text-muted">
            {currentStep === 0 ? t('Step 1: Configure the identity of your bot') : t('Step 2: Select one of the available templates or build your bot from scratch')}
          </Text>
        </Fragment>
      }
    >
      <Form
        onFinish={onSubmit}
        name="createChatbotForm"
        layout="vertical"
        form={form}
        onValuesChange={(_, allFields) => {
          updateState(allFields);
        }}
      >
        {steps[currentStep].content}
      </Form>
    </Modal>
  );
};

CreateBotModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};
export default CreateBotModal;
