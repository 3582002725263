
import store from '../store';
import { apiSlice } from '../apiSlice';
import { isEmptyArray } from '../../utils/is/isEmptyArray';
import moment from 'moment-timezone';
import { NAV_LIVECHAT } from '../../constants/navigation';
import replaceUrlParams from '../../utils/string/replaceUrlParams';
import { REQUEST_STATUSES } from '../../constants/liveChat';

export const addConversationMessage = (message) => {
  const orgId = store.getState().auth.organizationId;
  const conversationId = message.livechatConversation.id;
  const activeConversationId = store.getState().livechat?.activeConversationInfo?.id;

  if (conversationId === activeConversationId ) {
    // store.dispatch(readConversation(message.livechatConversation.id));
    const cachedResult = apiSlice.endpoints.fetchConversationMessages.select({ orgId, urlParams: { conversation: conversationId } })(store.getState());
    const currentConversationMessages = cachedResult?.data?.data || [];
    let messageIndex = currentConversationMessages?.length;

    for (let i = 0; i < messageIndex; i++) {
      const currentMessage = currentConversationMessages[i];
      if (currentMessage.id < message.id) {
        messageIndex = i;
        break;
      }
    }

    const updatedMessages = currentConversationMessages.slice(0, messageIndex).concat(message).concat(currentConversationMessages.slice(messageIndex));

    store.dispatch(apiSlice.util.updateQueryData('fetchConversationMessages', { orgId, urlParams: { conversation: conversationId } }, (draft) => {
      draft.data = updatedMessages;
      draft.total = draft.total + 1;
    }));
  }
};

export const addConversation = (conversation) => {
  const orgId = store.getState().auth.organizationId;

  store.dispatch(apiSlice.util.updateQueryData('fetchConversations', { orgId }, (draft) => {
    return {
      data: [conversation, ...draft.data],
      total: draft.total + 1
    };
  }
  ));
};

export const updateConversationStatus = (conversation) => {
  if (!conversation) {
    return;
  }

  const orgId = store.getState().auth.organizationId;
  const cachedResult = apiSlice.endpoints.fetchConversations.select({ orgId })(store.getState());
  const currentConversationData = cachedResult?.data?.data || [];
  const conversationIndex = currentConversationData.findIndex(currentConversation => currentConversation.id === conversation.id);

  if (conversationIndex !== -1) {
    store.dispatch(apiSlice.util.updateQueryData('fetchConversations', { orgId }, (draft) => {
      draft.data[conversationIndex] = {
        ...draft.data[conversationIndex],
        ...conversation
      };
    }));
  }
};

export const removeConversation = (conversation) => {
  const orgId = store.getState().auth.organizationId;

  store.dispatch(apiSlice.util.updateQueryData('fetchConversations', { orgId }, (draft) => {
    return {
      data: draft.data.filter(currentConversation => currentConversation.id !== conversation.id),
      total: draft.total - 1
    };
  }
  ));
};

export const setConversationRead = (conversation, currentUserId) => {
  const orgId = store.getState().auth.organizationId;
  const conversationId = conversation.id;

  if (!conversation) {
    return;
  }

  const cachedResult = apiSlice.endpoints.fetchConversations.select({ orgId })(store.getState());
  const currentConversationData = cachedResult?.data?.data || [];

  const conversationIndex = (currentConversationData || []).findIndex(currentConversation => currentConversation.id === conversationId);

  if (conversationIndex !== -1) {
    const meIndex = (currentConversationData[conversationIndex].livechatParticipants || []).findIndex(
      participant => participant.platformUserId === currentUserId
    );

    const updateParticipants = [...currentConversationData[conversationIndex].livechatParticipants];

    if (meIndex !== -1) {
      updateParticipants[meIndex] = {
        ...updateParticipants[meIndex],
        lastMessageRead: true
      };

      store.dispatch(apiSlice.util.updateQueryData('fetchConversations', { orgId }, (draft) => {
        draft.data[conversationIndex] = {
          ...draft.data[conversationIndex],
          livechatParticipants: updateParticipants
        };
      }));
    }
  }
};

export const updateConversationOnMessage = (message) => {
  const orgId = store.getState().auth.organizationId;
  const conversationId = message.livechatConversation.id;

  if (!message) {
    return;
  }

  const cachedResult = apiSlice.endpoints.fetchConversations.select({ orgId })(store.getState());
  let currentConversationData = cachedResult?.data?.data || [];
  let updatedData = [];
  const conversationIndex = currentConversationData.findIndex(currentConversation => currentConversation.id === conversationId);

  if (conversationIndex !== -1) {
    updatedData = currentConversationData.slice(0, conversationIndex).concat(currentConversationData.slice(conversationIndex + 1));

    const updatedConversation = {
      ...updatedData[conversationIndex],
      ...message.livechatConversation,
      lastMessage: {
        id: message.id,
        platformSessionId: message.platformSessionId,
        sentDate: message.sentDate,
        text: message.text,
        type: message.type
      }
    };

    updatedData = [updatedConversation, ...updatedData];

    store.dispatch(apiSlice.util.updateQueryData('fetchConversations', { orgId }, (draft) => {
      draft.data = updatedData;
    }));
  }
};

export const updateTransferredBackConversation = (conversation) => {
  const orgId = store.getState().auth.organizationId;
  const currentId = window.location.search.split('&')[1].split('=')[1];
  if (currentId) {
    store.dispatch(apiSlice.endpoints['fetchActiveConversation'].initiate({ orgId, conversationId: currentId }));
    store.dispatch(apiSlice.endpoints['fetchConversationMessages'].initiate({ orgId, urlParams: { skip: 0, take: 50, conversation: currentId }, replace: true }));
    store.dispatch(apiSlice.endpoints['fetchConversationNotes'].initiate({ conversationId: currentId }));
  }
};

export const onReconnectSocket = () => {
  const orgId = store.getState().auth.organizationId;
  const currentLocation = window.location.pathname;
  if (currentLocation === replaceUrlParams(NAV_LIVECHAT, { orgId })) {
    updateTransferredBackConversation();
    store.dispatch(apiSlice.endpoints['fetchRequests'].initiate({ orgId, replace: true, urlParams: { skip: 0, take: 20, status: [REQUEST_STATUSES.NEW, REQUEST_STATUSES.ACCEPTED, REQUEST_STATUSES.MISSED].join(',') } }));
    store.dispatch(apiSlice.endpoints['fetchConversations'].initiate({ orgId, replace: true, urlParams: {
      skip: 0,
      take: 20,
      status: store.getState().livechat.inboxFilters.status,
      isTransferred: store.getState().livechat.inboxFilters.transferredOnly,
      isUnread: store.getState().livechat.inboxFilters.unreadOnly,
      orderByUnread: store.getState().livechat.inboxFilters.orderByUnread,
      ...(!isEmptyArray(store.getState().livechat.inboxFilters?.bots) && { tenantIds: store.getState().livechat.inboxFilters.bots?.join(',') }),
      ...(!isEmptyArray(store.getState().livechat.inboxFilters?.users) &&{ userIds: store.getState().livechat.inboxFilters.users?.join(',') }),
      ...(store.getState().livechat.inboxFilters?.date && { dateRange: `${store.getState().livechat.inboxFilters?.date === 'custom' ? store.getState().livechat.inboxFilters.customDate[0] : store.getState().livechat.inboxFilters?.date}, ${store.getState().livechat.inboxFilters?.date === 'custom' ? store.getState().livechat.inboxFilters.customDate[1] : moment.tz(new Date(), 'UTC').endOf('day').format()}` }),
      ...(store.getState().livechat.inboxFilters?.searchText && { searchText: store.getState().livechat.inboxFilters.searchText })
    } }));
  }
};
