import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_NLP_PIPELINES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the NLP pipelines.'),
      403: i18n.t('You are not allowed to fetch the NLP pipelines.'),
      404: i18n.t('The NLP pipelines you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the NLP pipelines.'),
      default: i18n.t('An error occurred fetching the NLP pipelines.')
    }
  },

  FETCH_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the NLP pipeline.'),
      403: i18n.t('You are not allowed to fetch the NLP pipeline.'),
      404: i18n.t('The NLP pipeline you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the NLP pipeline.'),
      default: i18n.t('An error occurred fetching the NLP pipeline.')
    }
  },

  FETCH_NLP_PIPELINE_STATUS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the NLP pipeline status.'),
      403: i18n.t('You are not allowed to fetch the NLP pipeline status.'),
      404: i18n.t('The NLP pipeline status you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching NLP pipeline status.'),
      default: i18n.t('An error occurred fetching the NLP pipeline status.')
    }
  },

  CREATE_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to create the NLP pipeline.'),
      403: i18n.t('You are not allowed to create the NLP pipeline.'),
      404: i18n.t('The NLP pipeline you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the NLP pipeline.'),
      default: i18n.t('An error occurred creating the NLP pipeline.')
    },
    SUCCESS: {
      200: i18n.t('NLP pipeline created successfully!'),
      201: i18n.t('NLP pipeline created successfully!')
    }
  },

  UPDATE_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to update the NLP pipeline.'),
      403: i18n.t('You are not allowed to update the NLP pipeline.'),
      404: i18n.t('The NLP pipeline you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the NLP pipeline.'),
      default: i18n.t('An error occurred updating the NLP pipeline.')
    },
    SUCCESS: {
      200: i18n.t('NLP pipeline updated successfully!')
    }
  },

  DELETE_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the NLP pipeline.'),
      403: i18n.t('You are not allowed to delete the NLP pipeline.'),
      404: i18n.t('The NLP pipeline you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the NLP pipeline.'),
      default: i18n.t('An error occurred deleting the NLP pipeline.')
    },
    SUCCESS: {
      200: i18n.t('NLP pipeline deleted successfully!')
    }
  },

  UPDATE_PRIMARY_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to update the NLP pipeline.'),
      403: i18n.t('You are not allowed to update the NLP pipeline.'),
      404: i18n.t('The NLP pipeline you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the NLP pipeline.'),
      default: i18n.t('An error occurred updating the NLP pipeline.')
    },
    SUCCESS: {
      200: i18n.t('NLP pipeline updated successfully!')
    }
  },

  TRAIN_NLP_PIPELINE: {
    ERROR: {
      401: i18n.t('You are not authorized to train this pipeline.'),
      403: i18n.t('You are not allowed to train this pipeline.'),
      404: i18n.t('The pipeline you are trying to train does not exist.'),
      500: i18n.t('There was an error starting the NLP training.'),
      default: i18n.t('An error occurred starting the NLP training.')
    },
    SUCCESS: {
      200: i18n.t('Pipeline training started!')
    }
  },

  FETCH_RAQ_PIPELINE_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the pipeline settings.'),
      403: i18n.t('You are not allowed to fetch the pipeline settings.'),
      404: i18n.t('The pipeline settings you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the pipeline settings.'),
      default: i18n.t('An error occurred fetching the pipeline settings.')
    }
  },

  CREATE_RAQ_PIPELINE_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to create the pipeline settings.'),
      403: i18n.t('You are not allowed to create the pipeline settings.'),
      404: i18n.t('The pipeline settings you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the pipeline settings.'),
      default: i18n.t('An error occurred creating the pipeline settings.')
    },
    SUCCESS: {
      200: i18n.t('Pipeline settings created successfully!'),
      201: i18n.t('Pipeline settings created successfully!')
    }
  },

  UPDATE_RAQ_PIPELINE_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to update the pipeline settings.'),
      403: i18n.t('You are not allowed to update the pipeline settings.'),
      404: i18n.t('The pipeline settings you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the pipeline settings.'),
      default: i18n.t('An error occurred updating the pipeline settings.')
    },
    SUCCESS: {
      200: i18n.t('Pipeline settings updated successfully!')
    }
  }
};
