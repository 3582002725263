/**
* Sets up a fake anchor element to click and download a file
*
* @param {Blob} file The file to download.
* @param {string} filename The desired name of the file.
* @returns {void}
*/
const handleFileDownload = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export default handleFileDownload;
