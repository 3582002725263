import { API_TAGS } from '../../constants/api';
import { API_REPORTS_URL } from '../../constants/env';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './scheduledReportsMessages';

export const scheduledReportsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchScheduledReports: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organization/${orgId}/report/schedule`,
        method: 'GET',
        API_URL: API_REPORTS_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_SCHEDULED_REPORTS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.SCHEDULED_REPORTS, id: 'LIST' }];
        }
        return [
          ...result.map(({ id }) => ({ type: API_TAGS.SCHEDULED_REPORTS, id })),
          { type: API_TAGS.SCHEDULED_REPORTS, id: 'LIST' }
        ];
      }
    }),
    fetchScheduledReport: builder.query({
      query: ({ orgId, reportId, urlParams = {} }) => ({
        url: `organization/${orgId}/report/schedule/${reportId}`,
        method: 'GET',
        API_URL: API_REPORTS_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_SCHEDULED_REPORT.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.SCHEDULED_REPORTS, id: args.reportId }];
      }
    }),
    createScheduledReport: builder.mutation({
      query: ({ orgId, data }) => ({
        url: `organization/${orgId}/report/schedule`,
        method: 'POST',
        API_URL: API_REPORTS_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_SCHEDULED_REPORT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_SCHEDULED_REPORT.SUCCESS
        }
      }),
      transformErrorResponse: (response) => false,
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.SCHEDULED_REPORTS, id: 'LIST' }];
      }
    }),
    updateScheduledReport: builder.mutation({
      query: ({ orgId, reportId, data }) => ({
        url: `organization/${orgId}/report/schedule/${reportId}`,
        method: 'PUT',
        API_URL: API_REPORTS_URL,
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_SCHEDULED_REPORT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_SCHEDULED_REPORT.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.SCHEDULED_REPORTS, id: args.reportId }];
      }
    }),
    deleteScheduledReport: builder.mutation({
      query: ({ orgId, reportId }) => ({
        url: `organization/${orgId}/report/schedule/${reportId}`,
        method: 'DELETE',
        API_URL: API_REPORTS_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_SCHEDULED_REPORT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_SCHEDULED_REPORT.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.SCHEDULED_REPORTS, id: 'LIST' }];
      }
    }),
    triggersScheduledReport: builder.mutation({
      query: ({ orgId, reportId }) => ({
        url: `organization/${orgId}/report/schedule/trigger/${reportId}`,
        method: 'POST',
        API_URL: API_REPORTS_URL,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.TRIGGER_SCHEDULED_REPORT.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.TRIGGER_SCHEDULED_REPORT.SUCCESS
        }
      }),
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.SCHEDULED_REPORTS, id: args.reportId }];
      }
    })
  })
});

export const {
  useFetchScheduledReportsQuery,
  useFetchScheduledReportQuery,
  useCreateScheduledReportMutation,
  useUpdateScheduledReportMutation,
  useDeleteScheduledReportMutation,
  useTriggersScheduledReportMutation
} = scheduledReportsSlice;
