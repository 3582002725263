import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_LIVE_CHAT_SYSTEM_MESSAGES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch LiveChat system messages.'),
      403: i18n.t('You are not allowed to fetch LiveChat system messages.'),
      404: i18n.t('The LiveChat system messages you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the LiveChat system messages.'),
      default: i18n.t('An error occurred fetching the LiveChat system messages.')
    }
  },
  CREATE_LIVE_CHAT_SYSTEM_MESSAGE: {
    ERROR: {
      401: i18n.t('You are not authorized to create LiveChat system message.'),
      403: i18n.t('You are not allowed to create LiveChat system message.'),
      404: i18n.t('The LiveChat system message you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the LiveChat system message.'),
      default: i18n.t('An error occurred creating the LiveChat system messages.')
    },
    SUCCESS: {
      200: i18n.t('LiveChat system message created successfully!')
    }
  },
  UPDATE_LIVE_CHAT_SYSTEM_MESSAGE: {
    ERROR: {
      401: i18n.t('You are not authorized to update LiveChat system message.'),
      403: i18n.t('You are not allowed to update LiveChat system message.'),
      404: i18n.t('The LiveChat system message you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the LiveChat system message.'),
      default: i18n.t('An error occurred updating the LiveChat system messages.')
    },
    SUCCESS: {
      200: i18n.t('LiveChat system message updated successfully!')
    }
  }
};
