import { apiSlice } from '../apiSlice';
import { MESSAGES } from './broadcastsMessages';
import handleBlobTypes from '../utils/handleBlobTypes';
import { API_TAGS } from '../../constants/api';

export const broadcastsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchBroadcasts: builder.query({
      query: ({ orgId, tenantId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/broadcasts`,
        method: 'GET',
        params: {
          ...urlParams,
          includeGroups: true
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BROADCASTS.ERROR
        }
      }),
      providesTags: (result, error, args) => {
        if (error?.error) {
          return [{ type: API_TAGS.BROADCASTS, id: 'LIST' }];
        }
        return [
          ...result.items.map(({ id }) => ({ type: API_TAGS.BROADCASTS, id })),
          { type: API_TAGS.BROADCASTS, id: 'LIST' }
        ];
      }
    }),

    updateBroadcast: builder.mutation({
      query: ({ orgId, tenantId, broadcastId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/broadcasts/${broadcastId}`,
        method: 'PATCH',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_BROADCAST.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_BROADCAST.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BROADCASTS, id: args.broadcastId },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    createBroadcast: builder.mutation({
      query: ({ orgId, tenantId, data, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/broadcasts`,
        method: 'POST',
        params: {
          ...urlParams
        },
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_BROADCAST.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_BROADCAST.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BROADCASTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    deleteBroadcast: builder.mutation({
      query: ({ orgId, tenantId, broadcastId, urlParams = {}, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/broadcasts/${broadcastId}`,
        method: 'DELETE',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_BROADCAST.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_BROADCAST.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.BROADCASTS, id: 'LIST' },
          { type: API_TAGS.BOTS, id: args.tenantId }
        ];
      }
    }),

    downloadBroadcastAnalytics: builder.query({
      query: ({ orgId, tenantId, broadcastId, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/broadcasts/${broadcastId}/export`,
        method: 'GET',
        params: {
          ...urlParams
        },
        responseType: 'blob',
        includeResponseHeaders: true,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DOWNLOAD_BROADCAST_ANALYTICS.ERROR
        }
      }),
      transformResponse: (response) => {
        return handleBlobTypes(response);
      },
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.BROADCASTS, id: args.broadcastId }];
      }
    })
  })
});

export const {
  useFetchBroadcastsQuery,
  useLazyFetchBroadcastsQuery,
  useUpdateBroadcastMutation,
  useCreateBroadcastMutation,
  useDeleteBroadcastMutation,
  useLazyDownloadBroadcastAnalyticsQuery
} = broadcastsSlice;
