// Core imports
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

// Components imports
import { Popover } from 'antd';

const baseClassName = 'read-more-popover';

const ReadMorePopover = ({ message, searchFilter }) => {
  return (
    <Popover
      onClick={(e) => e.stopPropagation()}
      overlayClassName={baseClassName}
      content={ <Highlighter
        highlightClassName="bg-highlight p-0"
        searchWords={[searchFilter]}
        autoEscape
        textToHighlight={(message)}
      />}
      trigger="click"
    >
      <span className={`${baseClassName}__indicator`}>...</span>
    </Popover>
  );
};

ReadMorePopover.propTypes = {
  message: PropTypes.string,
  searchFilter: PropTypes.string
};

export default ReadMorePopover;
