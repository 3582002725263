import { API_TAGS } from '../../constants/api';
import { apiSlice } from '../apiSlice';
import { MESSAGES } from './botTemplatesMessages';

export const botTemplatesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchBotTemplates: build.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/bot-templates`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_TEMPLATES.ERROR
        }
      }),
      providesTags: () => {
        return [
          { type: API_TAGS.BOT_TEMPLATES, id: 'LIST' }
        ];
      }
    }),

    fetchBotTemplate: build.query({
      query: ({ orgId, templateId, urlParams = {} }) => ({
        url: `organizations/${orgId}/bot-templates/${templateId}`,
        method: 'GET',
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_BOT_TEMPLATE.ERROR
        }
      }),
      providesTags: (_result, _error, args) => {
        return [{ type: API_TAGS.BOT_TEMPLATES, id: args.templateId }];
      }
    })
  })
});

export const {
  useFetchBotTemplatesQuery,
  useLazyFetchBotTemplatesQuery,
  useFetchBotTemplateQuery
} = botTemplatesSlice;
