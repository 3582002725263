import { apiSlice } from '../apiSlice';
import { API_CORE_URL, API_LIVECHAT_URL } from '../../constants/env';
import { API_TAGS } from '../../constants/api';
import { MESSAGES } from './integrationsMessages';

export const integrationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCrmIntegrationsSubscriber: builder.query({
      query: ({ orgId, tenantId, handle, urlParams = {} }) => ({
        url: `organizations/${orgId}/tenants/${tenantId}/contact-by-subscriber-handle/${handle}`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        }
      }),
      transformResponse: (response, meta) => {
        return {
          data: response
        };
      },
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.CRM_INTEGRATIONS, id: 'LIST' }];
      }
    }),

    fetchIntegrations: builder.query({
      query: ({ orgId, urlParams = {} }) => ({
        url: `organizations/${orgId}/integrations`,
        method: 'GET',
        API_URL: API_CORE_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_INTEGRATIONS
        }
      }),
      providesTags: (result, error, args) => {
        return [{ type: API_TAGS.INTEGRATIONS, id: 'LIST' }];
      }
    }),

    createIntegration: builder.mutation({
      query: ({ orgId, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/integrations`,
        method: 'POST',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_INTEGRATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.CREATE_INTEGRATION.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.INTEGRATIONS, id: 'LIST' }
        ];
      }
    }),

    updateIntegration: builder.mutation({
      query: ({ orgId, id, data, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/integrations/${id}`,
        method: 'PATCH',
        body: data,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_INTEGRATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.UPDATE_INTEGRATION.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.INTEGRATIONS, id: 'LIST' }
        ];
      }
    }),

    deleteIntegration: builder.mutation({
      query: ({ orgId, id, successMessage = { showMessage: true } }) => ({
        url: `organizations/${orgId}/integrations/${id}`,
        method: 'DELETE',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_INTEGRATION.ERROR
        },
        successMessage: {
          ...successMessage,
          messages: MESSAGES.DELETE_INTEGRATION.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [
          { type: API_TAGS.INTEGRATIONS, id: 'LIST' }
        ];
      }
    })
  })
});

export const {
  useLazyFetchCrmIntegrationsSubscriberQuery,
  useFetchIntegrationsQuery,
  useLazyFetchIntegrationsQuery,
  useCreateIntegrationMutation,
  useUpdateIntegrationMutation,
  useDeleteIntegrationMutation
} = integrationsSlice;
