import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_GROUPS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the groups.'),
      403: i18n.t('You are not allowed to fetch the groups.'),
      404: i18n.t('The groups you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the groups.'),
      default: i18n.t('An error occurred fetching the groups.')
    }
  },

  CREATE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to create the group.'),
      403: i18n.t('You are not allowed to create the group.'),
      404: i18n.t('The group you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the group.'),
      default: i18n.t('An error occurred creating the group.')
    },
    SUCCESS: {
      200: i18n.t('Group created successfully!')
    }
  },

  UPDATE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to update the group.'),
      403: i18n.t('You are not allowed to update the group.'),
      404: i18n.t('The group you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the group.'),
      default: i18n.t('An error occurred updating the group.')
    },
    SUCCESS: {
      200: i18n.t('Group updated successfully!')
    }
  },

  DELETE_GROUP: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the group.'),
      403: i18n.t('You are not allowed to delete the group.'),
      404: i18n.t('The group you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the group.'),
      default: i18n.t('An error occurred deleting the group.')
    },
    SUCCESS: {
      200: i18n.t('Group deleted successfully!')
    }
  }
};
