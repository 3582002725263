// Core imports
import PropTypes from 'prop-types';

// Misc imports
import { usePrompt } from '../../utils/hooks/usePrompt';

const Prompt = ({ message, when }) => {
  usePrompt(message, when);
  return null;
};

Prompt.propTypes = {
  message: PropTypes.string,
  when: PropTypes.bool
};

export default Prompt;
