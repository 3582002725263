import { apiSlice } from '../apiSlice';
import { MESSAGES } from './authMessages';
import { TEMP_STORAGE_KEYS, AUTHENTICATION_METHODS } from '../../constants/main';
import { authActions } from './authSlice';
import { logoutUser } from './authActions';
import { API_TAGS } from '../../constants/api';
import { isTokenExpired } from '../../utils/auth/isTokenExpired';

const getStoredUser = () => {
  const storedEntry = window.localStorage.getItem(TEMP_STORAGE_KEYS.USER);
  return storedEntry && JSON.parse(storedEntry);
};

const getAuthenticationMethod = (method) => {
  switch (method) {
    case AUTHENTICATION_METHODS.GOOGLE:
      return 'login/google/';
    case AUTHENTICATION_METHODS.MICROSOFT:
      return 'login/microsoft/';
    case AUTHENTICATION_METHODS.TEAMS:
      return 'login/teams/';
    default:
      return 'login/';
  }
};

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    fetchMe: build.query({
      query: () => ({
        url: 'users/me',
        method: 'GET',
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_ME.ERROR,
          errorCodesToIgnore: [401]
        }
      }),
      transformResponse: (response) => {
        return {
          ...getStoredUser(),
          ...response
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled, getState }) => {
        await dispatch(authActions.userFetchStart());

        await queryFulfilled.then(async response => {
          await dispatch(authActions.userFetchComplete(response?.data));
        }).catch(async error => {
          await dispatch(authActions.userFetchFail(error?.error));
        });
      },
      providesTags: () => {
        return [{ type: API_TAGS.AUTH, id: 'Me' }];
      }
    }),

    logoutUser: build.mutation({
      query: () => ({
        url: 'logout',
        method: 'POST'
      })
    }),

    refreshToken: build.mutation({
      query: ({ refreshToken }) => ({
        url: 'login/refresh',
        method: 'POST',
        body: { token: refreshToken },
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.REFRESH_TOKEN.ERROR
        }
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled, getState }) => {
        const isTokenInvalid = isTokenExpired(getState().auth.token);

        try {
          const response = await queryFulfilled;
          await dispatch(authActions.refreshTokenComplete(response?.data));
        } catch (error) {
          logoutUser();
          await dispatch(authActions.refreshTokenFail(error));
        }

        if (isTokenInvalid) {
          await dispatch(apiSlice.util.invalidateTags([...Object.values(API_TAGS)]));
        }
      }
    }),

    authenticateUser: build.mutation({
      query: ({ authenticationMethod, data, currentUrl }) => ({
        url: getAuthenticationMethod(authenticationMethod),
        method: 'POST',
        body: data,
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.LOGIN_USER.ERROR
        }
      }),

      transformResponse: (response, _meta, args) => {
        const { organizations } = response.user;
        const firstOrgAvailable = organizations[0]?.id;
        const availableUrlPaths = args.currentUrl?.split('/');
        const urlOrg = availableUrlPaths ? availableUrlPaths[availableUrlPaths?.indexOf('org') + 1] : null;
        return {
          ...response,
          redirectUrl: !!args?.currentUrl,
          redirectOrg: args?.currentUrl ? urlOrg : firstOrgAvailable
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled.then(async response => {
          await dispatch(authActions.organizationChangeComplete(response.data?.redirectOrg));
          await dispatch(authActions.userLoginComplete(response?.data));
        }).catch(async error => {
          await dispatch(authActions.userLoginFail(error));
        });
      }
    }),

    verifyEmail: build.mutation({
      query: ({ token }) => ({
        url: `public/email-verification`,
        method: 'POST',
        body: { token },
        withAuth: false,
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.VERIFY_EMAIL.ERROR
        }
      }),
      transformResponse: () => true
    })
  })
});

export const { useLazyFetchMeQuery, useVerifyEmailMutation, useRefreshTokenMutation, useAuthenticateUserMutation, useLogoutUserMutation } = authSlice;
