import Icon from '@ant-design/icons';

const CheckCircleSvg = () => (
  <svg
    width="1.1em"
    height="1.1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    color="currentColor"
  >
    <polyline points="7 13 10 16 17 9"/>
    <circle cx="12" cy="12" r="10"/>
  </svg>
);

export const CheckCircleIcon = (props) => (
  <Icon component={CheckCircleSvg} {...props} />
);
