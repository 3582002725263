import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_ORGANIZATION: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the organization data.'),
      403: i18n.t('You are not allowed to fetch the organization data.'),
      404: i18n.t('The organization data you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the organization data.'),
      default: i18n.t('An error occurred fetching the organization data.')
    }
  },

  CREATE_ORGANIZATION: {
    ERROR: {
      401: i18n.t('You are not authorized to create an organization.'),
      403: i18n.t('You are not allowed to create an organization.'),
      404: i18n.t('The organization you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the organization.'),
      default: i18n.t('An error occurred creating the organization.')
    },
    SUCCESS: {
      200: i18n.t('Organization created successfully!')
    }
  },

  UPDATE_ORGANIZATION: {
    ERROR: {
      401: i18n.t('You are not authorized to update the organization configuration.'),
      403: i18n.t('You are not allowed to update the organization configuration.'),
      404: i18n.t('The organization configuration you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the organization configuration.'),
      default: i18n.t('An error occurred updating the organization data.')
    },
    SUCCESS: {
      200: i18n.t('Organization configuration updated successfully!')
    }
  },

  DELETE_ORGANIZATION: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the organization.'),
      403: i18n.t('You are not allowed to delete the organization.'),
      404: i18n.t('The organization you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the organization.'),
      default: i18n.t('An error occurred while deleting the organization.')
    },
    SUCCESS: {
      200: i18n.t('Organization deleted successfully!')
    }
  }
};
