import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_DASHBOARD: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the dashboard data.'),
      403: i18n.t('You are not allowed to fetch the dashboard data.'),
      404: i18n.t('The dashboard data you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the dashboard data.'),
      default: i18n.t('An error occurred fetching the dashboard data.')
    }
  }
};
