import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_USERS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the users data.'),
      403: i18n.t('You are not allowed to fetch the users data.'),
      404: i18n.t('The users data you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the users data.'),
      default: i18n.t('An error occurred fetching the users data.')
    }
  },
  CREATE_USER: {
    ERROR: {
      401: i18n.t('You are not authorized to create the user.'),
      403: i18n.t('You are not allowed to create the user.'),
      404: i18n.t('The user you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the user.'),
      default: i18n.t('An error occurred creating the user.')
    },
    SUCCESS: {
      200: i18n.t('User created successfully!')
    }
  },
  UPDATE_USER: {
    ERROR: {
      401: i18n.t('You are not authorized to update the user.'),
      403: i18n.t('You are not allowed to update the user.'),
      404: i18n.t('The user you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the user.'),
      default: i18n.t('An error occurred updating the user.')
    },
    SUCCESS: {
      200: i18n.t('User updated successfully!')
    }
  },
  DELETE_USER: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the user.'),
      403: i18n.t('You are not allowed to delete the user.'),
      404: i18n.t('The user you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the user.'),
      default: i18n.t('An error occurred deleting the user.')
    },
    SUCCESS: {
      200: i18n.t('User deleted successfully!')
    }
  },

  DELETE_USERS: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the users.'),
      403: i18n.t('You are not allowed to delete the users.'),
      404: i18n.t('The users you are trying to delete do not exist.'),
      500: i18n.t('An error occurred deleting the users.'),
      default: i18n.t('An error occurred deleting the users.')
    },
    SUCCESS: {
      200: i18n.t('Users deleted successfully!')
    }
  },

  UPDATE_USER_INFO: {
    ERROR: {
      401: i18n.t('You are not authorized to update the user.'),
      403: i18n.t('You are not allowed to update the user.'),
      404: i18n.t('The user you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the user.'),
      default: i18n.t('An error occurred updating the user.')
    },
    SUCCESS: {
      200: i18n.t('User updated successfully!')
    }
  },
  INVITE_USER: {
    ERROR: {
      401: i18n.t('You are not authorized to invite user.'),
      403: i18n.t('You are not allowed to invite user.'),
      404: i18n.t('The user you are trying to invite does not exist.'),
      500: i18n.t('There was an error inviting the user.'),
      default: i18n.t('An error occurred inviting the user.'),
      'HBF-REQ-0005': i18n.t('A user with the specified email already exists.')
    },
    SUCCESS: {
      200: i18n.t('User invited successfully!')
    }
  },

  ACCEPT_USER_INVITATION: {
    ERROR: {
      401: i18n.t('You are not authorized to accept user invitation.'),
      403: i18n.t('You are not allowed to accept user invitation.'),
      404: i18n.t('The user invitation you are trying to accept does not exist.'),
      500: i18n.t('There was an error accepting the user invitation.'),
      default: i18n.t('An error occurred accepting the user invitation.')
    },
    SUCCESS: {
      200: i18n.t('Invitation accepted successfully!')
    }
  },

  REVOKE_USER_INVITATION: {
    ERROR: {
      401: i18n.t('You are not authorized to revoke user invitation.'),
      403: i18n.t('You are not allowed to revoke user invitation.'),
      404: i18n.t('The user invitation you are trying to revoke does not exist.'),
      500: i18n.t('There was an error revoking the user invitation.'),
      default: i18n.t('An error occurred revoking the user invitation.')
    },
    SUCCESS: {
      200: i18n.t('User invitation revoked successfully!')
    }
  },

  VALIDATE_USER_INVITATION: {
    ERROR: {
      401: i18n.t('You are not authorized to validate user invitation.'),
      403: i18n.t('You are not allowed to validate user invitation.'),
      // 404: i18n.t('The user invitation you are trying to validate does not exist.'),
      500: i18n.t('There was an error validating the user invitation.'),
      default: i18n.t('An error occurred validating the user invitation.')
    },
    SUCCESS: {
      200: i18n.t('User invitation validated successfully!')
    }
  },

  FETCH_USER_INVITATIONS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch user invitations.'),
      403: i18n.t('You are not allowed to fetch user invitations.'),
      404: i18n.t('The user invitations you are trying to fetch do not exist.'),
      500: i18n.t('There was an error fetching the user invitations.'),
      default: i18n.t('An error occurred fetching the user invitations.')
    }
  },

  FETCH_USER_INVITATION: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch user invitation.'),
      403: i18n.t('You are not allowed to fetch user invitation.'),
      404: i18n.t('The user invitation you are trying to fetch does not exist.'),
      500: i18n.t('There was an error fetching the user invitation.'),
      default: i18n.t('An error occurred fetching the user invitation.')
    }
  }
};
