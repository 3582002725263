
import { apiSlice } from '../apiSlice';

/**
  * This function returns the current state of the store only for queries
  * @param {String} endpointName - Name of the endpoint
  * @param {Object} fetchArgs - Has to be the same with the cache you wish to access
  * @param {Boolean} skipParam - If you want to skip the query when certain conditions are met, for example for fetchBot you could give !tenantId
  * @return {Object} The data as the query would have returned them
*/
export const getCurrentQueryState = (endpointName, fetchArgs, skipParam = false) => {
  let data;

  if (endpointName && !fetchArgs) {
    data = apiSlice.endpoints[endpointName].useQueryState(undefined, { skip: skipParam });
  }

  if (endpointName && fetchArgs) {
    if (skipParam) {
      data = apiSlice.endpoints[endpointName].useQueryState(fetchArgs, { skip: skipParam });
    } else {
      data = apiSlice.endpoints[endpointName].useQueryState(fetchArgs);
    }
  }

  return data;
};
