import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_SURVEYS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch the surveys.'),
      403: i18n.t('You are not allowed to fetch the surveys.'),
      404: i18n.t('The surveys you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the surveys'),
      default: i18n.t('An error occurred fetching the surveys.')
    }
  },
  DOWNLOAD_SURVEYS: {
    ERROR: {
      401: i18n.t('You are not authorized to download the surveys file.'),
      403: i18n.t('You are not allowed to download the surveys file.'),
      404: i18n.t('The surveys file you are trying to download do not exist.'),
      500: i18n.t('An error occurred downloading the surveys file.'),
      default: i18n.t('An error occurred downloading the surveys file.')
    }
  }
};
