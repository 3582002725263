import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_BROADCASTS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch broadcasts.'),
      403: i18n.t('You are not allowed to fetch broadcasts.'),
      404: i18n.t('The broadcasts you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the broadcasts data.'),
      default: i18n.t('An error occurred fetching the broadcasts data.')
    }
  },
  CREATE_BROADCAST: {
    ERROR: {
      401: i18n.t('You are not authorized to create the broadcast.'),
      403: i18n.t('You are not allowed to create the broadcast.'),
      404: i18n.t('The broadcast you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the broadcast.'),
      default: i18n.t('An error occurred creating the broadcast.')
    },
    SUCCESS: {
      200: i18n.t('Broadcast created!')
    }
  },
  UPDATE_BROADCAST: {
    ERROR: {
      401: i18n.t('You are not authorized to update the broadcast.'),
      403: i18n.t('You are not allowed to update the broadcast.'),
      404: i18n.t('The broadcast you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the broadcast.'),
      default: i18n.t('An error occurred updating the broadcast.')
    },
    SUCCESS: {
      200: i18n.t('Broadcast updated!')
    }
  },
  DELETE_BROADCAST: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the broadcast.'),
      403: i18n.t('You are not allowed to delete the broadcast.'),
      404: i18n.t('The broadcast you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the broadcast.'),
      default: i18n.t('An error occurred deleting the broadcast.')
    },
    SUCCESS: {
      200: i18n.t('Broadcast deleted!')
    }
  },

  DOWNLOAD_BROADCAST_ANALYTICS: {
    ERROR: {
      401: i18n.t('You are not authorized to download broadcast analytics.'),
      403: i18n.t('You are not allowed to download broadcast analytics.'),
      404: i18n.t('The broadcast you are trying to download broadcast analytics does not exist.'),
      500: i18n.t('An error occurred downloading the broadcast analytics.'),
      default: i18n.t('An error occurred downloading the broadcast analytics.')
    },
    SUCCESS: {
      200: i18n.t('Broadcast analytics downloaded successfully!')
    }
  }
};
