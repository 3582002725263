import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_ACTIVITIES: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch activities.'),
      403: i18n.t('You are not allowed to fetch activities.'),
      404: i18n.t('The activities you are trying to fetch do not exist.'),
      500: i18n.t('An error occurred fetching the activities.'),
      default: i18n.t('An error occurred fetching the activities.')
    }
  },

  FETCH_ACTIVITY: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch activity.'),
      403: i18n.t('You are not allowed to fetch activity.'),
      404: i18n.t('The activity you are trying to fetch does not exist.'),
      500: i18n.t('An error occurred fetching the activity.'),
      default: i18n.t('An error occurred fetching the activity.')
    }
  },

  CREATE_ACTIVITY: {
    ERROR: {
      401: i18n.t('You are not authorized to create activity.'),
      403: i18n.t('You are not allowed to create activity.'),
      404: i18n.t('The activity you are trying to create does not exist.'),
      500: i18n.t('An error occurred creating the activity.'),
      default: i18n.t('An error occurred creating the activity.')
    },
    SUCCESS: {
      200: i18n.t('Activity created successfully!')
    }
  },

  // Error codes: https://github.com/Helvia/hbf-core/blob/develop/src/main/java/gr/helvia/hbf/core/compilers/flowgraph/exceptions/GraphSyntaxErrorCodes.kt
  UPDATE_ACTIVITY: {
    ERROR: {
      401: i18n.t('You are not authorized to update activity.'),
      403: i18n.t('You are not allowed to update activity.'),
      404: i18n.t('The activity you are trying to update does not exist.'),
      500: i18n.t('An error occurred updating the activity.'),
      default: i18n.t('An error occurred updating the activity.'),
      '001': i18n.t('Could not update graph. Please ensure all Question nodes have at least one outgoing edge.'),
      '002': i18n.t('Could not update graph. Please ensure the node has one and only outgoing edge.'),
      '003': i18n.t('Could not update graph. Message nodes cannot have more than one outgoing edge.'),
      '004': i18n.t('Could not update graph. Question nodes can only connect to Option, Multi-Option and User Input nodes.'),
      '005': i18n.t('Could not update graph. Please refresh the page and try again.'),
      '006': i18n.t('Could not update graph. Please refresh the page and try again.'),
      '007': i18n.t('Could not update graph. Please refresh the page and try again.'),
      '008': i18n.t('Could not update graph. Please connect the last node in the graph to something.'),
      '009': i18n.t('Could not update graph. Option nodes should have only one outgoing edge.'),
      '010': i18n.t('Could not update graph. Make sure that all nodes are connected to something.'),
      '011': i18n.t('Could not update graph. Please ensure there is only one Intro node in the graph.'),
      '012': i18n.t('Could not update graph. The Intro node should only have one outgoing edge.'),
      '013': i18n.t('Could not update graph. The Intro node cannot have an incoming edge.'),
      '014': i18n.t('Could not update graph. Question nodes can only have one User Input child.'),
      '015': i18n.t('Could not update graph. User Input nodes can only have one outgoing edge.'),
      '016': i18n.t('Could not update graph. Please ensure that the Flow control node has one or two outgoing edges.'),
      '017': i18n.t('Could not update graph. You cannot have more than one Dynamic Multi-Option node on the same level.'),
      '018': i18n.t('Could not update graph. Make sure all Multi-Option nodes have data.'),
      '019': i18n.t('Could not update graph. Make sure your Intro node is connected to something.'),
      '020': i18n.t('Could not update graph. Ensure all Dynamic Multi-Option nodes have a key.'),
      '021': i18n.t('Could not update graph. Ensure all Condition nodes have data.'),
      '022': i18n.t('Could not update graph. Ensure all Flow Control nodes have data.'),
      '023': i18n.t('Could not update graph. Ensure all Flow Control nodes are followed by at least one Condition node.'),
      '024': i18n.t('Could not update graph. Ensure all Flow Control nodes are connected to something.'),
      '025': i18n.t('Could not update graph. Ensure all Redirect nodes have data.'),
      '026': i18n.t('Could not update graph. Ensure all Redirect nodes are properly connected.'),
      '027': i18n.t('Could not update graph. Ensure there are no nodes attached after the LiveChat node.'),
      '028': i18n.t('Could not update graph. Ensure Link Option nodes have data.'),
      '029': i18n.t('Could not update graph. Ensure the Intro node ID is not a duplicate.'),
      '030': i18n.t('Could not update graph. Make sure all User Info nodes have data.'),
      '128': i18n.t('Could not update graph. Make sure all Link Option nodes have a URL and a label.'),
      '130': i18n.t('Could not update graph. Make sure all Callback nodes have data.'),
      '131': i18n.t('Could not update graph. Ensure custom question labels of User Info nodes only contain alphanumerics, spaces, and hyphens only.'),
      '132': i18n.t('Could not update graph. Make sure all Switch Variable nodes have data.'),
      '133': i18n.t('Could not update graph. Dropdown rendering is currently valid only for Question nodes with Multi-Option or Dynamic Multi-Option nodes.'),
      '134': i18n.t('Could not update graph. Make sure all Flow Control nodes have data.'),
      '201': i18n.t('Could not update graph. Ensure that each edge has a source node.'),
      '202': i18n.t('Could not update graph. Ensure that each edge has a target node.'),
      '203': i18n.t('Could not update graph. Make sure all Channel Action nodes have data.'),
      '301': i18n.t('Could not update graph. Make sure the knowledge base activity contains at most one KB Node.')
    },
    SUCCESS: {
      200: i18n.t('Activity updated successfully!')
    }
  },

  DELETE_ACTIVITY: {
    ERROR: {
      401: i18n.t('You are not authorized to delete activity.'),
      403: i18n.t('You are not allowed to delete activity.'),
      404: i18n.t('The activity you are trying to delete does not exist.'),
      500: i18n.t('An error occurred deleting the activity.'),
      default: i18n.t('An error occurred deleting the activity.')
    },
    SUCCESS: {
      200: i18n.t('Activity deleted successfully!')
    }
  },

  DELETE_ACTIVITIES: {
    ERROR: {
      401: i18n.t('You are not authorized to delete activities.'),
      403: i18n.t('You are not allowed to delete activities.'),
      404: i18n.t('The activities you are trying to delete do not exist.'),
      500: i18n.t('An error occurred deleting the activities.'),
      default: i18n.t('An error occurred deleting the activities.')
    },
    SUCCESS: {
      200: i18n.t('Activities deleted successfully!')
    }
  },

  CREATE_ACTIVITIES: {
    ERROR: {
      401: i18n.t('You are not authorized to create activities.'),
      403: i18n.t('You are not allowed to create activities.'),
      404: i18n.t('The activities you are trying to create do not exist.'),
      500: i18n.t('An error occurred creating the activities.'),
      default: i18n.t('An error occurred creating the activities.')
    },
    SUCCESS: {
      200: i18n.t('Activities created successfully!')
    }
  }
};
