// Core imports
import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

// Component imports
import { InputNumber } from 'antd';

// Misc imports
import { GraphContext } from './FlowGraph';

const PriorityTag = ({ nodeId, currentPriority }) => {
  const { elements = [], onElementsUpdated } = useContext(GraphContext);
  const incomingEdge = elements.filter((element) => element.data.nodeType === 'edge').find(edge => edge.target === nodeId);

  return (
    <Fragment>
      {/* Option nodes always have a set priority, but if they don't have a source node it is irrelevant, so we hide it. */}
      <InputNumber
        className={`order-input ${!incomingEdge ? 'd-none' : '' }`}
        bordered={false}
        min={1}
        max={99}
        required
        controls={false}
        value={currentPriority}
        onChange={(value) => {
          return onElementsUpdated([{
            [nodeId]: {
              data: {
                priority: value
              }
            }
          }]);
        }}
        parser={value => !value ? 1 : value}
      />
    </Fragment>
  );
};

PriorityTag.propTypes = {
  nodeId: PropTypes.string,
  currentPriority: PropTypes.number
};

export default PriorityTag;
