import i18n from '../../i18n';

export const MESSAGES = {
  FETCH_BOTS: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bots.'),
      403: i18n.t('You are not allowed to fetch bots.'),
      404: i18n.t('The bots you are trying to fetch do not exist.'),
      500: i18n.t('There was an error fetching bots.'),
      default: i18n.t('An error occurred fetching bots.')
    }
  },
  FETCH_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot.'),
      403: i18n.t('You are not allowed to fetch bot.'),
      404: i18n.t('The bot you are trying to fetch does not exist.'),
      500: i18n.t('There was an error fetching bot.'),
      default: i18n.t('An error occurred fetching bot.')
    }
  },

  FETCH_BOT_PUBLIC: {
    ERROR: {
      401: i18n.t('You are not authorized to fetch bot.'),
      403: i18n.t('You are not allowed to fetch bot.'),
      404: i18n.t('The bot you are trying to fetch does not exist.'),
      500: i18n.t('There was an error fetching bot.'),
      default: i18n.t('An error occurred fetching bot.')
    }
  },

  CREATE_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to create the bot.'),
      403: i18n.t('You are not allowed to create the bot.'),
      404: i18n.t('The bot you are trying to create does not exist.'),
      500: i18n.t('There was an error creating the bot.'),
      default: i18n.t('An error occurred creating the bot.')
    },
    SUCCESS: {
      200: i18n.t('Bot created successfully!')
    }
  },

  UPDATE_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to update bot configuration.'),
      403: i18n.t('You are not allowed to update bot configuration.'),
      404: i18n.t('The bot you are trying to update does not exist.'),
      500: i18n.t('There was an error updating the bot configuration.'),
      default: i18n.t('An error occurred updating the bot configuration.')
    },
    SUCCESS: {
      200: i18n.t('Bot configuration updated!')
    }
  },

  DELETE_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to delete the bot.'),
      403: i18n.t('You are not allowed to delete the bot.'),
      404: i18n.t('The bot you are trying to delete does not exist.'),
      500: i18n.t('There was an error deleting the bot.'),
      default: i18n.t('An error occurred deleting the bot.')
    },
    SUCCESS: {
      200: i18n.t('Bot deleted successfully!')
    }
  },

  CLONE_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to clone bot.'),
      403: i18n.t('You are not allowed to clone bot.'),
      404: i18n.t('The bot you are trying to clone does not exist.'),
      500: i18n.t('There was an error cloning bot.'),
      default: i18n.t('An error occurred cloning the bot.')
    },
    SUCCESS: {
      200: i18n.t('Bot cloned successfully!')
    }
  },

  TRAIN_BOT: {
    ERROR: {
      401: i18n.t('You are not authorized to train this bot.'),
      403: i18n.t('You are not allowed to train this bot.'),
      404: i18n.t('The bot you are trying to train does not exist.'),
      500: i18n.t('There was an error starting the NLP training.'),
      default: i18n.t('An error occurred starting the NLP training.')
    },
    SUCCESS: {
      200: i18n.t('NLP training started!')
    }
  },

  UPDATE_LIVECHAT_SETTINGS: {
    ERROR: {
      401: i18n.t('You are not authorized to update LiveChat settings.'),
      403: i18n.t('You are not allowed to update LiveChat settings.'),
      404: i18n.t('The bot you are trying to update LiveChat settings does not exist.'),
      500: i18n.t('There was an error updating LiveChat settings.'),
      default: i18n.t('An error occurred updating LiveChat settings.')
    },
    SUCCESS: {
      200: i18n.t('LiveChat settings updated successfully!')
    }
  },

  CLEAR_CACHE: {
    ERROR: {
      401: i18n.t('You are not authorized to clear the bot cache.'),
      403: i18n.t('You are not allowed to clear the bot cache.'),
      404: i18n.t('The bot you are trying to clear the cache does not exist.'),
      500: i18n.t('There was an error clearing the bot cache.'),
      default: i18n.t('An error occurred clearing the bot cache.')
    },
    SUCCESS: {
      200: i18n.t('Bot cache reset successfully!')
    }
  },

  PROCESS_MESSAGE: {
    ERROR: {
      401: i18n.t('You are not authorized to process this message.'),
      403: i18n.t('You are not allowed to process this message.'),
      404: i18n.t('The message you are trying to process does not exist.'),
      500: i18n.t('An error occurred processing the message.'),
      default: i18n.t('An error occurred processing the message.')
    },
    SUCCESS: {
      200: i18n.t('Message processed successfully!')
    }
  }
};
