import { apiSlice } from '../apiSlice';
import { API_LIVECHAT_URL } from '../../constants/env';
import { MESSAGES } from './conversationNotesMessages';
import { API_TAGS } from '../../constants/api';

export const conversationNotesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchConversationNotes: builder.query({
      query: ({ conversationId, urlParams = {} }) => ({
        url: `livechat-conversations/${conversationId}/livechat-notes`,
        method: 'GET',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.FETCH_CONVERSATION_NOTES.ERROR
        }
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}${queryArgs.conversationId}`;
      },
      providesTags: (result, error, args) => {
        return [
          { type: API_TAGS.LIVE_CHAT_CONVERSATION_NOTES, id: args.conversationId }
        ];
      }
    }),

    createConversationNote: builder.mutation({
      query: ({ conversationId, data, urlParams = {} }) => ({
        url: `livechat-conversations/${conversationId}/livechat-notes`,
        method: 'POST',
        API_URL: API_LIVECHAT_URL,
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_CONVERSATION_NOTES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.CREATE_CONVERSATION_NOTES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.LIVE_CHAT_CONVERSATION_NOTES, id: args.conversationId }];
      }
    }),

    deleteConversationNote: builder.mutation({
      query: ({ conversationId, noteId, urlParams = {} }) => ({
        url: `livechat-conversations/${conversationId}/livechat-notes/${noteId}`,
        method: 'DELETE',
        API_URL: API_LIVECHAT_URL,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_CONVERSATION_NOTES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.DELETE_CONVERSATION_NOTES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.LIVE_CHAT_CONVERSATION_NOTES, id: args.conversationId }];
      }
    }),

    updateConversationNote: builder.mutation({
      query: ({ conversationId, noteId, data, urlParams = {} }) => ({
        url: `livechat-conversations/${conversationId}/livechat-notes/${noteId}`,
        method: 'PATCH',
        API_URL: API_LIVECHAT_URL,
        body: data,
        params: {
          ...urlParams
        },
        errorMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_CONVERSATION_NOTES.ERROR
        },
        successMessage: {
          showMessage: true,
          messages: MESSAGES.UPDATE_CONVERSATION_NOTES.SUCCESS
        }
      }),
      transformResponse: (response) => true,
      transformErrorResponse: (error) => false,
      invalidatesTags: (result, error, args) => {
        if (error) {
          return;
        }
        return [{ type: API_TAGS.LIVE_CHAT_CONVERSATION_NOTES, id: args.conversationId }];
      }
    })
  })
});

export const {
  useFetchConversationNotesQuery,
  useCreateConversationNoteMutation,
  useDeleteConversationNoteMutation,
  useUpdateConversationNoteMutation
} = conversationNotesSlice;
